import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class SubContractorNameEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractorName: '',
            failedMessage: ''
        };

        this.handleContractorName = this.handleContractorName.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
    }

    componentDidMount() {
        axios({
            url: `${config.api.subcontractorname}/${this.id}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        })
        .then(response => {
            const data = response.data.data;
            this.setState({
                subContractorName: data.subContractorName
            });
        }).catch(e => {
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        });
    }

    handleDeleteCard(idx) {
        this.closeModal();
    }

    handleContractorName(event) {
        const subContractorName = event.target.value;
        this.setState({
            subContractorName
        });
    }

    handleSave() {
        axios({
            url: `${config.api.subcontractorname}/${this.id}`,
            method: 'put',
            headers: { 'x-access-token': this.user.token },
            data: {
                subContractorName: this.state.subContractorName
            } 
        })
        .then(response => {
            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('contractor name has been updated successfully.');
            this.closeModal();
        }).catch(e => {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        }) 
    }

    render() {
        return (
            <div>
                <div className="pull-right">
                    <button type="button"  
                            className="clickable close-icon close" 
                            onClick={() =>this.handleDeleteCard(1)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label>Sub Contractor Name</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.subContractorName}
                            onChange={this.handleContractorName}/>
                    </div>
                </form>

                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                  </div> 
                : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            
            </div>
        );
    }
}


export default SubContractorNameEdit;