import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class ContributingFactorCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            description: ''
        };

        this.handleDescription = this.handleDescription.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
    }


    handleDescription(description) {
        this.setState({
            description
        });
    }

    handleSave() {
        const user = getObject('user');
        const typeAPI = config.api.contirbutingfactor;

        const header = {
            headers: { 'x-access-token': user.token }
        };

        axios.post(typeAPI,
                  { description: this.state.description },
                   header)
            .then(res => {
                this.refreshTable({
                    page: this.props.tablePaging.page,
                    pageSize: this.props.tablePaging.pageSize,
                    filtered: []
                });
                this.props.setSuccessMessage('contributing factor has been created successfully');
                this.closeModal();
            }).catch(e => {
                const data = e.response.data;
                if (data.code === 400) {
                }
                console.log(e.response.data);
            });
    
    }

    render() {
        return (
            <div>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label>Contributing factor</label>
                        <input type="text"
                            className="form-control"
                            onChange={(event) => this.handleDescription(event.target.value)}/>
                    </div>
                </form>
                {this.state.failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {this.state.failedMessage}
                        </div> 
                    : null}

                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            
            </div>
        );
    }
}


export default ContributingFactorCreate;