import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class ScoringType {
    static get allOrNothing() {
        return 'ALL_OR_NOTHING';
    }

    static get cumulative() {
        return 'CUMULATIVE';
    }
}

class SectionTemplateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            scoringType: ScoringType.cumulative,
            findingType: 'MAJOR', 
            point: '',
            isPointChanged: false,
            isOriginalPointChanged: false,
            failedMessage: ''
        };

        this.handleTitle = this.handleTitle.bind(this);
        this.handleScoringType = this.handleScoringType.bind(this);
        this.handleFindingType = this.handleFindingType.bind(this);
        this.handlePoint = this.handlePoint.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage; 
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
    }

    showPoint(scoringType, isPointChanged) {
        return scoringType === "ALL_OR_NOTHING" ? false : isPointChanged;
    }

    componentDidMount() {
        const isPointChangedPromise = 
            axios({
                url: `${config.api.sectiontemplate}/${this.id}/questions/ispointchanged`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
        
        const getSectionPromise = 
            axios({
                url: `${config.api.sectiontemplate}/${this.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

        Promise.all([isPointChangedPromise, getSectionPromise])
               .then(responses => {
                    const pointChangedResponse = responses[0];
                    const sectionResponse = responses[1];

                    const isPointChanged = pointChangedResponse.data.data;
                    const section = sectionResponse.data.data;
                    this.setState({
                        title: section.title,
                        scoringType: section.scoringType,
                        findingType: section.findingType,
                        point: section.point,
                        isPointChanged: section.scoringType === "ALL_OR_NOTHING" ? false : isPointChanged,
                        isOriginalPointChanged: isPointChanged,
                        comment: section.comment
                    });
               })
               .catch(responses => {
                    // const data = e.response.data;
                    // this.setFailedMessage(data.message);
                    this.closeModal();
               });
    }

    handleComment(event) {
        const comment = event.target.value;

        console.log('comment = ', comment);

        this.setState({
            comment
        });
    }

    handleDeleteCard(idx) {
        this.closeModal();
    }


    handlePoint(event) {
        const point = event.target.value;
        this.setState({
            point
        });
    }

    handleTitle(event) {
        const title = event.target.value;
        this.setState({
            title
        });
    }

    handleScoringType(event) {
        const scoringType = event.target.value;

        this.setState((state, props) => {
            return {
                scoringType,
                isPointChanged: scoringType === "ALL_OR_NOTHING" ? false : state.isOriginalPointChanged
            };
        });
    }

    handleFindingType(event) {
        const findingType = event.target.value;

        this.setState({
            findingType: findingType
        });
    }

    handleSave() {
        this.props.clearMessage();
        
        const data = {
            title: this.state.title,
            scoringType: this.state.scoringType,
            findingType: this.state.findingType,
            comment: this.state.comment
        };

        if(!this.state.isPointChanged) {
            data.point = this.state.point;
        }
        
        axios({
            url: `${config.api.sectiontemplate}/${this.id}`,
            method: 'put',
            headers: { 'x-access-token': this.user.token },
            data
        })
        .then(response => {
            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('section template has been updated successfully.');
            this.closeModal();

            if(this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(e => {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        }) 
    }

    render() {
        return (
            <div>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={() =>this.handleDeleteCard(1)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className="align-middle">
                    <form>
                        <div className="form-group">
                            <label>Title</label>
                            <input type="text"
                                className="form-control"
                                value={this.state.title}
                                onChange={this.handleTitle}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Scoring Type</label>
                            <select className="form-control" 
                                    onChange={this.handleScoringType}>
                                <option value="CUMULATIVE" 
                                        selected={this.state.scoringType === ScoringType.cumulative}>Cumulative</option>
                                <option value="ALL_OR_NOTHING" 
                                        selected={this.state.scoringType === ScoringType.allOrNothing}>All or nothing</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Finding Type</label>
                            <select className="form-control" 
                                    onChange={this.handleFindingType}>
                                <option value="MAJOR" 
                                        selected={this.state.findingType === "MAJOR"}>Major</option>
                                <option value="MINOR" 
                                        selected={this.state.findingType === "MINOR"}>Minor</option>
                            </select>
                        </div>
                        {!this.state.isPointChanged
                        ?
                        <div className="form-group">
                            <label>Point</label>
                            <input type="text"
                                className="form-control"
                                value={this.state.point}
                                onChange={this.handlePoint}/>
                        </div>
                        :
                        null}
                        <div className="form-group">
                            <label>Comment</label>
                            <textarea 
                                className="form-control"
                                onChange={this.handleComment}
                                value={this.state.comment}
                                rows={10}>
                            </textarea>
                        </div>
                    </form>
                </div>
                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                  </div> 
                : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            
            </div>
        );
    }
}


export default SectionTemplateEdit;
