import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject, setSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda';
import config from '../config';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { now } from 'moment';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class InspectionCreate extends Component {
    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
        this.totalCard = 1;
        this.selectedProjectId = 0;
        this.selectedTemplateId = 0;
        this.user = getObject('user');
        this.nowTime = moment(); 
        const timeFormatted = this.nowTime.format("YYYY-MM-DD HH:mm:ss")
        this.state = {
            projectValue: '',
            projectName: '',
            inspectionName: '',
            dateTimeConducted: timeFormatted,
            location: '',
            projectSuggestions: [],
            templateSuggestions: [],
            inspectionTemplates: [],
            projects: [],
            templateValue: '',
            templateDescription: '',
            failedMessage: ''
        };

        this.getProjectSuggestions = async value => {
            const result = await axios({
                url: `${config.api.project}?projectName=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedProjectId = 0;
            this.setState({
                location: '',
                companyName: ''
            });
            return data.results;
        };

        this.getTemplateSuggestions = async value => {
            const result = await axios({
                url: `${config.api.inspectiontemplate}?title=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedTemplateId = 0;
            this.setState({
                templateDescription: ''
            });
            return data.results;
        };
 
        this.getProjectSuggestionValue = suggestion => {
            this.selectedProjectId = suggestion.id;
            this.setState({
                location: suggestion.location,
                companyName: suggestion.company
            });
            return suggestion.projectName;
        };

        this.getTemplateSuggestionValue = suggestion => {
            this.selectedTemplateId = suggestion.id;
            this.setState({
                templateDescription: suggestion.description
            });
            return suggestion.title;
        };

        this.renderProjectSuggestion = suggestion => (
            <span>{suggestion.projectName}</span>);

        this.renderTemplateSuggestion = suggestion => (
                <span>{suggestion.title}</span>);

        this.handleInspectionName = this.handleInspectionName.bind(this);
    }
    
    handleDateTimeConducted = (value) => {
        this.setState({
            dateTimeConducted: value.format("YYYY-MM-DD HH:mm:ss")
        });
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });

            const inspectionTemplatePromise = axios({
                url: `${config.api.inspectiontemplate}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const projectPromise = axios({
                url: `${config.api.project}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const [inspectionTemplatesResult, projectsResult] = 
                await Promise.all([inspectionTemplatePromise, projectPromise]);
            
            const inspectionTemplates = inspectionTemplatesResult.data.data.results;    
            const projects = projectsResult.data.data.results;    

            this.setState({
                inspectionTemplates,
                templateValue: inspectionTemplates.length > 0 ? inspectionTemplates[0].id : 0,
                templateDescription: inspectionTemplates.length > 0 ? inspectionTemplates[0].description : '',
                projects : R.map(x => ({ value: x.id, 
                                        label: x.projectName}), 
                                projects)
            });
        } catch(e) {

        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    }

    handleChangeProject = async ({value, label}) => {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const projectResult = await axios({
                url: `${config.api.project}/${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const project = projectResult.data.data;
    
            this.setState({
                projectValue: value,
                location: project.location,
                companyName: project.company
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleInspectionName(inspectionName) {
        this.setState({
            inspectionName
        });
    }

    onProjectSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getProjectSuggestions(value);
        this.setState({
            projectSuggestions: values
        });
    };

    onTemplateSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getTemplateSuggestions(value);
        this.setState({
            templateSuggestions: values
        });
    };

    onProjectSuggestionsClearRequested = () => {
        this.setState({
          projectSuggestions: []
        });
    };

    onTemplateSuggestionsClearRequested = () => {
        this.setState({
          templateSuggestions: []
        });
    };

    onProjectChange = (event, { newValue }) => {
        this.setState({
          projectValue: newValue
        });
    };

    onTemplateChange = async (event) => {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const templateValue = event.target.value;
            
            const inspectionTemplateResult = await axios({
                url: `${config.api.inspectiontemplate}/${templateValue}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const inspectionTemplate = inspectionTemplateResult.data.data;
            this.setState({
                templateValue,
                templateDescription: inspectionTemplate.description
            });  
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    };

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const inspectionAPI = config.api.inspection;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
    
            const data = {
                projectId: this.state.projectValue,
                templateId: this.state.templateValue,
                name: this.state.inspectionName
            }
    
            if(this.state.dateTimeConducted) {
                data.dateTimeConducted = this.state.dateTimeConducted;
            }
    
            const res = await axios.post(inspectionAPI,
                       data,
                       header);
            this.props.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('inspection has been added successfully');
            const dataResult = res.data.data;
            setSuccessMessage('inspection has been added successfully');
            this.props.history.push(`/inspection/${dataResult.id}/template/${dataResult.templateId}/section`);
        } catch(e) { 
            const data = e.response.data;
            if (data.code === 400) {
                this.setState({
                    failedMessage: data.message
                });
            }        
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }

    }

    render() {
        const { projectValue, templateValue } = this.state;

        console.log('project value di render = ', projectValue);
        const projectInputProps = {
            placeholder: 'Type project name',
            value: projectValue,
            onChange: this.onProjectChange
        };

        const templateInputProps = {
            placeholder: 'Type template title',
            value: templateValue,
            onChange: this.onTemplateChange
        };

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div>
                    <form>
                        <div className="form-group">
                            <label><b>Inspection name</b></label>
                            <input 
                                className="form-control"
                                onChange={(event) => this.handleInspectionName(event.target.value)}
                                value={this.state.inspectionName} />
                        </div>
                        <div className="form-group">
                            <label><b>Project</b></label>
                            <Select
                                onChange={this.handleChangeProject}
                                options={this.state.projects}
                            />
                        </div>
                        <div className="form-group">
                            <label><b>Date time conducted</b></label>
                            <DateTime onChange={this.handleDateTimeConducted} defaultValue={this.nowTime}/>
                        </div>
                        {this.state.location
                        ?
                        <div className="form-group">
                            <label><b>Location</b></label>
                            <p>{this.state.location}</p>
                        </div>
                        :
                        null}
                        {this.state.companyName
                        ?
                        <div className="form-group">
                            <label><b>Company</b></label>
                            <p>{this.state.companyName}</p>
                        </div>
                        :
                        null}
                        <div className="form-group">
                            <label><b>Template</b></label>
                            <select className="form-control" onChange={this.onTemplateChange}>
                                {R.map(x => <option value={x.id}>{x.title}</option>,
                                       this.state.inspectionTemplates)}
                            </select>
                        </div>
                        {this.state.templateDescription
                        ?
                        <div className="form-group">
                            <label><b>Template description</b></label>
                            <p>{this.state.templateDescription}</p>
                        </div>
                        :
                        null}
                    </form>
                    {this.state.failedMessage 
                        ? <div className="alert alert-danger" role="alert">
                                {this.state.failedMessage}
                            </div> 
                        : null}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default withRouter(InspectionCreate);