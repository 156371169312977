// import 'rc-calendar/assets/index.css';
import React from 'react';
import axios from 'axios';
import config from '../config';
// import FullCalendar from 'rc-calendar/lib/FullCalendar';
import * as R from 'ramda';
// import 'rc-select/assets/index.css';
// import Select from 'rc-select';
import Select from 'react-select';
// import enUS from 'rc-calendar/lib/locale/en_US';
import { setObject, getObject } from '../utils/localstorage';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ReactTable from "react-table";
import {convertToStd} from '../utils/date';

const now = moment();
now.locale('en-gb').utcOffset(0);

// const defaultCalendarValue = now.clone();
// defaultCalendarValue.add(-1, 'month');

class ManHourNonContractor extends React.Component {

  constructor(props) {
    super(props);
    this.user = getObject('user');
    this.projectId = 0;
    this.year = moment().format('YYYY');
    this.nowYear = this.year;
    this.month = moment().format('MM');
    this.nowMonth = this.month;
    this.date = moment().format('YYYY-MM-DD');
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleDateFilterShortcut = this.handleDateFilterShortcut.bind(this);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.loadManHour = this.loadManHour.bind(this);
    this.showFromMonth = this.showFromMonth.bind(this);
    this.handleMode = this.handleMode.bind(this);
    this.loadContractorNames = this.loadContractorNames.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.page = 0;
    this.pageSize = 10;

    const defaultValueSelect = {
      label: 'All',
      value: 'ALL'
    };

    this.contractorNameValue = defaultValueSelect;
    this.projectValue = defaultValueSelect;
    this.typeValue = defaultValueSelect;
    this.dateModeValue = {
      label: 'Last week',
      value: 'LAST_WEEK'
    };
    this.filters = [];
    this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    this.toDate = moment().format("YYYY-MM-DD");
    this.type = 'ALL';

    this.state = {
      isRender: true,
      isSaving: false,
      totalHours: 0,
      totalWorkers: 0,
      types:  [
        {
          label: 'All',
          value: 'ALL'
        },
        {
          label: 'Plant',
          value: 'PLANT'
        },
        {
          label: 'Tema',
          value: 'TEMA'
        }
      ],
      dates: [
        {
          label: 'Last week',
          value: 'LAST_WEEK'
        },
        {
          label: 'Last 2 weeks',
          value: 'LAST_2_WEEK'
        },
        {
          label: 'Last 30 days',
          value: 'LAST_30_DAYS'
        },
        {
          label: 'Custom',
          value: 'NONE'
        }
      ],
      type: 'ALL',
      mode: 'SHOW',
      dateMode: 'LAST_WEEK'
    };

    this.props.setActiveLoadingOverlay(true);
    setObject('lastRoute', 'manhour');
    this.props.handleClickMenu('manhour', 'manhour');
  }

  state = {
    type: 'month',
  };

  onTypeChange = (type) => {
    console.log('Masuk bray');
  }

  onSelect = async (value) => {
    this.date = value.format('YYYY-MM-DD');

    await this.loadManHour();
  }

  onChangeCalendar = async (value) => {
    this.date = value.format('YYYY-MM-DD');
    await this.loadManHour();
  }

  async fetchData(state, instance) {
    await this.refreshTable(state, instance);
  }

  async handleChangeLocation({value, label}) { 

    const contractorNames = await this.loadContractorNames(value);
    let contractorNameValue = null;
    let contractorNameId;
    if(contractorNames.length > 0) {
      const contractorName = contractorNames[0];

      contractorNameValue = {
        value: contractorName.value,
        label: contractorName.label
      };

      contractorNameId = contractorName.value;
    }

    this.contractorNameId = contractorNameId;
    this.contractorNameValue = contractorNameValue;

    this.setState({
        locationId: value,
        locationValue: {
          value,
          label
        },
        contractorNames
      }, async () => {
        await this.loadManHour()
      });
  }

  async loadContractorNames(locationId) {
    const contractorNamesResult = await axios({
      url: `${config.api.contractorname}/${locationId}`,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });

    const result = contractorNamesResult.data.data.results;

    const finalResult = R.map(x => ({value: x.id, label: x.contractorName}), result);
    return finalResult;
  }

  async refreshTable(state) {
    try {
      this.props.setActiveLoadingOverlay(true);
      let manHour = {
        totalWorker: 0,
        totalHour: 0,
        type: 'ALL'
      }
      const user = getObject('user');
      const size = state.pageSize;
      const page = parseInt(state.page) + 1;
  
      this.page = parseInt(state.page);
      this.pageSize = state.pageSize;
  
      const filtered = this.filters;
  
      let filterString = '';
      const filterXs = 
            R.pipe(
              R.filter(x => x.value !== ''),
              R.map(x => `${x.id}=${x.value}`)
            )(filtered);
      
      filterString = filterXs.join('&');
  
      const sortedXs = R.map(
        x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
        state.sorted || []);
      let sortedString = '&orderBy=date&orderDirection=asc';
  
      if(sortedXs.length > 0) {
          sortedString = sortedXs.join('&');
      }
  
      if(this.fromDate
        && this.toDate
        && this.type
        && this.projectId
        && this.contractorNameId) {
          const response = await axios({
            url: `${config.api.manhour}?fromDate=${this.fromDate}&toDate=${this.toDate}&type=${this.type}&projectId=${this.projectId}&contractorNameId=${this.contractorNameId}&size=${size}&page=${page}${sortedString}`,
            method: 'get',
            headers: { 'x-access-token': user.token }
          });
    
          const data = response.data.data;
          this.setState({
            data: data.results,
            pages: data.totalPage,
            totalHours: data.totalHour,
            totalWorkers: data.totalWorker,
            loading: false
          });
      } else {
        this.setState({
          data: [],
          pages: 1,
          totalHours: 0,
          totalWorkers: 0,
          loading: false
        });
      }

    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }

  async loadManHour() {
    try {
      this.props.setActiveLoadingOverlay(true);

      this.refreshTable({
        page: this.page,
        pageSize: this.pageSize
      });
    } catch(e) {
      this.setState({
        totalWorkers: 0,
        totalHours: 0,
        type: 'ALL'
      });
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }

  async componentDidMount() {
    try {
      this.props.setActiveLoadingOverlay(true);
      const projectsResult = await axios({
        url: `${config.api.project}`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const projects = projectsResult.data.data.results;
      this.projectId = projects.length > 0 ? projects[0].id : 0;
  
      const apiControlUpdateManHourResult = await axios({
        url: `${config.api.apicontrols}/checking?route=/manhours&method=PUT&parameter=/:projectId/:date`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const isAllowedUpdateManHour = apiControlUpdateManHourResult.data.data.isAllowed;
      let contractors = null;
      let manHour = null;

      const contractorsResult = await axios({
        url: config.api.contractorname,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });

      this.contractorNameId = contractorsResult
      contractors = contractorsResult.data.data.results;
      this.contractorNameId = contractors.length > 0 ? contractors[0].id : 0;

      this.contractorNameValue = {
        value: this.contractorNameId,
        label: this.contractorNameId > 0 ? contractors[0].contractorName : ''
      };

      this.projectValue = {
        value: this.projectId,
        label: this.projectId > 0 ? projects[0].projectName : ''
      };

      const reactSelectContractors = R.map(x => ({
        label: x.contractorName,
        value: x.id
      }), contractors || []); 

      const reactSelectProjects = R.map(x => ({
        label: x.projectName,
        value: x.id
      }), projects || []);


      let locations = [];
      let locationId = 0;
      let locationValue;
      if(this.user && this.user.user.type === 'ADMIN' || this.user.user.type === 'SUPER_ADMIN') {
        const locationsResult = await axios({
          url: `${config.api.location}`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });

        locations = R.map(location => ({value: location.id, label: location.location}), locationsResult.data.data.results);
        locationId = locations.length > 0 ? locations[0].value : 0;

        locationValue = locations.length > 0 ? {value: locations[0].value, label: locations[0].label} : {};

        console.log('Location value = ', locationValue);

      } else {
        locationId = this.user.user.locationId;
      }


      let contractorNames = [];
      if(locationId) {
        contractorNames = await this.loadContractorNames(locationId);
      }

      let contractorNameValue = null;
      let contractorNameId = 0;

      if(contractorNames.length > 0) {
        const contractorName = contractorNames[0];

        contractorNameValue = {
          value: contractorName.value,
          label: contractorName.label
        };
        contractorNameId = contractorName.value;
      }

      this.contractorNameValue = contractorNameValue;
      this.contractorNameId = contractorNameId;

      this.setState({
        projects: reactSelectProjects,
        isAllowedUpdateManHour,
        contractors: reactSelectContractors,
        type: 'ALL',
        totalHours: 0,
        totalWorkers: 0,
        locations,
        locationId,
        locationValue,
        contractorNames,
        contractorNameValue
      }, () => {
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
      });
    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }

  handleChangeProject = async ({value, label}) => {
    try {
      this.props.setActiveLoadingOverlay(true);
      this.projectId = value;
      this.projectValue = {
        value,
        label
      };
      await this.loadManHour();
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }

  handleSave = async () => {
    const header = {
      headers: { 'x-access-token': this.user.token }
    };

    const data = {
      totalHour: this.state.totalHours,
      totalWorker: this.state.totalWorkers,
      type: this.state.type
    };    

    this.setState({
      isSaving: true
    });
    await axios.put(`${config.api.manhour}/${this.projectId}/${this.date}`,
                    data,
                    header)
               .then(async () => {
                 this.setState({
                   successMessage: 'man hour has been saved successfully',
                   isSaving: false
                 });
                 await this.loadManHour();
               });
  }


  async handleMode(event) {
    const mode = event.target.value;
    console.log('Date = ', this.date);
    if(mode === 'EDIT') {
      this.fromDate = this.date;
      this.toDate = this.date;

      this.setState({
        mode
      }, async () => {
        await this.loadManHour();
      });
    } else {
      this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
      this.toDate = moment().format("YYYY-MM-DD"); 


      this.setState({
        isFilterDateShowing: false,
        dateMode: 'LAST_WEEK',
        mode
      }, async () => {
        await this.loadManHour();
      });
    }
  }

  handleContractorName = async ({value, label}) => {
    try {
      this.props.setActiveLoadingOverlay(true);
      this.contractorNameId = value;
      this.contractorNameValue = {
        value,
        label
      };

      await this.loadManHour();
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }

  isAllowedUpdated = () => {
    return this.state.isAllowedUpdateManHour && this.state.mode === 'EDIT';
  }

  handleChangeTotalWorkers = (event) => {
    const totalWorkers = event.target.value;

    this.setState({
      totalWorkers
    });
  }

  handleChangeTotalHours = (event) => {
    const totalHours = event.target.value;

    this.setState({
      totalHours
    });
  }

  handleChangeType = async ({value, label}) => {
    try {
      this.props.setActiveLoadingOverlay(true);
      this.type = value;
      this.typeValue = {
        value,
        label
      };
      await this.loadManHour();
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  async handleDateFilterShortcut({value, label}) {
    try {
      this.props.setActiveLoadingOverlay(true);
      const today = moment().format("YYYY-MM-DD");
      this.dateModeValue = {
        value,
        label
      };
      this.setState({
        dateMode: value
      });
      if(value === 'LAST_WEEK') {
        
        this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
        this.toDate = today;
        
        const state = await this.loadManHour();
        this.setState({
          isFilterDateShowing: false,
          ...state
        });
      } else if(value === "LAST_2_WEEK") {
        
        this.fromDate = moment().subtract(14, 'days').format("YYYY-MM-DD");
        this.toDate = today;
        
        const state = await this.loadManHour();
        this.setState({
          isFilterDateShowing: false,
          ...state
        });
      } else if(value === "LAST_30_DAYS") {
        this.fromDate = moment().subtract(30, 'days').format("YYYY-MM-DD");
        this.toDate = today;
        
        const state = await this.loadManHour();
        
        this.setState({
          isFilterDateShowing: false,
          ...state
        });
      } else if(value === 'NONE') {
        this.props.setActiveLoadingOverlay(false);
        this.setState({
          isFilterDateShowing: true
        });
      } else {
        this.props.setActiveLoadingOverlay(false);
      }
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }

  async handleFromChange(from) {
    try {
      this.props.setActiveLoadingOverlay(true);
      this.fromDate = moment(from).format('YYYY-MM-DD');
      if(this.toDate) {
        const state = await this.loadManHour();
        this.setState(state);
      } 
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }

  async handleToChange(to) {
    try {
      this.props.setActiveLoadingOverlay(true);
      this.toDate = moment(to).format('YYYY-MM-DD');
      let state;
      if(this.fromDate) {
        this.showFromMonth();
        state = await this.loadManHour();
      } else {
        this.showFromMonth();
        state = await this.loadManHour();
      }
      this.setState(state);
    } finally {
      // this.props.setActiveLoadingOverlay(false);
    }
  }


  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    const { data, pages, loading } = this.state;

    const columns = [
      {
        Header: "Date",
        accessor: "date",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      },
      {
        Header: "Contractor name",
        accessor: "contractorName"
      },
      {
          Header: "Project",
          accessor: "projectName"
      },
      {
        Header: "Type",
        accessor: "type"
      },
      {
        Header: "Total Hour",
        accessor: "totalHour"
      },
      {
        Header: "Total Worker",
        accessor: "totalWorker"
      }
    ];

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  notifActive: true,
                  successMessage: ''
              });
          }, config.notifDuration);
    }

    // const SelectManHour = <Select/>
    return (
      <div style={{ zIndex: 1000, position: 'relative' }}>
          <br/>
          {this.state.successMessage
          ? <div className="alert alert-success" role="alert">
            {this.state.successMessage}
          </div>
          : null}
          <hr/>
          <h1>Man Hour</h1>
          <hr/>

            <div className="form-group">
            <div className="InputFromTo">
            <label><b>Choose date : </b></label>
            <Select
              onChange={this.handleDateFilterShortcut}
              value={this.dateModeValue}
              options={this.state.dates}
            />
            <div style={{display: this.state.isFilterDateShowing ? 'inline-block' : 'none', marginTop: '10px'}}>
              <DayPickerInput
                value={from}
                placeholder="From"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 1,
                  onDayClick: () => this.to.getInput().focus(),
                }}
                onDayChange={this.handleFromChange}
              />{' '}
              —{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  ref={el => (this.to = el)}
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 1,
                  }}
                  onDayChange={this.handleToChange}
                />
              </span>
          </div>
          <Helmet>
            <style>{`
            .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #f0f8ff !important;
              color: #4a90e2;
            }
            .InputFromTo .DayPicker-Day {
              border-radius: 0 !important;
            }
            .InputFromTo .DayPicker-Day--start {
              border-top-left-radius: 50% !important;
              border-bottom-left-radius: 50% !important;
            }
            .InputFromTo .DayPicker-Day--end {
              border-top-right-radius: 50% !important;
              border-bottom-right-radius: 50% !important;
            }
            .InputFromTo .DayPickerInput-Overlay {
              width: 550px;
            }
            .InputFromTo-to .DayPickerInput-Overlay {
              margin-left: -198px;
            }
            `}</style>
                </Helmet>
              </div>
          </div>
        {this.user
        && this.user.user.type === 'ADMIN' || this.user.user.type === 'SUPER_ADMIN'
        ?
        <div className="form-group">
          <label><b>Choose location: </b></label>
          <Select
              onChange={this.handleChangeLocation}
              options={this.state.locations}
              value={this.state.locationValue}
              />
        </div>
        :
        null}
        {this.state.contractors && 
        ((this.user && this.user.user.type !== 'PROJECT_MONITOR') 
          && (this.user && this.user.user.type !== 'CONTRACTOR'))
          ?
          <div className="form-group">
          <label><b>Contractor Name:</b></label>
          <Select
              value={this.contractorNameValue}
              onChange={this.handleContractorName}
              options={this.state.contractorNames}
          />
          </div>
          :
          null}
          {this.state.projects ?
          <div className="form-group">
            <label><b>Project :</b></label>
            <Select
              value={this.projectValue}
              onChange={this.handleChangeProject}
              options={this.state.projects}
            />
          </div>
          :
          null}
          <div className="form-group">
            <label><b>Type :</b></label><br/>
            <Select
              value={this.typeValue}
              onChange={this.handleChangeType}  
              options={this.state.types}
            />
          </div>
          <div className="form-group">
              <label><b>Total hours :</b></label><br/>
              {this.isAllowedUpdated()
              ?
              <input className="form-control" 
                     value={this.state.totalHours} 
                     onChange={this.handleChangeTotalHours}/>
              :
              <p>{this.state.totalHours} hours</p>
              }
          </div>
          <div className="form-group">
            <label><b>Total workers :</b></label><br/>
            {this.isAllowedUpdated()
            ?
            <input className="form-control" 
                   value={this.state.totalWorkers} 
                   onChange={this.handleChangeTotalWorkers}/>
            :
            <p>{this.state.totalWorkers} workers</p>
            }
          </div>
          <label><b>Detail :</b></label>
          {this.state.mode === 'SHOW'
          ?
          <ReactTable
          columns={columns}
          manual 
          data={data}
          pages={pages} 
          loading={loading} 
          onFetchData={this.fetchData} 
          filterable={false}
          defaultPageSize={10}
          className="-striped -highlight"
        />
          :
          null}
        {this.state.isAllowedUpdateManHour
         && !this.state.isSaving
         && this.state.mode === 'EDIT'
         ?
         <div className="form-group">
          <button className="btn"
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={this.handleSave}>Save</button>
          </div>
          :
          null}
         <br/>
         <br/>
      </div>
    );
  }
}


export default ManHourNonContractor;