import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class InspectionTemplateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '', 
            failedMessage: ''
        };

        this.handleTitle = this.handleTitle.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
    }

    componentDidMount() {
        axios({
            url: `${config.api.inspectiontemplate}/${this.id}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        })
        .then(response => {
            const data = response.data.data;
            this.setState({
                title: data.title,
                description: data.description
            });
        }).catch(e => {
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        });
    }

    handleDeleteCard(idx) {
        this.closeModal();
    }

    handleTitle(event) {
        const title = event.target.value;
        this.setState({
            title
        });
    }

    handleDescription(event) {
        const description = event.target.value;
        this.setState({
            description
        });
    }

    handleSave() {
        this.props.clearMessage();
        axios({
            url: `${config.api.inspectiontemplate}/${this.id}`,
            method: 'put',
            headers: { 'x-access-token': this.user.token },
            data: {
                title: this.state.title,
                description: this.state.description
            } 
        })
        .then(response => {
            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('inspection template has been updated successfully');
            this.props.onSuccess();
            this.closeModal();
        }).catch(e => {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        }) 
    }

    render() {
        return (
            <div>
                <div className="pull-right">
                    <button type="button"  
                            className="clickable close-icon close" 
                            onClick={() =>this.handleDeleteCard(1)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div style={{ }}>
                    <form>
                        <div className="form-group">
                            <label>Title</label>
                            <input type="text"
                                className="form-control"
                                value={this.state.title}
                                onChange={this.handleTitle}/>
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea 
                                className="form-control"
                                onChange={this.handleDescription}
                                value={this.state.description}
                                rows={10}>
                            </textarea>
                        </div>
                    </form>

                    {this.state.failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {this.state.failedMessage}
                    </div> 
                    : null}
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn"
                                style={{ width: '100%' }}
                                onClick={this.handleSave}>Save</button>
                        </div>
                    </div>
                </div>
   
            
            </div>
        );
    }
}


export default InspectionTemplateEdit;