import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda';
import BlockUi from 'react-block-ui';
import config from '../config';

class QuestionTemplateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: '',
            nonConformingResponse: '',
            recommendation: '',
            failedMessage: '',
            toolboxTalk1ToSave: null,
            toolboxTalk2ToSave: null,
            toolboxTalk3ToSave: null,
            toolboxTalk4ToSave: null,
            toolboxTalk5ToSave: null,
            toolboxTalkToDelete1: false,
            toolboxTalkToDelete2: false,
            toolboxTalkToDelete3: false,
            toolboxTalkToDelete4: false,
            toolboxTalkToDelete5: false,
            isActiveLoadingOverlay: false,
            toolboxTalkValue1: "",
            toolboxTalkValue2: "",
            toolboxTalkValue3: "",
            toolboxTalkValue4: "",
            toolboxTalkValue5: ""
        };

        this.handleQuestion = this.handleQuestion.bind(this);
        this.handleNonConformingResponse = this.handleNonConformingResponse.bind(this);
        this.handleRecommendation = this.handleRecommendation.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
    }


    getFileName(fullPath) {
        if(fullPath) {
            return fullPath.split('/').pop();
        }

        return fullPath;
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const response = await axios({
                url: `${config.api.questiontemplate}/${this.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = response.data.data;

            const toolboxTalks = {};

            for(let i=1;i<=5;i++) {
                const fieldName = `toolboxTalk${i}`;
                const toolboxTalkValue = data[fieldName];
                
                toolboxTalks[fieldName] = toolboxTalkValue;
                toolboxTalks[`${fieldName}FileName`] = this.getFileName(toolboxTalkValue);
            }

            this.setState({
                question: data.question,
                nonConformingResponse: data.nonConformingResponse,
                recommendation: data.recommendation,
                ...toolboxTalks
            });
        } catch(e) {
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }


    handleQuestion(event) {
        const question = event.target.value;
        this.setState({
            question
        });
    }

    handleUploadFile(event) {
        const fieldName = event.target.name + "ToSave";
        const number = parseInt(fieldName.replace("toolboxTalk", ""));
        let file = event.target.files[0];

        const state = {};

        if(file) {
            if(file.type === "application/pdf") {
                const tempUrl = URL.createObjectURL(file);
                const toolboxTalkNumber = `toolboxTalk${number}`;
                const fileName = this.getFileName(file.name);
                state[toolboxTalkNumber] = tempUrl;
                state[`${toolboxTalkNumber}FileName`] = fileName;
                state[`toolboxTalkToDelete${number}`] = false;
                state[`toolboxTalkValue${number}`] = "";
                state[fieldName] = file;
                this.setState({
                    ...state,
                    failedMessage: ''});
            } else {
                this.setState({
                    failedMessage: "Sorry we only accept pdf format for toolbox talk"
                });
            }
        }
    }

    handleDeleteFile(fieldName) {
        const state = {};
        state[fieldName] = true;
        const number = parseInt(fieldName.replace("toolboxTalkToDelete", ""));
        state[`toolboxTalk${number}`] = null;
        state[`toolboxTalkValue${number}`] = "";
        this.setState(state);
    }

    handleRecommendation(event) {
        const recommendation = event.target.value;

        this.setState({
            recommendation
        });
    }

    handleNonConformingResponse(event) {
        const nonConformingResponse = event.target.value;
        this.setState({
            nonConformingResponse
        });
    }

    async handleSave() {
        this.setState({
            isActiveLoadingOverlay: true
        })
        const data = {
            question: this.state.question,
            point: this.state.point,
            nonConformingResponse: this.state.nonConformingResponse,
            recommendation: this.state.recommendation
        };

        if(this.state.nonConformingResponse) {
            data.nonConformingResponse = this.state.nonConformingResponse;
        } else {
            data.nonConformingResponse = null;
        }

        if(this.state.recommendation) {
            data.recommendation = this.state.recommendation;
        } else {
            data.recommendation = null;
        }

        try {
            const response = await axios({
                url: `${config.api.questiontemplate}/${this.id}`,
                method: 'put',
                headers: { 'x-access-token': this.user.token },
                data
            });

            const toolboxTalks = new FormData();
            let isToolboxTalkUpdate = false;
            for (let i=1;i<=5;i++) {
                const fieldName = `toolboxTalk${i}ToSave`;
                const toolboxTalk = this.state[fieldName]; 

                if(toolboxTalk) {
                    toolboxTalks.append(fieldName.replace('ToSave', ''), toolboxTalk);
                    isToolboxTalkUpdate = true;
                }
            }

            if(isToolboxTalkUpdate) {
                await axios({
                    url: `${config.api.questiontemplate}/${this.id}/toolboxtalks`,
                    method: 'put',
                    headers: { 'x-access-token': this.user.token },
                    data: toolboxTalks
                });
            }

            const toolboxTalksToDelete = [];

            for(let i=1;i<=5;i++) {
                const toolboxTalkToDelete = this.state[`toolboxTalkToDelete${i}`];
                if(toolboxTalkToDelete) {
                    toolboxTalksToDelete.push(i);
                }
            }

            if(toolboxTalksToDelete.length > 0) {
                await axios({
                    url: `${config.api.questiontemplate}/${this.id}/toolboxtalks`,
                    method: 'delete',
                    headers: { 'x-access-token': this.user.token },
                    data: {toolboxTalks: toolboxTalksToDelete}
                });
            }

            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('question template has been updated successfully.');
            this.props.loadSection();
            this.closeModal();
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            })
        }
    }

    render() {
        if(this.state.failedMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        failedMessage: ''
                    });
                }, config.notifDuration);
        }

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}></BlockUi>
                <div className="pull-right" style={{marginRight: '10px'}}>
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className="align-middle">
                    <form>
                        <div className="form-group">
                            <label><b>Question</b> <span style={{color: "red"}}>*</span></label>
                            <textarea 
                                className="form-control"
                                onChange={this.handleQuestion}
                                value={this.state.question}
                                rows={5}>
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label><b>Non conforming response</b> <span style={{color: "red"}}>(Optional)</span></label>
                            <textarea 
                                className="form-control"
                                onChange={this.handleNonConformingResponse}
                                value={this.state.nonConformingResponse}
                                rows={5}>
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label><b>Recommendation</b> <span style={{color: "red"}}>(Optional)</span></label>
                            <textarea 
                                className="form-control"
                                onChange={this.handleRecommendation}
                                value={this.state.recommendation}
                                rows={5}>
                            </textarea>
                        </div>

                        <div className="form-group">
                            <label><b>Toolbox talks</b> <span style={{color: "red"}}>(Optional)</span></label>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label><b>Pdf 1</b></label>
                                    <input type="file" 
                                           style={{color:"transparent"}}
                                           accept="application/pdf"
                                           onChange={this.handleUploadFile} 
                                           name="toolboxTalk1"
                                           value={this.state.toolboxTalkValue1}/>
                                </div>
                                {this.state.toolboxTalk1 
                                ?
                                <div className="form-group"> 
                                    <div className="row">
                                        <div className="col-md-10">
                                            <a href={this.state.toolboxTalk1} target="_blank" title="Click to view the pdf" style={{wordWrap: 'break-word'}}>{this.state.toolboxTalk1FileName}</a>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button"  
                                                    className="pull-right clickable close-icon close"
                                                    title="Click to delete the pdf"
                                                    onClick={() => this.handleDeleteFile("toolboxTalkToDelete1")}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label><b>Pdf 2</b></label>
                                    <input type="file" 
                                           style={{color:"transparent"}}
                                           accept="application/pdf"
                                           onChange={this.handleUploadFile}
                                           name="toolboxTalk2"
                                           value={this.state.toolboxTalkValue2}/>
                                </div>
                                {this.state.toolboxTalk2 
                                ?
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <a href={this.state.toolboxTalk2} target="_blank" title="Click to view the pdf" style={{wordWrap: 'break-word'}}>{this.state.toolboxTalk2FileName}</a>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button"  
                                                    className="pull-right clickable close-icon close"
                                                    title="Click to delete the pdf"
                                                    onClick={() => this.handleDeleteFile("toolboxTalkToDelete2")}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label><b>Pdf 3</b></label>
                                    <input type="file" 
                                           style={{color:"transparent"}}
                                           accept="application/pdf"
                                           onChange={this.handleUploadFile}
                                           name="toolboxTalk3"
                                           value={this.state.toolboxTalkValue3}/>
                                </div>
                                {this.state.toolboxTalk3 
                                ?
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <a href={this.state.toolboxTalk3} target="_blank" title="Click to view the pdf" style={{wordWrap: 'break-word'}}>{this.state.toolboxTalk3FileName}</a>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button"  
                                                    className="pull-right clickable close-icon close"
                                                    title="Click to delete the pdf"
                                                    onClick={() => this.handleDeleteFile("toolboxTalkToDelete3")}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label><b>Pdf 4</b></label>
                                    <input type="file" 
                                           style={{color:"transparent"}}
                                           accept="application/pdf"
                                           onChange={this.handleUploadFile}
                                           name="toolboxTalk4"
                                           value={this.state.toolboxTalkValue4}/>
                                </div>
                                {this.state.toolboxTalk4 
                                ?
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <a href={this.state.toolboxTalk4} target="_blank" title="Click to view the pdf" style={{wordWrap: 'break-word'}}>{this.state.toolboxTalk4FileName}</a>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button"  
                                                    className="pull-right clickable close-icon close"
                                                    title="Click to delete the pdf"
                                                    onClick={() => this.handleDeleteFile("toolboxTalkToDelete4")}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label><b>Pdf 5</b></label>
                                    <input type="file"
                                           style={{color:"transparent"}}
                                           accept="application/pdf" 
                                           onChange={this.handleUploadFile}
                                           name="toolboxTalk5"
                                           value={this.state.toolboxTalkValue5}/>
                                </div>
                                {this.state.toolboxTalk5 
                                ?
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <a href={this.state.toolboxTalk5} target="_blank" title="Click to view the pdf" style={{wordWrap: 'break-word'}}>{this.state.toolboxTalk5FileName}</a>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button"  
                                                    className="pull-right clickable close-icon close"
                                                    title="Click to delete the pdf"
                                                    onClick={() => this.handleDeleteFile("toolboxTalkToDelete5")}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                    </form>
                </div>
                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                  </div> 
                : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}
                            disabled={this.state.isActiveLoadingOverlay}>{this.state.isActiveLoadingOverlay ? 'Please wait...' : 'Save'}</button>
                    </div>
                </div>
            
            </div>
        );
    }
}



export default QuestionTemplateEdit;