import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import Autosuggest from 'react-autosuggest';

class ProjectContractorCreate extends Component {
    constructor(props) {
        super(props);

        console.log(props);
        this.state = {
            name: '',
            department: '',
            company: '',
            value: '',
            suggestions: []
        };

        this.selectedId = 0;
        this.user = getObject('user');

        this.getSuggestions = async value => {
            const result = await axios({
                url: `${config.api.contractorname}/${props.contractorNameId}/contractors?email=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedId = 0;
            this.setState({
                name: '',
                department: '',
                company: ''
            });
            return data.results;
        };

        this.getSuggestionValue = suggestion => {
            console.log('Masuk', suggestion);
            this.selectedId = suggestion.id;
            console.log('selected id = ', this.selectedId);
            this.setState({
                name: suggestion.name,
                department: suggestion.department,
                company: suggestion.companyName,
                subContractorName: suggestion.subContractorName
            });
            return suggestion.email;
        };

        this.renderSuggestion = suggestion => (
            <span>{suggestion.email}</span>);  

        
        this.handleSave = this.handleSave.bind(this);
        this.handleProjectName = this.handleProjectName.bind(this);
        this.handleLocation = this.handleLocation.bind(this);
        this.handleCompanyId = this.handleCompanyId.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
    }

    onSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getSuggestions(value);
        this.setState({
            suggestions: values
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
          suggestions: []
        });
    };

    componentDidMount() {
        axios({
            url: `${config.api.company}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        }).then(res => {
            const companies = res.data.data.results;

            this.setState({
                companyOptions: companies,
                companyId: companies[0].id
            });
        });
    }


    handleCompanyId(companyId) {
        this.setState({
            companyId
        });
    }

    handleProjectName(projectName) {
        this.setState({
            projectName
        });
    }

    handleLocation(location) {
        this.setState({
            location
        });
    }

    handleSave() {
        if(this.selectedId === 0) {
            this.setState({
                failedMessage: "must be valid contractor email"
            })
        } else {
            const user = getObject('user');
            const projectContractorAPI = config.api.projectcontractor;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            console.log(this.selectedId);
            axios.post(projectContractorAPI,
                       {
                           contractorId: this.selectedId,
                           projectId: this.props.projectId
                       },
                       header)
                 .then(res => {
                     console.log(res);
                     this.props.refreshTable({
                        page: this.props.tablePaging.page,
                        pageSize: this.props.tablePaging.pageSize,
                        filtered: []
                    });
                    this.props.setSuccessMessage('contractor has been added successfully');
                    this.props.closeModal();
                 })
                 .catch(e => {
                    const data = e.response.data;
                    if (data.code === 400) {
                        this.setState({
                            failedMessage: data.message
                        });
                    }                 
                 });

        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
    };

    onKeyDown = (event) => {
        switch ( event.keyCode) {
            case 13:  {
                event.preventDefault();      
                this.handleSave();
            }
        }
      };

      
    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: 'Type contractor email',
            value,
            onChange: this.onChange,
            onKeyDown: this.onKeyDown
        };

        return (
            <div>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label><b>Email</b></label>
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}/>
                    </div>
                    {this.state.name
                    ?
                    <div className="form-group">
                        <label><b>Full Name</b></label>
                        <p>{this.state.name}</p>
                    </div>
                    :
                    null}
                    {this.state.subContractorName
                    ?
                    <div className="form-group">
                        <label><b>Sub Contractor Company</b></label>
                        <p>{this.state.subContractorName}</p>
                    </div>
                    :
                    null}
                </form>
                {this.state.failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {this.state.failedMessage}
                        </div> 
                    : null}

                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            
            </div>
        );
    }
}


export default ProjectContractorCreate;