import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import UserCreate from '../components/UserCreate';
import UserCreateLocAndDepAdmin from '../components/UserCreateLocAndDepAdmin';
import UserEdit from '../components/UserEdit';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import {convertToStd} from '../utils/date';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import UserAdmin from '../components/UserAdmin';
import UserAdminLocation from '../components/UserAdminLocation';
import UserAdminDepartment from '../components/UserAdminDepartment';
import UserContractor from '../components/UserContractor';
import UserProjectMonitor from '../components/UserProjectMonitor';
import UserCreateProjectMonitor from './UserCreateProjectMonitor';

class User extends Component {

    constructor(props) {
        super(props);

        this.filters = [
            {id: 'name', value: ''},
            {id: 'department', value: ''},
            {id: 'email', value: ''},
            {id: 'type', value: 'ALL'},
            {id: 'companyName', value: ''},
            {id: 'location', value: ''},
            {id: 'contractorName', value: ''},
            {id: 'phoneNumber', value: ''}
        ];

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showDeleteModal: false,
            showAddModal: false,
            isBackToHome: false,
            editId: 0,
            deleteId: 0,
            redirectId: 0,
            successMessage: '',
            failedMessage: '',
            isShowingGetUser: true,
            isShowingCreateUser: false,
            isShowingUpdateUser: false,
            isShowingDeleteUser: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.handleSection = this.handleSection.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);

        this.hideNavBar = props.hideNavBar;

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('user', 'master');

        const userObj = getObject('user');
        this.userObj = userObj;

        if(userObj) {
            this.user = userObj.user;
            this.userType = this.user.type;
        } else {
            this.props.history.push('/login');
        }

        setObject('lastRoute', 'user');
    }

    handleFilterInput() {
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
      }
    
    handleCloseFilterInput() {
        this.filters = [
            {id: 'name', value: ''},
            {id: 'department', value: ''},
            {id: 'email', value: ''},
            {id: 'type', value: 'ALL'},
            {id: 'companyName', value: ''},
            {id: 'location', value: ''},
            {id: 'contractorName', value: ''},
            {id: 'phoneNumber', value: ''}
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
      }
    
      handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
      }

    handleBack() {
        this.setState({
            isBackToHome : true
        });
    }

    handleSection(redirectId) {
        this.setState({
            redirectId
        });
    }

    refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const filtered = this.filters;

        console.log(filtered);
    
        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = '&orderBy=id&orderDirection=desc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        if(user) {
            axios({
                url: `${config.api.user}?size=${size}&page=${page}&${filterString}&${sortedString}`,
                method: 'get',
                headers: { 'x-access-token': user.token }
            })
            .then(response => {
                const data = response.data.data;
                console.log(data);
                this.setState({
                    data: data.results,
                    pages: data.totalPage,
                    loading: false
                });
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState((state) => ({
            isShowingGetUserAdmin: !state.isShowingGetUserAdmin,
            isShowingGetUserAdminLocation: !state.isShowingGetUserAdminLocation,
            isShowingGetUserAdminDepartment: !state.isShowingGetUserAdminDepartment,
            isShowingGetUserContractor: !state.isShowingGetUserContractor
        }), () => {
            this.setState((lastState) => ({
                isShowingGetUserAdmin: !lastState.isShowingGetUserAdmin,
                isShowingGetUserAdminLocation: !lastState.isShowingGetUserAdminLocation,
                isShowingGetUserAdminDepartment: !lastState.isShowingGetUserAdminDepartment,
                isShowingGetUserContractor: !lastState.isShowingGetUserContractor,
                successMessage,
                failedMessage: ''
            }));
        })
 
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    componentWillUnmount() {
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    async componentDidMount() {
        if(this.user) {
            const apiControlGetUserResult = await axios({
                url: `${config.api.apicontrols}/checking?route=/users&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            if(!apiControlGetUserResult.data.data.isAllowed) {
                console.log('masuk ke sini bro');
                this.props.history.push('/');
            }

            const apiControlGetUserAdminPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/useradmins&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });

            const apiControlGetUserAdminLocationPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/useradminlocations&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });

            const apiControlGetUserAdminDepartmentPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/useradmindepartments&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });

            const apiControlGetUserContractorPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/usercontractors&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });


            const apiControlGetUserProjectMonitorPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/userprojectmonitors&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            const apiControlCreateUserPromise = axios({ 
                url: `${config.api.apicontrols}/checking?route=/users&method=POST&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
            
            const [apiControlGetUserAdminResult,
                   apiControlGetUserAdminLocationResult,
                   apiControlGetUserAdminDepartmentResult,
                   apiControlGetUserContractorResult,
                   apiControlGetUserProjectMonitorResult,
                   apiControlCreateUserResult] = 
                   await Promise.all([
                       apiControlGetUserAdminPromise, 
                       apiControlGetUserAdminLocationPromise,
                       apiControlGetUserAdminDepartmentPromise,
                       apiControlGetUserContractorPromise,
                       apiControlGetUserProjectMonitorPromise,
                       apiControlCreateUserPromise]);

            this.setState({
                isShowingGetUserAdmin: apiControlGetUserAdminResult.data.data.isAllowed,
                isShowingGetUserAdminLocation: apiControlGetUserAdminLocationResult.data.data.isAllowed,
                isShowingGetUserAdminDepartment: apiControlGetUserAdminDepartmentResult.data.data.isAllowed,
                isShowingGetUserContractor: apiControlGetUserContractorResult.data.data.isAllowed,
                isShowingGetProjectMonitor: apiControlGetUserProjectMonitorResult.data.data.isAllowed,
                isShowingCreateUser: apiControlCreateUserResult.data.data.isAllowed
            });
        }
    }

    render() {

        if(this.state.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        successMessage: ''
                    });
                }, config.notifDuration);
        }

        const columns = [
            {
                Header: "Name",
                accessor: "name",
                sortable: true
            },
            {
                Header: "Department",
                accessor: "department"
            },
            {
                Header: "Location",
                accessor: "location"
            },
            {
                Header: "Email",
                accessor: "email"
            },
            {
                Header: "Phone number",
                accessor: "phoneNumber"
            },
            {
                Header: "Type",
                accessor: "type",
                Filter: ({ filter, onChange }) => {
                    const userRole = this.user.type;
                    console.log(this.user);
                    const typeOptions = [
                        {
                            value: "ALL",
                            text: "All"
                        },
                        {
                            value: "INSPECTOR",
                            text: "Inspector"
                        },
                        {
                            value: "CONTRACTOR",
                            text: "Sub Contractor"
                        }
                    ];

                    if(userRole === "SUPER_ADMIN") {
                        typeOptions.push({
                            value: "ADMIN",
                            text: "Admin"
                        });
                    }

                    return (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "ALL"}>
                            {R.map(
                                x => <option value={x.value}>{x.text}</option>,
                                typeOptions)}
                        </select>);
                }
            },
            {
                Header: "Company",
                accessor: "companyName"
            },
            {
                Header: "Contractor name",
                accessor: "contractorName"
            },
            {
                Header: "Created date",
                accessor: "createdDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                        return '';
                    }
                    return date;
                }
            },
            {
                Header: "Updated date",
                accessor: "updatedDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                        return '';
                    }
                    return date;
                }
            }
        ];

        if(this.state.isShowingUpdateUser
           || this.state.isShowingDeleteUser) {
            columns.unshift({
                Header: "Action",
                width: 224,
                filterable: false,
                id: "user",
                accessor: x => x,
                Cell: cellInfo => {
                    console.log('cell info = ', cellInfo);
                    return (
                        <div className="row">
                            <div className="col-md-auto">
                                {this.state.isShowingUpdateUser
                                ?
                                <button type="button"
                                    className="btn btn-sm"
                                    onClick={() => this.handleEdit(cellInfo.original.id)}>Edit</button>
                                :
                                null}
                            </div>
                            <div className="col-md-auto">
                                {this.state.isShowingDeleteUser
                                ?
                                <button type="button"
                                    className="btn btn-sm"
                                    onClick={() => this.handleDelete(cellInfo.original.id)}>Delete</button>
                                :
                                null}
                            </div>
                            <div className="col-md-auto">
                                {cellInfo.original.type === "CONTRACTOR"
                                 && this.state.isShowingContractorProject
                                 ? 
                                 <Link to={`/user/${cellInfo.original.id}/project`} class="btn btn-sm">Projects</Link>
                                 : null}                              
                            </div>
                        </div>);
                }
            });
        }

        const { data, pages, loading } = this.state;
        let userCreateComponent;

        if(this.userType === 'ADMIN_LOCATION'
            || this.userType === 'ADMIN_DEPARTMENT') {
            userCreateComponent = (<UserCreateLocAndDepAdmin
                closeModal={this.handleCloseAddModal}
                refreshTable={this.fetchData}
                tablePaging={{
                    page: this.page,
                    pageSize: this.pageSize
                }}
                setSuccessMessage={this.setSuccessMessage} />)
        } else if(this.userType === 'PROJECT_MONITOR') {
            userCreateComponent = (<UserCreateProjectMonitor
                closeModal={this.handleCloseAddModal}
                refreshTable={this.fetchData}
                tablePaging={{
                    page: this.page,
                    pageSize: this.pageSize
                }}
                setSuccessMessage={this.setSuccessMessage} />)
        } else {
            userCreateComponent = (<UserCreate
                closeModal={this.handleCloseAddModal}
                refreshTable={this.fetchData}
                tablePaging={{
                    page: this.page,
                    pageSize: this.pageSize
                }}
                setSuccessMessage={this.setSuccessMessage} />)

        } 

        const component = (
            <div>
                <br/>
                {this.state.successMessage
                    ? <div className="alert alert-success" role="alert">
                        {this.state.successMessage}
                    </div>
                    : null}
                {this.state.failedMessage
                    ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                    </div>
                    : null}
                <br/>
                <div className="grid-item">
                    {this.state.isShowingCreateUser
                    ?
                    <button type="button" class="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New User</span></button>
                    :
                    null}
                </div>
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    {userCreateComponent}
                </ReactModal>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {this.state.isShowingGetUserAdmin
                    ?
                    <li className="nav-item">
                        <a className={this.state.isShowingGetUserAdmin ? "nav-link active" : "navlink"} id="userAdmin-tab" data-toggle="tab" href="#userAdmin" role="tab" aria-controls="userAdmin" aria-selected={this.state.isShowingGetUserAdmin ? "true" : "false"}>Admin</a>
                    </li>
                    :
                    null}
                    {this.state.isShowingGetUserAdminLocation
                    ?
                    <li className="nav-item">
                        <a className={this.state.isShowingGetUserAdmin ? "nav-link" : "nav-link active"} id="userAdminLocation-tab" data-toggle="tab" href="#userAdminLocation" role="tab" aria-controls="userAdminLocation" aria-selected={this.state.isShowingGetUserAdmin ? "false" : "true"}>Admin location</a>
                    </li>
                    :
                    null}
                    {this.state.isShowingGetUserAdminDepartment
                    ?
                    <li className="nav-item">
                        <a className={this.state.isShowingGetUserAdminLocation ? "nav-link" : "nav-link active"} id="userAdminDepartment-tab" data-toggle="tab" href="#userAdminDepartment" role="tab" aria-controls="userAdminDepartment" aria-selected={this.state.isShowingGetUserAdminLocation ? "false" : "true"}>Admin department</a>
                    </li>
                    :
                    null}
                    {this.state.isShowingGetProjectMonitor
                    ?
                    <li className="nav-item">
                        <a className={this.state.isShowingGetUserAdminDepartment ? "nav-link" : "nav-link active"} id="userProjectMonitor-tab" data-toggle="tab" href="#userProjectMonitor" role="tab" aria-controls="userProjectMonitor" aria-selected={this.state.isShowingGetUserAdminDepartment ? "false" : "true"}>General Contractor</a>
                    </li>
                    :
                    null}
                    {this.state.isShowingGetUserContractor
                    ?
                    <li className="nav-item">
                        <a className={this.state.isShowingGetUserAdminDepartment ? "nav-link" : "nav-link active"} id="userContractor-tab" data-toggle="tab" href="#userContractor" role="tab" aria-controls="userContractor" aria-selected={this.state.isShowingGetUserAdminDepartment ? "false" : "true"}>Sub Contractor</a>
                    </li>
                    :
                    null}
                </ul>
                <div className="tab-content" id="myTabContent">
                    {this.state.isShowingGetUserAdmin
                    ?
                    <div className="tab-pane fade show active" id="userAdmin" role="tabpanel" aria-labelledby="userAdmin-tab">
                        <UserAdmin
                            hideNavBar={this.props.hideNavBar} 
                            handleClickMenu={this.props.handleClickMenu} 
                            location={this.props.location} 
                            setActiveLoadingOverlay={this.props.setActiveLoadingOverlay}/>
                    </div>
                    :
                    null}
                    {this.state.isShowingGetUserAdminLocation
                    ?
                    <div className={"tab-pane fade" + (this.state.isShowingGetUserAdmin ? "" : "show active")} id="userAdminLocation" role="tabpanel" aria-labelledby="userAdminLocation-tab">
                        <UserAdminLocation 
                            hideNavBar={this.props.hideNavBar} 
                            handleClickMenu={this.props.handleClickMenu} 
                            location={this.props.location} 
                            setActiveLoadingOverlay={this.props.setActiveLoadingOverlay}/>
                    </div>
                    :
                    null}
                    {this.state.isShowingGetUserAdminDepartment
                    ?
                    <div className={"tab-pane fade" + (this.state.isShowingGetUserAdminLocation ? "" : "show active")} id="userAdminDepartment" role="tabpanel" aria-labelledby="userAdminDepartment-tab">
                        <UserAdminDepartment 
                            hideNavBar={this.props.hideNavBar} 
                            handleClickMenu={this.props.handleClickMenu} 
                            location={this.props.location} 
                            setActiveLoadingOverlay={this.props.setActiveLoadingOverlay}/>
                    </div>
                    :
                    null}
                    {this.state.isShowingGetProjectMonitor
                    ?
                    <div className={"tab-pane fade" + (this.state.isShowingGetUserAdminDepartment ? "" : "show active")} id="userProjectMonitor" role="tabpanel" aria-labelledby="userProjectMonitor-tab">
                        <UserProjectMonitor 
                            hideNavBar={this.props.hideNavBar} 
                            handleClickMenu={this.props.handleClickMenu} 
                            location={this.props.location} 
                            setActiveLoadingOverlay={this.props.setActiveLoadingOverlay}/>
                    </div>
                    :
                    null}
                    {this.state.isShowingGetUserContractor
                    ?
                    <div className={"tab-pane fade" + (this.state.isShowingGetUserAdminDepartment ? "" : "show active")} id="userContractor" role="tabpanel" aria-labelledby="userContractor-tab">
                        <UserContractor 
                            hideNavBar={this.props.hideNavBar} 
                            handleClickMenu={this.props.handleClickMenu} 
                            location={this.props.location} 
                            setActiveLoadingOverlay={this.props.setActiveLoadingOverlay}/>
                    </div>
                    :
                    null}
                </div>
            </div>
        );

        return component;

    }
}

export default withRouter(User);