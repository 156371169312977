import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import { getColumnWidth } from '../utils/table';
import QuestionTemplateCreate from '../components/QuestionTemplateCreate';
import QuestionOpenItemEdit from '../components/QuestionOpenItemEdit';
import QuestionTemplateDelete from '../components/QuestionTemplateDelete';
import PointChangedConfirmation from '../components/PointChangedConfirmation';
import QuestionPointEdit from '../components/QuestionPointEdit';
import DeleteModal from '../components/DeleteModal';
import {convertToStd, convertToStdDay} from '../utils/date';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import moment from 'moment';
import Select from 'react-select';
import EditOpenItemAssignedContractor from './EditOpenItemAssignedContractor';
import {isMobileOrTablet, getRedirectToOpenItem} from '../utils/mobile';


class QuestionOpenItem extends Component {

    constructor(props) {
        super(props);
        
        this.filters = [
            {id: 'question', value: ''},
            {id: 'nonConformingResponse', value: ''},
            {id: 'inspectionAnswerNote', value: ''},
            {id: 'contributingFactorDescription', value: ''},
            {id: 'escalationProcessTitle', value: ''},
            {id: 'escalationProcessDescription', value: ''},
            {id: 'assignedContractorId', value: 0},
            {id: 'note', value: ''},
            {id: 'inspectionName', value: ''},
            {id: 'status', value: 'OPEN'}
        ];

        setObject('lastRoute', 'inspectionopenitem');

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            type: 'OPEN',
            showDeleteModal: false,
            showAddModal: false,
            showEditPoint : false,
            showEditPointConfirmation: false,
            editId: 0,
            deleteId: 0,
            isBackToInspectionTemplate: false,
            pointFailedMessage: '',
            failedMessage: '',
            pointsToChange: [],
            section: null,
            isShowingApiControlAnsweringInspection: false,
            isMobileOrTablet: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.sectionId = props.sectionId;
        this.hideNavBar = props.hideNavBar;
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleEditPoint = this.handleEditPoint.bind(this);
        this.handleCloseEditPointModal = this.handleCloseEditPointModal.bind(this);
        this.setPointsToChange = this.setPointsToChange.bind(this);
        this.handleClosePointConfirmationModal = this.handleClosePointConfirmationModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleAssignedContractor = this.handleAssignedContractor.bind(this);
        this.handleEditOpenItemAssignedContractor = this.handleEditOpenItemAssignedContractor.bind(this);
        this.handleCloseEditOpenItemAssignedContractor = this.handleCloseEditOpenItemAssignedContractor.bind(this);
        this.setPointFailedMessage = this.setPointFailedMessage.bind(this);
        this.loadSection = this.loadSection.bind(this);
        this.fetchDataSync = this.fetchDataSync.bind(this);

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('inspectionopenitem', 'inspectionopenitem');

        this.user = getObject('user');
    }

    loadSection(onDone) {

    }

    async componentDidMount() {
        const mobileOrTablet = isMobileOrTablet();

        if(mobileOrTablet) {
            this.setState({isMobileOrTablet: mobileOrTablet});
        } else {
            if(this.user) {
                const apiControlAnsweringInspectionPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/openitems&method=PUT&parameter=/raw`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
                const apiControlEditOpenItemAssignedContractorPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/openitems&method=PUT&parameter=/:id/contractors`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
        
                const [apiControlAnsweringInspectionResult,
                        apiControlEditOpenItemAssignedContractorResult] = 
                    await Promise.all([apiControlAnsweringInspectionPromise,
                                        apiControlEditOpenItemAssignedContractorPromise]);
    
                const contractorsResult = await axios({
                    url: `${config.api.user}/contractors`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
                
                const newContractors = R.map(x => ({ value: x.id, label: x.email }), contractorsResult.data.data);
                this.assignedContractorValue = {value:0, label: 'All'};
    
                console.log('is showing api edit open item assigned contractor = ', apiControlEditOpenItemAssignedContractorResult.data.data.isAllowed);
                this.setState({
                    isShowingApiControlAnsweringInspection: apiControlAnsweringInspectionResult.data.data.isAllowed,
                    isShowingApiEditOpenItemAssignedContractor: apiControlEditOpenItemAssignedContractorResult.data.data.isAllowed,
                    contractors: [{value:0, label: 'All'}, ...newContractors]
                });
            }
        }

    }

    handleFilterInput() {
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
    }
    
    setPointFailedMessage(pointFailedMessage) {
        this.setState({
            pointFailedMessage
        })
    }

    handleCloseFilterInput() {
        this.props.setActiveLoadingOverlay(true);
        this.filters = [
          {id: 'question', value: ''},
          {id: 'nonConformingResponse', value: ''},
          {id: 'inspectionAnswerNote', value: ''},
          {id: 'contributingFactorDescription', value: ''},
          {id: 'escalationProcessTitle', value: ''},
          {id: 'escalationProcessDescription', value: ''},
          {id: 'assignedContractorId', value: 0},
          {id: 'note', value: ''},
          {id: 'inspectionName', value: ''},
          {id: 'status', value: 'OPEN'}
        ];

        this.setState((state) => ({
            showFiltering: !state.showFiltering,
            type: 'OPEN'
        }), () => {
            this.refreshTable({
                page: this.page,
                pageSize: this.pageSize
            });
        });
    }
    
    handleSearch(idx, value, type) {
        this.filters[idx].value = value;
        this.refreshTable({
            page: this.page,
            pageSize: this.pageSize
        });
    }

    handleCloseEditPointModal() {
        this.hideNavBar(false);
        this.setState({
            showEditPoint: false
        });
    }

    handleClosePointConfirmationModal() {
        this.setState((state, props) => {
            this.hideNavBar(false);
            return {
                showEditPointConfirmation: false,
                showEditPoint: false
            }
        });
    }

    handleAssignedContractor({value, label}) {
        this.filters[6].value = value;
        this.assignedContractorValue = {value, label};
        this.refreshTable({
            page: this.page,
            pageSize: this.pageSize
        });
    }

    setPointsToChange(pointsToChange) {
        this.hideNavBar(true);
        this.setState(
            (state, props) => {
                return {pointsToChange, showEditPointConfirmation: true};
        });
    }

    handleBack() {
        this.setState({
            isBackToInspectionTemplate : true
        });
    }

    async refreshTable(state) {
        try {
            this.setState({
                loading: true
            });
            const user = getObject('user');
            const size = state.pageSize;
            const page = parseInt(state.page) + 1;
    
            const filtered = this.filters;
    
            console.log(filtered);
        
            let filterString = '';
            const filterXs = 
                  R.pipe(
                    R.filter(x => x.value !== ''),
                    R.map(x => `${x.id}=${x.value}`)
                  )(filtered);
            
            filterString = filterXs.join('&');
    
            this.page = parseInt(state.page);
            this.pageSize = state.pageSize;
    
            const sortedXs = R.map(
                x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
                state.sorted || []);
            let sortedString = 'orderBy=id&orderDirection=desc';
    
            if(sortedXs.length > 0) {
                sortedString = sortedXs.join('&');
            }
    
            const response = await axios({
                url: `${config.api.openitems}?size=${size}&page=${page}&${filterString}&${sortedString}`,
                method: 'get',
                headers: { 'x-access-token': user.token }
            });
            const data = response.data.data;
            this.setState({
                data: data.results,
                pages: data.totalPage,
                loading: false
            });
        } finally {
            this.props.setActiveLoadingOverlay(false);
        }
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    async fetchDataSync(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        await this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleEditOpenItemAssignedContractor(openItemId, projectId) {
        this.hideNavBar(true);
        this.setState({
            showEditOpenItemAssignedContractor: true,
            openItemId,
            projectId
        });
    }

    async handleCloseEditOpenItemAssignedContractor() {
        await this.refreshTable({
            page: this.page,
            pageSize: this.pageSize
        });
        this.setState({
            showEditOpenItemAssignedContractor: false
        },
        () => this.hideNavBar(false));
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    } 

    handleEditPoint() {
        this.hideNavBar(true);
        this.setState({
            showEditPoint: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    handleStatus(event) {
        this.props.setActiveLoadingOverlay(true);
        this.filters = [
            {id: 'question', value: ''},
            {id: 'nonConformingResponse', value: ''},
            {id: 'inspectionAnswerNote', value: ''},
            {id: 'contributingFactorDescription', value: ''},
            {id: 'escalationProcessTitle', value: ''},
            {id: 'escalationProcessDescription', value: ''},
            {id: 'assignedContractorId', value: 0},
            {id: 'note', value: ''},
            {id: 'inspectionName', value: ''},
            {id: 'status', value: 'OPEN'}
        ];
  
        const value = event.target.value;
        this.filters[9].value = value;

        this.setState({
            type: value,
            sorted: {
                id: 'closedDate',
                desc: true
            }
        }, () => {
            this.refreshTable({
                page: this.page,
                pageSize: this.pageSize
            });
        });
    }

    setSuccessMessage(successMessage) {
        this.setState(() => {
            this.successMessage = successMessage;
            return {
                failedMessage: ''
            };
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    renderView = (cellInfo) => {
        if(cellInfo.value) {
            return (
            <button className="btn btn-sm" title="View image" onClick={() => {
                this.setState(() => {
                    this.hideNavBar(true);
                    return {imageToBeDisplayed: cellInfo.value}
                });
            }}><span className="fe fe-eye"></span></button>);
        }
        return null;
    }

    render() {

        if(this.state.isMobileOrTablet) {
            window.location = getRedirectToOpenItem();
            return null;
        }

        const { data, pages, loading } = this.state;
        if(!this.user) {
            return <Redirect to="/login" />;
        }
        let columns = [
            {
                Header: "Assigned contractor",
                accessor: "assignedContractor",
                width: getColumnWidth(data, 'assignedContractor', 'Assigned contractor'),
                sortable: true
            },
            {
                Header: "Status",
                accessor: "status",
                width: getColumnWidth(data, 'status', 'Status'),
                Cell: ({ row, original }) => {
                    return original.note 
                           || original.image1
                           || original.image2
                           || original.image3
                           || original.image4 
                           ? "Closed"
                           : "Open";
                }
            },
            {
                Header: "Inspection name",
                accessor: "inspectionName",
                width: getColumnWidth(data, 'inspectionName', 'Inspection name'),
                sortable: true
            },
            {
                Header: "Question",
                accessor: "question",
                width: getColumnWidth(data, 'question', 'Inspection name'),
                sortable: true
            }
        ];

        if(this.state.type === 'OPEN') {
            
            let firstColumn = [];
            if(this.state.isShowingApiControlAnsweringInspection) {
                const isShowingEditOpenItemAssignedContractor = (openItem) => (!(openItem.note 
                    || openItem.image1
                    || openItem.image2
                    || openItem.image3
                    || openItem.image4) 
                    && this.state.isShowingApiEditOpenItemAssignedContractor);

                firstColumn = [{
                    Header: "Action",
                    width: 200,//getColumnWidth(data, 'answer', 'Action'),
                    filterable: false,
                    id: "answer",
                    accessor: x => x,
                    Cell: cellInfo => {
                        const openItem = cellInfo.original;
                        return (
                            <div className="row">
                               {(openItem.note 
                                || openItem.image1
                                || openItem.image2
                                || openItem.image3
                                || openItem.image4) 
                                ?
                                null
                                :
                                <div className="col-md-auto">
                                    <button type="button"
                                        className="btn btn-sm"
                                        title="Fix open item"
                                        onClick={() => this.handleEdit(openItem.id)}>Fix</button>
                                </div>}
                                {isShowingEditOpenItemAssignedContractor(openItem)
                                ?
                                <div className="col-md-auto">
                                    <button type="button"
                                        className="btn btn-sm"
                                        title="Fix open item"
                                        onClick={() => this.handleEditOpenItemAssignedContractor(openItem.id, openItem.projectId)}>Change contractor</button>
                                </div>
                                :
                                null}
                               
                            </div>

                        );
                    }
                }]; 
            }
            columns = [...firstColumn,
                       ...columns, 
                       ...[
                        {
                            Header: "Created date",
                            accessor: "createdDate",
                            Cell: cellInfo => {
                            const date = convertToStdDay(cellInfo.value);
                            if(date == 'Invalid date') {
                                return '';
                            }
                            return date;
                            },
                            sortable: true,
                            width: getColumnWidth(data, 'createdDate', 'Inspection name')
                        },
                        {
                            Header: "Non conforming response",
                            accessor: "nonConformingResponse",
                            width: getColumnWidth(data, 'nonConformingResponse', 'Non conforming response'),
                            sortable: true
                        },
                        {
                            Header: "Inspection note",
                            accessor: "inspectionAnswerNote",
                            width: getColumnWidth(data, 'inspectionAnswerNote', 'Inspection note'),
                            sortable: true
                        },
                        {
                            Header: "Escalation process title",
                            accessor: "escalationProcessTitle",
                            width: getColumnWidth(data, 'escalationProcessTitle', 'Escalation process title'),
                            sortable: true
                        },
                        {
                            Header: "Escalation process description",
                            accessor: "escalationProcessDescription",
                            width: getColumnWidth(data, 'escalationProcessDescription', 'Escalation process description'),
                            sortable: true
                        },
                        {
                            Header: "Contributing factor",
                            accessor: "contributingFactorDescription",
                            width: getColumnWidth(data, 'contributingFactorDescription', 'Contributing factor'),
                            sortable: true
                        },
                        {
                            Header: "Inspection image 1",
                            accessor: "inspectionAnswerImage1",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'inspectionAnswerImage1', 'Inspection image 1')
                        },
                        {
                            Header: "Inspection image 2",
                            accessor: "inspectionAnswerImage2",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'inspectionAnswerImage2', 'Inspection image 2')
                        },
                        {
                            Header: "Inspection image 3",
                            accessor: "inspectionAnswerImage3",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'inspectionAnswerImage3', 'Inspection image 3')
                        },
                        {
                            Header: "Inspection image 4",
                            accessor: "inspectionAnswerImage4",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'inspectionAnswerImage4', 'Inspection image 4')
                        }]];
        } else {
            columns = [...columns, 
                       ...[
                        {
                            Header: "Closed date",
                            accessor: "updatedDate",
                            Cell: cellInfo => {
                            const date = convertToStdDay(cellInfo.value);
                            if(date == 'Invalid date') {
                                return '';
                            }
                            return date;
                            },
                            sortable: true,
                            width: getColumnWidth(data, 'updatedDate', 'Closed date')
                        },
                        {
                            Header: "Note",
                            accessor: "note",
                            width: getColumnWidth(data, 'note', 'Fixed note'),
                            sortable: true
                        },
                        {
                            Header: "Image 1",
                            accessor: "image1",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'image1', 'Image 1')
                        },
                        {
                            Header: "Image 2",
                            accessor: "image2",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'image2', 'Image 2')
                        },
                        {
                            Header: "Image 3",
                            accessor: "image3",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'image3', 'Image 3')
                        },
                        {
                            Header: "Image 4",
                            accessor: "image4",
                            Cell: this.renderView,
                            width: getColumnWidth(data, 'image4', 'Image 4')
                        }]];
        }

        if(this.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.successMessage = '';
                    this.setState({
                        notifActive: true
                    })
                }, config.notifDuration);
        } 
        const previewStyle = {
            position: 'absolute',
            top: '50%',
            left: '54%',
            width: '440px',
            height: '300px',
            marginTop: '-150px', /* Half the height */
            marginLeft: '-250px' /* Half the width */
        };
        const component = (<div>
                <hr/>
                <h1>Open Item</h1>
                <hr/>
                {this.successMessage
                ? <div className="alert alert-success" role="alert">
                        {this.successMessage}
                    </div>
                : null}
                {this.state.failedMessage
                ? <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                  </div>
                : null}
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    {!this.state.showFiltering
                    ?
                    <div className="col-md-12">
                        <button type="button" className="btn float-right" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}
                </div>
                {this.state.showFiltering 
                ?
                <div>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Question</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)}/>
                        </div>
                        {this.state.type === 'OPEN'
                        ?
                        <div className="col-md-auto">
                            <label><b>Non conforming response</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(1, e.target.value)}/>
                        </div>
                        :
                        null}
                        {this.state.type === 'OPEN'
                        ?
                        <div className="col-md-auto">
                            <label><b>Inspection note</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(2, e.target.value)}/>
                        </div>
                        :
                        null}
                        {this.state.type === 'OPEN'
                        ?
                        <div className="col-md-auto">
                            <label><b>Contributing factor</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(3, e.target.value)}/>
                        </div>
                        :
                        null}
                        {this.state.type === 'OPEN'
                        ?
                        <div className="col-md-auto">
                            <label><b>Escalation process title</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(4, e.target.value)}/>
                        </div>
                        :
                        null}
                        {this.state.type === 'OPEN'
                        ?
                        <div className="col-md-auto">
                            <label><b>Escalation process description</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(5, e.target.value)}/>
                        </div>
                        :
                        null}
                        <div className="col-md-10">
                            <label><b>Assigned contractor</b></label>
                            <Select
                                onChange={this.handleAssignedContractor}
                                value={this.assignedContractorValue}
                                options={this.state.contractors}
                            />
                        </div>
                        {this.state.type === 'CLOSED'
                        ?
                        <div className="col-md-auto">
                            <label><b>Note</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(7, e.target.value)}/>
                        </div>
                        :
                        null}
                        <div className="col-md-auto">
                            <label><b>Inspection name</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(8, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Status</b></label>
                            <select className="form-control"
                                    onChange={this.handleStatus}>
                                <option value="OPEN">Open</option>
                                <option value="CLOSED">Closed</option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    <QuestionTemplateCreate
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        setSuccessMessage={this.setSuccessMessage}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showEditPoint}>
                    <QuestionPointEdit
                        closeModal={this.handleCloseEditPointModal}
                        setPointsToChange={this.setPointsToChange}
                        sectionId={this.sectionId} 
                        pointFailedMessage={this.state.pointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.imageToBeDisplayed}
                                    style={{
                                        content: {
                                            marginTop: '6%',
                                            marginLeft: '20%',
                                            marginBottom: '6%',
                                            marginRight: '20%'
                                        }
                                    }}> 
                        <div>
                            <div className="pull-right" style={{marginRight: '10px'}}>
                                    <button type="button"  
                                            className="pull-right clickable close-icon close" 
                                            onClick={() => this.setState(() => {
                                                this.hideNavBar(false);
                                                return {imageToBeDisplayed: ''};
                                            })}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            <br/>
                            <br/>
                            <div className="align-middle">
                                <div className="form-group">
                                    <img src={this.state.imageToBeDisplayed} style={previewStyle}/>
                                </div>
                            </div>
                        </div>
                </ReactModal>
                <ReactModal isOpen={this.state.showEditModal}>
                    <QuestionOpenItemEdit
                        closeModal={this.handleCloseEditModal}
                        id={this.state.editId}
                        questionId={this.state.editId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal isOpen={this.state.showEditOpenItemAssignedContractor}>
                    <EditOpenItemAssignedContractor 
                            openItemId={this.state.openItemId}
                            projectId={this.state.projectId}
                            closeModal={this.handleCloseEditOpenItemAssignedContractor}
                            setSuccessMessage={this.setSuccessMessage}
                            setFailedMessage={this.setFailedMessage}/>
                </ReactModal>
                {/* showEditOpenItemAssignedContractor */}
                <ReactModal isOpen={this.state.showEditPointConfirmation}
                            style={{
                                content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)'
                                }
                            }}>
                    <PointChangedConfirmation 
                        pointsToChange={this.state.pointsToChange}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        closeModal={this.handleClosePointConfirmationModal}
                        closeConfirmationModal={() => this.setState(() => {
                                                    return {showEditPointConfirmation: false} } )}
                        loadSection={this.loadSection}
                        setPointFailedMessage={this.setPointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}>
                    <DeleteModal
                        closeModal={() => {
                            this.loadSection(() => {
                                this.handleCloseDeleteModal();
                            });
                        }}
                        id={this.state.deleteId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        deleteUrl={config.api.questiontemplate} />
                </ReactModal>
                <ReactTable
                    columns={columns}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
                <br/>
            </div>);

        return component;
    }
}

export default QuestionOpenItem;