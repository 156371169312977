import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import QuestionTemplateCreate from '../components/QuestionTemplateCreate';
import QuestionInspectionEdit from '../components/QuestionInspectionEdit';
import QuestionTemplateDelete from '../components/QuestionTemplateDelete';
import PointChangedConfirmation from '../components/PointChangedConfirmation';
import QuestionPointEdit from '../components/QuestionPointEdit';
import DeleteModal from '../components/DeleteModal';
import {convertToStd} from '../utils/date';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';

class QuestionInspection extends Component {

    constructor(props) {
        super(props);
        
        this.filters = [
            {id: 'question', value: ''},
            {id: 'point', value: ''},
            {id: 'nonConformingResponse', value: ''},
            {id: 'note', value: ''},
            {id: 'isImmediateFixed', value: 'all'},
            {id: 'contributingFactor', value: ''},
            {id: 'escalationProcessTitle', value: ''},
            {id: 'escalationProcessDescription', value: ''},
            {id: 'answer', value: 'all'}
        ];

        // if(this.props.location.state) {
        //     this.successMessage = this.props.location.state.successMessage; 
        // }

        // const history = createBrowserHistory();
        // history.push({ state: {successMessage: ""} });

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showDeleteModal: false,
            showAddModal: false,
            showEditPoint : false,
            showEditPointConfirmation: false,
            editId: 0,
            deleteId: 0,
            isBackToInspectionTemplate: false,
            pointFailedMessage: '',
            failedMessage: '',
            pointsToChange: [],
            section: null,
            isShowingApiControlAnsweringInspection: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.sectionId = props.sectionId;
        this.templateId = props.templateId;
        this.hideNavBar = props.hideNavBar;
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleEditPoint = this.handleEditPoint.bind(this);
        this.handleCloseEditPointModal = this.handleCloseEditPointModal.bind(this);
        this.setPointsToChange = this.setPointsToChange.bind(this);
        this.handleClosePointConfirmationModal = this.handleClosePointConfirmationModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.setPointFailedMessage = this.setPointFailedMessage.bind(this);
        this.loadSection = this.loadSection.bind(this);

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('inspection', 'inspection');

        this.user = getObject('user');

        setObject('lastRoute', `inspection/${this.props.inspectionId}/template/${this.props.templateId}/section/${this.props.sectionId}/question`);
    }

    loadSection(onDone) {
        axios({
            url: `${config.api.inspection}/${this.props.inspectionId}/sections/${this.props.sectionId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        })
        .then(response => {
            this.setState(() => {
                const data = response.data.data;
                return {
                    section: data
                };
            }, function() {
                if(onDone) {
                    onDone();
                }
            });
        }).catch(e => {
            const data = e.response.data;
        });
    }

    async componentDidMount() {
        if(this.user) {
            try {
                const apiControlAnsweringInspectionPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId/answers/raw`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const sectionPromise = axios({
                    url: `${config.api.inspection}/${this.props.inspectionId}/sections/${this.props.sectionId}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
                const inspectionPromise = axios({
                    url: `${config.api.inspection}/${this.props.inspectionId}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const [apiControlAnsweringInspectionResult, 
                       sectionResult,
                       inspectionResult] = 
                    await Promise.all([apiControlAnsweringInspectionPromise, 
                                       sectionPromise,
                                       inspectionPromise]);
        
                this.setState({
                    section: sectionResult.data.data,
                    inspection: inspectionResult.data.data,
                    isShowingApiControlAnsweringInspection: apiControlAnsweringInspectionResult.data.data.isAllowed
                });
            } catch(err) {
                const statusCode = err.response.status;
                if(statusCode == 403) {
                    this.setState({
                        isForbidden: true
                    });
                }
            }

        }

    }

    handleFilterInput() {
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
    }
    
    setPointFailedMessage(pointFailedMessage) {
        this.setState({
            pointFailedMessage
        })
    }

    handleCloseFilterInput() {
        this.filters = [
          {id: 'question', value: ''},
          {id: 'point', value: ''},
          {id: 'nonConformingResponse', value: ''},
          {id: 'note', value: ''},
          {id: 'isImmediateFixed', value: 'all'},
          {id: 'contributingFactor', value: ''},
          {id: 'escalationProcessTitle', value: ''},
          {id: 'escalationProcessDescription', value: ''},
          {id: 'answer', value: 'all'} 
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }
    
    handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
            page: this.page,
            pageSize: this.pageSize
        });
    }

    handleCloseEditPointModal() {
        this.hideNavBar(false);
        this.setState({
            showEditPoint: false
        });
    }

    handleClosePointConfirmationModal() {
        this.setState((state, props) => {
            this.hideNavBar(false);
            return {
                showEditPointConfirmation: false,
                showEditPoint: false
            }
        });
    }

    setPointsToChange(pointsToChange) {
        this.hideNavBar(true);
        this.setState(
            (state, props) => {
                return {pointsToChange, showEditPointConfirmation: true};
        });
    }

    handleBack() {
        this.setState({
            isBackToInspectionTemplate : true
        });
    }

    async refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        const filtered = this.filters;

        console.log(filtered);
    
        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = 'orderBy=id&orderDirection=desc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        const response = await axios({
            url: `${config.api.inspection}/${this.props.inspectionId}/sections/${this.props.sectionId}/answers?size=${size}&page=${page}&${filterString}&${sortedString}`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        });
    
        const data = response.data.data;
        this.setState({
            data: data.results,
            pages: data.totalPage,
            loading: false
        });
    
    }

    async fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        await this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    } 

    handleEditPoint() {
        this.hideNavBar(true);
        this.setState({
            showEditPoint: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState(() => {
            this.successMessage = successMessage;
            return {
                failedMessage: ''
            };
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    renderView = (cellInfo) => {
        if(cellInfo.value) {
            return (
            <button className="btn btn-sm" title="View image" onClick={() => {
                this.setState(() => {
                    this.hideNavBar(true);
                    return {imageToBeDisplayed: cellInfo.value}
                });
            }}><span className="fe fe-eye"></span></button>);
        }
        return null;
    }

    render() {
        if(!this.user) {
            return <Redirect to="/login" />;
        }

        if(this.state.isForbidden) {
            return <Redirect to="/"/>;   
        }

        const columns = [
            {
                Header: "Question",
                accessor: "question"
            },
            {
                Header: "Answer",
                accessor: "answer"
            },
            {
                Header: "Point",
                accessor: "point"
            },
            {
                Header: "Non conforming response",
                accessor: "nonConformingResponse"
            },
            {
                Header: "Note",
                accessor: "note"
            },
            {
                Header: "Is Immediate Fixed",
                accessor: "isImmediateFixed",
                Cell: cellInfo => {
                    return cellInfo.value ? 'Yes' : 'No';
                }
            },
            {
                Header: "Escalation process title",
                accessor: "escalationProcessTitle"
            },
            {
                Header: "Escalation process description",
                accessor: "escalationProcessDescription"
            },
            {
                Header: "Contributing factor",
                accessor: "contributingFactor"
            },
            {
                Header: "Image 1",
                accessor: "image1",
                Cell: this.renderView
            },
            {
                Header: "Image 2",
                accessor: "image2",
                Cell: this.renderView
            },
            {
                Header: "Image 3",
                accessor: "image3",
                Cell: this.renderView
            },
            {
                Header: "Image 4",
                accessor: "image4",
                Cell: this.renderView
            },
            {
                Header: "Created date",
                accessor: "createdDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                      return '';
                    }
                    return date;
                }
            },
            {
                Header: "Updated date",
                accessor: "updatedDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                      return '';
                    }
                    return date;
                }
            }
        ];


        if(this.state.isShowingApiControlAnsweringInspection 
            && (this.state.inspection && !this.state.inspection.submissionDate)) {
            columns.unshift({
                Header: "Action",
                width: 60,
                filterable: false,
                id: "answer",
                accessor: x => x,
                Cell: cellInfo => {
                    const inspectionAnswer = cellInfo.original;
                    return (<div className="row">
                                <div className="col-md-auto">
                                    <button type="button"
                                        className="btn btn-sm"
                                        title="Update answer`"
                                        onClick={() => this.handleEdit(inspectionAnswer.questionId)}><span class="fe fe-edit"></span></button>
                                </div>
                            </div>);
                }
            });
        }


        const { data, pages, loading } = this.state;
        if(this.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.successMessage = '';
                    this.setState({
                        notifActive: true
                    })
                }, config.notifDuration);
        } 
        const previewStyle = {
            position: 'absolute',
            top: '50%',
            left: '54%',
            width: '440px',
            height: '300px',
            marginTop: '-150px', /* Half the height */
            marginLeft: '-250px' /* Half the width */
        };
        const component = (<div>
                <br/>
                {this.successMessage
                ? <div className="alert alert-success" role="alert">
                        {this.successMessage}
                    </div>
                : null}
                {this.state.failedMessage
                ? <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                  </div>
                : null}
                <hr/>
                {this.state.section 
                ?
                <h1>{this.state.section.section.title}</h1>
                :
                null}
                {this.state.section 
                 ?
                <div className="row">
                    <div className="col">
                        <b>Scoring type : </b><br/>
                        <p>{this.state.section.section.scoringType}</p>
                    </div>
                    <div className="col">
                        <b>Section point : </b><br/>
                        <p>{this.state.section.section.point}</p>
                    </div>                            
                    <div className="col">
                        <b>Point possible: </b><br/>
                        <p>{this.state.section.score.pointPossible}</p>
                    </div>                            
                    <div className="col">
                        <b>Point received: </b><br/>
                        <p>{this.state.section.score.pointReceived}</p>
                    </div>                            
                    {this.state.section.createdDate
                    ?
                    <div className="col">
                        <b>Created date: </b><br/>
                        <p>{convertToStd(this.state.section.createdDate)}</p>
                    </div>
                    :
                    null}
                    {this.state.section.updatedDate
                    ?
                    <div className="col">
                        <b>Updated date: </b><br/>
                        <p>{convertToStd(this.state.section.updatedDate)}</p>
                    </div>
                    :
                    null}
                </div>
                 :
                 null}
                <hr/>
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    {!this.state.showFiltering
                    ?
                    <div className="col-md-12">
                        <button type="button" className="btn float-right" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}
                </div>
                {this.state.showFiltering 
                ?
                <div>
                <br/>
                <br/>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Question</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Point</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(1, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Non conforming response</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(2, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Note</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(3, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Immediately fixed</b></label>
                            <select className="form-control" 
                                    onChange={e => this.handleSearch(4, e.target.value)}>
                                    <option value={"all"}>All</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                            </select>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Contributing factor</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(5, e.target.value)}
                                    value={this.filters[5].value}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Escalation process title</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(6, e.target.value)}
                                    value={this.filters[6].value}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Escalation process description</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(7, e.target.value)}
                                    value={this.filters[7].value}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Answer</b></label>
                            <select className="form-control" 
                                    onChange={e => this.handleSearch(8, e.target.value)}
                                    value={this.filters[8].value}>
                                    <option value={"all"}>All</option>
                                    <option value={'NA'}>NA</option>
                                    <option value={'YES'}>Yes</option>
                                    <option value={'NO'}>No</option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    <QuestionTemplateCreate
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        setSuccessMessage={this.setSuccessMessage}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showEditPoint}>
                    <QuestionPointEdit
                        closeModal={this.handleCloseEditPointModal}
                        setPointsToChange={this.setPointsToChange}
                        sectionId={this.sectionId} 
                        pointFailedMessage={this.state.pointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.imageToBeDisplayed}
                                    style={{
                                        content: {
                                            marginTop: '6%',
                                            marginLeft: '20%',
                                            marginBottom: '6%',
                                            marginRight: '20%'
                                        }
                                    }}> 
                        <div>
                            <div className="pull-right" style={{marginRight: '10px'}}>
                                    <button type="button"  
                                            className="pull-right clickable close-icon close" 
                                            onClick={() => this.setState(() => {
                                                this.hideNavBar(false);
                                                return {imageToBeDisplayed: ''};
                                            })}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            <br/>
                            <br/>
                            <div className="align-middle">
                                <div className="form-group">
                                    <img src={this.state.imageToBeDisplayed} style={previewStyle}/>
                                </div>
                            </div>
                        </div>
                </ReactModal>
                <ReactModal isOpen={this.state.showEditModal}>
                    <QuestionInspectionEdit
                        closeModal={this.handleCloseEditModal}
                        inspectionId={this.props.inspectionId}
                        questionId={this.state.editId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal isOpen={this.state.showEditPointConfirmation}
                            style={{
                                content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)'
                                }
                            }}>
                    <PointChangedConfirmation 
                        pointsToChange={this.state.pointsToChange}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        closeModal={this.handleClosePointConfirmationModal}
                        closeConfirmationModal={() => this.setState(() => {
                                                    return {showEditPointConfirmation: false} } )}
                        loadSection={this.loadSection}
                        setPointFailedMessage={this.setPointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}>
                    <DeleteModal
                        closeModal={() => {
                            this.loadSection(() => {
                                this.handleCloseDeleteModal();
                            });
                        }}
                        id={this.state.deleteId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        deleteUrl={config.api.questiontemplate} />
                </ReactModal>
                <ReactTable
                    columns={columns}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="col-md-12">
                        <Link to={`/inspection/${this.props.inspectionId}/template/${this.props.templateId}/section`} 
                            class="btn"><span class="fe fe-arrow-left"> Back</span></Link>
                    </div>
                </div>
                <br/>
            </div>);

        return component;
    }
}

export default QuestionInspection;