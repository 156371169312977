import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class ReportEmailModal extends Component {
    constructor(props) {
        super(props);

        this.user = getObject('user');
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.state = {
            isSuccess: false,
            redirectTo: null
        };
    }

    async handleSendEmail() {
        try {
            this.setState({isWaiting: true, isActiveLoadingOverlay: true});
            const result = await axios.post(
                `${config.api.openitems}/pdf/send`,
                {
                  reportId: this.props.reportId,
                  email: this.state.email
                },
                { headers: { 'x-access-token': this.user.token } }
              );
          
            this.props.setReportState({
                successMessage: result.data.message,
                reportId: 0
            });

        } catch(e) {
            if(e && e.response) {
                const data = e.response.data;
                console.log('data = ', data);
                this.setState({failedMessage: data.message, isWaiting: false});
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        if(this.state.failedMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        failedMessage: ''
                    });
                }, config.notifDuration);
          }
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>  
                <div className="align-middle">
                    {this.state.failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {this.state.failedMessage}
                        </div> 
                    : null}
                    <div className="form-group">
                        Do you want to send the pdf ?
                    </div>
                    <div className="form-group">
                        <input className="form-control" 
                               type="email" 
                               placeholder="Please type email address"
                               onChange={(event) => this.setState({email: event.target.value})}/>
                    </div>
                </div>
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-6">
                        <button className="btn"
                                disabled={this.state.isWaiting}
                                style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSendEmail}>{this.state.isWaiting ? 'Please wait' : 'Yes'}</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn"
                                disabled={this.state.isWaiting}
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.props.handleClose}>No</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default ReportEmailModal;