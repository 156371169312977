import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import InspectionTemplateCreate from '../components/InspectionTemplateCreate';
import InspectionTemplateEdit from '../components/InspectionTemplateEdit';
import DeleteModal from '../components/DeleteModal';
import RestoreModal from '../components/RestoreModal';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import { convertToStd } from '../utils/date';

class InspectionTemplate extends Component {

  constructor(props) {
    super(props);

    this.filters = [
      {id: 'title', value: ''},
      {id: 'description', value: ''},
      {id: 'isRemoved', value: false}
    ];

    this.successMessage = '';

    // if(this.props.location.state) {
    //   console.log('Location bray = ', this.props.location);
    //   this.successMessage = this.props.location.state.successMessage; 
    // }

    // const history = createBrowserHistory();
    // history.push({ state: {successMessage: ""} });

    this.state = {
      data: [],
      pages: null,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showAddModal: false,
      isBackToHome: false,
      editId: 0,
      deleteId: 0,
      redirectId: 0,
      successMessage: this.successMessage,
      failedMessage: '',
      showFiltering: false,
      notifActive: false,
      isShowingAddInspectionTemplate: false,
      isShowingGetInspectionTemplate: true
    };

    this.page = 0;
    this.pageSize = 10;
    this.fetchData = this.fetchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.setFailedMessage = this.setFailedMessage.bind(this);
    this.refreshTable = this.refreshTable.bind(this);   
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
    this.handleRestoreInspectionTemplate = this.handleRestoreInspectionTemplate.bind(this);
    this.handleCloseRestoreModal = this.handleCloseRestoreModal.bind(this);
    this.hideNavBar = props.hideNavBar;
    this.timer = null;

    this.props.handleClickMenu('template', 'master');

    this.user = getObject('user');
    setObject('lastRoute', 'inspectiontemplate');
  }

  async handleRestoreInspectionTemplate(restoreId) {
    this.setState({
      showRestoreModal: true,
      restoreId
    });
  }

  handleFilterInput() {
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
  }

  async componentDidMount() {
    if(this.user) {
      const apiControlGetInspectionTemplatePromise = axios({
        url: `${config.api.apicontrols}/checking?route=/inspectiontemplates&method=GET&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const apiControlCheckingPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/inspectiontemplates&method=POST&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const [apiControlGetInspectionTemplateResult, apiControlCheckingResult] = 
        await Promise.all([apiControlGetInspectionTemplatePromise, apiControlCheckingPromise]);
  
      this.setState({
        isShowingAddInspectionTemplate: apiControlCheckingResult.data.data.isAllowed,
        isShowingGetInspectionTemplate: apiControlGetInspectionTemplateResult.data.data.isAllowed
      }); 
    }
  }

  handleCloseRestoreModal() {
    this.setState({
      showRestoreModal: false
    });
  }

  handleCloseFilterInput() {
    this.filters = [
      {id: 'title', value: ''},
      {id: 'description', value: ''},
      {id: 'isRemoved', value: false}
    ];
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleSearch(idx, value) {
    this.filters[idx].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleBack() {
    this.setState({
        isBackToHome : true
    });
  }

  handleSection(redirectId) {
    this.setState({
      redirectId
    });
  }

  refreshTable(state) {
    const user = getObject('user');
    const size = state.pageSize;
    const page = parseInt(state.page) + 1;

    this.page = parseInt(state.page);
    this.pageSize = state.pageSize;

    const filtered = this.filters;

    console.log(filtered);

    let filterString = '';
    const filterXs = 
          R.pipe(
            R.filter(x => x.value !== ''),
            R.map(x => `${x.id}=${x.value}`)
          )(filtered);
    
    filterString = filterXs.join('&');

    const sortedXs = R.map(
                      x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
                      state.sorted || []);
    let sortedString = '&orderBy=id&orderDirection=desc';
    
    if(sortedXs.length > 0) {
      sortedString = sortedXs.join('&');
    }

    axios({
      url: `${config.api.inspectiontemplate}?size=${size}&page=${page}&${filterString}&${sortedString}`,
      method: 'get',
      headers: { 'x-access-token': user.token }
    })
      .then(response => {
        const data = response.data.data;
        console.log(data);
        this.setState({
          data: data.results,
          pages: data.totalPage,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  fetchData(state, instance) {
    this.clearMessage();
    this.setState({ loading: true });
    this.refreshTable(state);
  }

  handleAdd() {
    this.hideNavBar(true);
    this.setState({
      showAddModal: true
    });
  }

  handleEdit(editId) {
    this.hideNavBar(true);
    this.setState({
      showEditModal: true,
      editId
    });
  }

  handleCloseAddModal() {
    this.hideNavBar(false);
    this.setState({
      showAddModal: false
    });
  }

  handleCloseEditModal(id) {
    this.hideNavBar(false);
    this.setState({
      showEditModal: false
    });
  }

  handleDelete(deleteId) {
    this.setState({
      showDeleteModal: true,
      deleteId
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      showDeleteModal: false
    });
  }

  setSuccessMessage(successMessage) {
    this.setState({
      successMessage,
      failedMessage: ''
    });
  }

  setFailedMessage(failedMessage) {
    this.setState({
      failedMessage,
      successMessage: ''
    });
  }

  clearMessage() {
    this.setState({
      successMessage: '',
      failedMessage: ''
    });
  }

  componentWillUnmount() {
    if(this.timer){
        clearTimeout(this.timer);
    }
  }

  render() {
    if(!this.user) {
      return <Redirect to="/login" />;
    }

    if(!this.state.isShowingGetInspectionTemplate) {
      return <Redirect to="/" />
    }

    if(this.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.successMessage = '';
              this.setState({
                  notifActive: true
              })
          }, config.notifDuration);
    }

    const { data, pages, loading } = this.state;
    var modalStyles = {overlay: {zIndex: 10}};
    const component = (
      <div>
        <br />
        {this.successMessage
          ? <div className="alert alert-success" role="alert">
            {this.successMessage}
          </div>
          : null}
        {this.state.failedMessage
          ? <div className="alert alert-danger" role="alert">
            {this.state.failedMessage}
          </div>
          : null}
        <hr/>
        <h1>Inspection template</h1>
        <hr/>
        <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
          <div className="col">
            {this.state.isShowingAddInspectionTemplate
             ?
             <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Template</span></button>
             :
             null}
          </div>
          {!this.state.showFiltering
          ?
          <div className="col">
            <button type="button" className="btn float-right" onClick={this.handleFilterInput} title="Search"><span class="fe fe-search"></span></button>
          </div>
          :
          null}
        </div>

        {this.state.showFiltering 
        ?
        <div>
          <div className="card">
            <div className="pull-right" style={{ marginRight: '10px'}}>
                <button type="button"  
                        className="pull-right clickable close-icon close" 
                        onClick={this.handleCloseFilterInput}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-auto">
                  <label><b>Title</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(0, e.target.value)} />
                </div>
                <div className="col-md-auto">
                  <label><b>Description</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(1, e.target.value)} />
                </div>
                {this.user
                  && this.user.user.type === 'SUPER_ADMIN'
                  ?
                  <div className="col-md-auto">
                    <label><b>Removed</b></label>
                    <select className="form-control" 
                            onChange={e => this.handleSearch(2, e.target.value)}>
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                </div>
                :
                null}
              </div>
            </div>
          </div>
        </div>
        :
        null}
        <ReactModal isOpen={this.state.showAddModal}
          contentLabel="Minimal Modal Example" style={modalStyles}>
          <InspectionTemplateCreate
            closeModal={this.handleCloseAddModal}
            refreshTable={this.fetchData}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }}
            hideNavBar={this.hideNavBar} />
        </ReactModal>
        <ReactModal isOpen={this.state.showEditModal}
          contentLabel="Minimal Modal Example">
          <InspectionTemplateEdit
            closeModal={this.handleCloseEditModal}
            id={this.state.editId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage} 
            clearMessage={this.clearMessage}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }}/>
        </ReactModal>
        <ReactModal isOpen={this.state.showRestoreModal}
            contentLabel="Minimal Modal Example"
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
              }
            }}>
            <RestoreModal
              closeModal={this.handleCloseRestoreModal}
              id={this.state.restoreId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              deleteUrl={config.api.inspectiontemplate} />
        </ReactModal>
        <ReactModal isOpen={this.state.showDeleteModal}
          contentLabel="Minimal Modal Example"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }
          }}>
          <DeleteModal
            closeModal={this.handleCloseDeleteModal}
            id={this.state.deleteId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage}
            deleteUrl={config.api.inspectiontemplate}
            clearMessage={this.clearMessage} />
        </ReactModal>
        <ReactTable
          columns={[
            {
              Header: "Action",
              accessor: 'id',
              width: 100,
              filterable: false,
              Cell: cellInfo => {
                const inspectionTemplate = cellInfo.original;
                if(!inspectionTemplate.isExisted) {
                  return (
                  <div className="row">
                    <div className="col-md-auto">
                      <button type="button"
                              className="btn btn-sm"
                              style={{width: 80}}
                              onClick={() => this.handleRestoreInspectionTemplate(inspectionTemplate.id)}>Restore</button>
                    </div>
                  </div>
                  );
                }
                return (
                  <div className="row">
                    <div className="col-md-auto">
                      <Link to={`/inspectiontemplate/${cellInfo.value}/sectiontemplate`} class="btn btn-sm">Edit</Link>                      
                    </div>
                  </div>);
              }
            },
            {
              Header: "Title",
              accessor: "title",
              sortable: true
            },
            {
              Header: "Description",
              accessor: "description"
            },
            {
              Header: "Created date",
              accessor: "createdDate",
              Cell: cellInfo => {
                const date = convertToStd(cellInfo.value);
                if(date == 'Invalid date') {
                  return '';
                }
                return date;
              }
            },
            {
              Header: "Updated date",
              accessor: "updatedDate",
              Cell: cellInfo => {
                const date = convertToStd(cellInfo.value);
                if(date == 'Invalid date') {
                  return '';
                }
                return date;
              }
            }
          ]}
          manual 
          data={data}
          pages={pages} 
          loading={loading} 
          onFetchData={this.fetchData} 
          filterable={false}
          defaultPageSize={10}
          className="-striped -highlight"
          sortable={false}
        />
        <br/>
      </div>
    );
    return component;
  }
}

export default InspectionTemplate;