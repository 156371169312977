import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import ProjectCreate from '../components/ProjectCreate';
import ProjectEdit from '../components/ProjectEdit';
import DeleteModal from '../components/DeleteModal';
import RestoreModal from '../components/RestoreModal';
import config from '../config';
import {convertToStd} from '../utils/date';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';

class Project extends Component {

  constructor(props) {
    super(props);

    this.filters = [
      {id: 'projectName', value: ''},
      {id: 'location', value: ''},
      {id: 'company', value: ''},
      {id: 'department', value: ''},
      {id: 'isRemoved', value: false}
    ];

    this.state = {
      data: [],
      pages: null,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showRestoreModal: false,
      showAddModal: false,
      isBackToHome: false,
      editId: 0,
      deleteId: 0,
      restoreId: 0,
      redirectId: 0,
      successMessage: '',
      failedMessage: '',
      redirectId: 0,
      isShowingGetProject: true,
      isShowingCreateProject: false,
      isShowingUpdateProject: false,
      isShowingDeleteProject: false,
      isShowingProjectContractor: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleCloseRestoreModal = this.handleCloseRestoreModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.setFailedMessage = this.setFailedMessage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
    this.handleRestoreProject = this.handleRestoreProject.bind(this);
    this.hideNavBar = props.hideNavBar;

    this.page = 0;
    this.pageSize = 10;

    this.props.handleClickMenu('project', 'master');

    this.user = getObject('user');

    setObject('lastRoute', 'project');
  }

  async handleRestoreProject(restoreId) {
    this.setState({
      showRestoreModal: true,
      restoreId
    });
  }

  handleFilterInput() {
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
  }

  handleCloseFilterInput() {
    this.filters = [
      {id: 'projectName', value: ''},
      {id: 'location', value: ''},
      {id: 'company', value: ''},
      {id: 'department', value: ''},
      {id: 'isRemoved', value: false}
    ];
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleSearch(idx, value) {
    this.filters[idx].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleBack() {
    this.setState({
        isBackToHome : true
    });
  }

  handleSection(redirectId) {
    this.setState({
      redirectId
    });
  }

  async refreshTable(state) {
    const user = getObject('user');
    const size = state.pageSize;
    const page = parseInt(state.page) + 1;

    this.page = parseInt(state.page);
    this.pageSize = state.pageSize;

    const filtered = this.filters;

    let filterString = '';
    const filterXs = 
          R.pipe(
            R.filter(x => x.value !== ''),
            R.map(x => `${x.id}=${x.value}`)
          )(filtered);
    
    filterString = filterXs.join('&');

    const sortedXs = R.map(
      x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
      state.sorted || []);
    let sortedString = '&orderBy=id&orderDirection=desc';

    if(sortedXs.length > 0) {
        sortedString = sortedXs.join('&');
    }
    const response = await axios({
      url: `${config.api.project}?size=${size}&page=${page}&${filterString}&${sortedString}`,
      method: 'get',
      headers: { 'x-access-token': user.token }
    })
    const data = response.data.data;
    this.setState({
      data: data.results,
      pages: data.totalPage,
      loading: false
    });
  }

  fetchData(state, instance) {
    this.clearMessage();
    this.setState({ loading: true });
    this.refreshTable(state);
  }

  handleAdd() {
    this.hideNavBar(true);
    this.setState({
      showAddModal: true
    });
  }

  handleEdit(editId) {
    this.hideNavBar(true);
    this.setState({
      showEditModal: true,
      editId
    });
  }

  handleCloseAddModal() {
    this.hideNavBar(false);
    this.setState({
      showAddModal: false
    });
  }

  handleCloseEditModal(id) {
    this.hideNavBar(false);
    this.setState({
      showEditModal: false
    });
  }

  handleDelete(deleteId) {
    this.setState({
      showDeleteModal: true,
      deleteId
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      showDeleteModal: false
    });
  }

  handleCloseRestoreModal() {
    this.setState({
      showRestoreModal: false
    });
  }

  setSuccessMessage(successMessage) {
    this.setState({
      successMessage,
      failedMessage: ''
    });
  }

  setFailedMessage(failedMessage) {
    this.setState({
      failedMessage,
      successMessage: ''
    });
  }

  clearMessage() {
    this.setState({
      successMessage: '',
      failedMessage: ''
    });
  }

  componentWillUnmount() {
    if(this.timer){
        clearTimeout(this.timer);
    }
  }

  async componentDidMount() {

    if(this.user) {
      const apiControlGetProjectResult = await axios({
        url: `${config.api.apicontrols}/checking?route=/projects&method=GET&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      if(!apiControlGetProjectResult.data.data.isAllowed) {
        this.setState({
          isShowingGetProject: false
        });
      }
  
      const apiControlCreateProjectPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/projects&method=POST&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const apiControlUpdateProjectPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/projects&method=PUT&parameter=/:id`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const apiControlDeleteProjectPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/projects&method=DELETE&parameter=/:id`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const apiControlProjectContractorPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/projectcontractors&method=POST&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const [apiControlCreateProjectResult,
             apiControlUpdateProjectResult,
             apiControlDeleteProjectResult,
             apiControlProjectContractorResult] = 
        await Promise.all([
                apiControlCreateProjectPromise,
                apiControlUpdateProjectPromise,
                apiControlDeleteProjectPromise,
                apiControlProjectContractorPromise]);

      this.setState({
        isShowingCreateProject: apiControlCreateProjectResult.data.data.isAllowed,
        isShowingUpdateProject: apiControlUpdateProjectResult.data.data.isAllowed,
        isShowingDeleteProject: apiControlDeleteProjectResult.data.data.isAllowed,
        isShowingProjectContractor: apiControlProjectContractorResult.data.data.isAllowed
      });
    }
  }

  render() {
    if(!this.user) {
      return <Redirect to="/login"/>;
    }

    if(!this.state.isShowingGetProject) {
      return <Redirect to="/"/>
    }

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  successMessage: ''
              });
          }, config.notifDuration);
    }

    const columns = [
      {
        Header: "Project name",
        accessor: "projectName",
        sortable: true
      },
      {
          Header: "Location",
          accessor: "location"
      },
      {
        Header: "Department",
        accessor: "department"
      },
      {
        Header: "Contractor name",
        accessor: "contractorName"
      },
      {
          Header: "Company",
          accessor: "company",
          sortable: false
      },
      {
        Header: "Created date",
        accessor: "createdDate",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      },
      {
          Header: "Updated date",
          accessor: "updatedDate",
          Cell: cellInfo => {
            const date = convertToStd(cellInfo.value);
            if(date == 'Invalid date') {
              return '';
            }
            return date;
          }
      }
    ];

    if(this.state.isShowingUpdateProject
       || this.state.isShowingDeleteProject
       || this.state.isShowingProjectContractor) {
         columns.unshift({
            Header: "Action",
            accessor: 'id',
            width: (!this.state.isShowingUpdateProject && !this.state.isShowingDeleteProject)
                    && this.state.isShowingProjectContractor  
                    ? 100
                    : 246,
            filterable: false,
            Cell: cellInfo => {
              const project = cellInfo.original;
              if(!project.isExisted) {
                return (
                <div className="row">
                  <div className="col-md-auto">
                    <button type="button"
                            className="btn btn-sm"
                            style={{width: (!this.state.isShowingUpdateProject && !this.state.isShowingDeleteProject)
                              && this.state.isShowingProjectContractor  
                              ? 80
                              : 220}}
                            onClick={() => this.handleRestoreProject(project.id)}>Restore</button>
                  </div>
                </div>
                );
              }
              return (
                <div className="row">
                  <div className="col-md-auto">
                    {this.state.isShowingUpdateProject
                    ?
                    <button type="button" 
                            className="btn btn-sm" 
                            onClick={() => this.handleEdit(cellInfo.value)}>Edit</button>
                    :
                    null}
                  </div>
                  <div className="col-md-auto">
                      {this.state.isShowingProjectContractor
                      ?
                      <Link to={`/project/${cellInfo.value}/contractor`} class="btn btn-sm">Contractors</Link>
                      :
                      null}
                  </div>
                  <div className="col-md-auto">
                    {this.state.isShowingDeleteProject
                    ?
                    <button type="button" 
                            className="btn btn-sm" 
                            onClick={() => this.handleDelete(cellInfo.value)}>Delete</button>
                    :
                    null}
                  </div>
                </div>);
            }
          });
       }


      const { data, pages, loading } = this.state;
      var modalStyles = {overlay: {zIndex: 10}};
      const component = (
        <div>
          <br/>
          {this.state.successMessage
            ? <div className="alert alert-success" role="alert">
              {this.state.successMessage}
            </div>
            : null}
          {this.state.failedMessage
            ? <div className="alert alert-danger" role="alert">
              {this.state.failedMessage}
            </div>
            : null}
          <hr/>
          <h1>Project</h1>
          <hr/>
          <div className="grid2-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
            <div className="grid-item">
              {this.state.isShowingCreateProject
              ?
              <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Project</span></button>
              :
              null}
            </div>
            {!this.state.showFiltering
            ?
            <div className="grid-item" style={{ justifySelf: "end" }}>
              <button type="button" className="btn" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
            </div>
            :
            null}
          </div>
          {this.state.showFiltering 
          ?
          <div>
            <br/>
            <br/>
            <div className="card">
              <div className="pull-right" style={{ marginRight: '10px'}}>
                  <button type="button"  
                          className="pull-right clickable close-icon close" 
                          onClick={this.handleCloseFilterInput}>
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-auto">
                    <label><b>Project name</b></label>
                    <input className="form-control" 
                          onChange={e => this.handleSearch(0, e.target.value)}/>
                  </div>
                  <div className="col-md-auto">
                    <label><b>Location</b></label>
                    <input className="form-control" 
                          onChange={e => this.handleSearch(1, e.target.value)}/>
                  </div>
                  <div className="col-md-auto">
                    <label><b>Company</b></label>
                    <input className="form-control" 
                          onChange={e => this.handleSearch(2, e.target.value)}/>
                  </div>
                  <div className="col-md-auto">
                    <label><b>Department</b></label>
                    <input className="form-control" 
                          onChange={e => this.handleSearch(3, e.target.value)}/>
                  </div>
                  {this.user
                  && this.user.user.type === 'SUPER_ADMIN'
                  ?
                  <div className="col-md-auto">
                    <label><b>Removed</b></label>
                    <select className="form-control" 
                            onChange={e => this.handleSearch(4, e.target.value)}>
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                </div>
                :
                null}
                </div>
              </div>
            </div>
          </div>
          :
          null}
          <ReactModal isOpen={this.state.showAddModal}
            contentLabel="Minimal Modal Example" style={modalStyles}>
            <ProjectCreate
              closeModal={this.handleCloseAddModal}
              refreshTable={this.fetchData}
              tablePaging={{
                page: this.page,
                pageSize: this.pageSize
              }}
              setSuccessMessage={this.setSuccessMessage}/>
          </ReactModal>
          <ReactModal isOpen={this.state.showEditModal}
            contentLabel="Minimal Modal Example">
            <ProjectEdit
              closeModal={this.handleCloseEditModal}
              id={this.state.editId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              tablePaging={{
                page: this.page,
                pageSize: this.pageSize
              }} />
          </ReactModal>
          <ReactModal isOpen={this.state.showRestoreModal}
            contentLabel="Minimal Modal Example"
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
              }
            }}>
            <RestoreModal
              closeModal={this.handleCloseRestoreModal}
              id={this.state.restoreId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              deleteUrl={config.api.project} />
          </ReactModal>
          <ReactModal isOpen={this.state.showDeleteModal}
            contentLabel="Minimal Modal Example"
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
              }
            }}>
            <DeleteModal
              closeModal={this.handleCloseDeleteModal}
              id={this.state.deleteId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              deleteUrl={config.api.project} />
          </ReactModal>
          <ReactTable
            columns={columns}
            manual 
            data={data}
            pages={pages} 
            loading={loading} 
            onFetchData={this.fetchData} 
            filterable={false}
            defaultPageSize={10}
            className="-striped -highlight"
            sortable={false}
          />
          <br />
        </div>
      );
  
      return component;
  }
}

export default Project;