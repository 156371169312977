import 'rc-calendar/assets/index.css';
import React from 'react';
import axios from 'axios';
import config from '../config';
import FullCalendar from 'rc-calendar/lib/FullCalendar';
import * as R from 'ramda';
import 'rc-select/assets/index.css';
import Select from 'rc-select';
import enUS from 'rc-calendar/lib/locale/en_US';
import { setObject, getObject } from '../utils/localstorage';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import {getMonthDateRange} from '../utils/date'

const now = moment();
now.locale('en-gb').utcOffset(0);

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

class ManHour extends React.Component {

  constructor(props) {
    super(props);
    this.user = getObject('user');
    this.projectId = 0;
    this.year = moment().format('YYYY');
    this.nowYear = this.year;
    this.month = moment().format('MM');
    this.nowMonth = this.month;
    this.date = moment().format('YYYY-MM-DD');
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleDateFilterShortcut = this.handleDateFilterShortcut.bind(this);
    this.loadManHour = this.loadManHour.bind(this);
    this.showFromMonth = this.showFromMonth.bind(this);
    this.handleMode = this.handleMode.bind(this);

    this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    this.toDate = moment().format("YYYY-MM-DD");
    this.state = {
      isRender: true,
      isSaving: false,
      totalHours: 0,
      totalWorkers: 0,
      type: 'PLANT',
      mode: 'EDIT',
      dateMode: 'LAST_WEEK'
    };

    this.props.setActiveLoadingOverlay(true);
    setObject('lastRoute', 'manhour');
    this.props.handleClickMenu('manhour', 'manhour');
  }

  state = {
    type: 'month',
  };

  onTypeChange = (type) => {
    console.log('Masuk bray');
  }

  onSelect = async (value) => {
    this.date = value.format('YYYY-MM-DD');

    await this.loadManHour();
  }

  onChangeCalendar = async (value) => {
    this.date = value.format('YYYY-MM-DD');
    await this.loadManHour();
  }

  async loadManHour() {
    try {
      this.props.setActiveLoadingOverlay(true);
      let manHour = null;
      
      if(this.state.mode === 'EDIT') {
        this.fromDate = this.date;
        this.toDate = this.date;
      }

      const manHourResult = await axios({
        url: `${config.api.manhour}/${this.projectId}/${this.fromDate}`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });    
  
      manHour = manHourResult.data.data;
  
      
      const totalWorkers = manHour.totalWorker;
      const totalHours = manHour.totalHour;
      const type = manHour.type;

      this.setState({
        totalHours,
        totalWorkers,
        type
      });
    } catch(e) {
      this.setState({
        totalWorkers: 0,
        totalHours: 0,
        type: 'PLANT'
      });
    } finally {
      await this.loadManHourDates();
      console.log('Masuk ke finally');
      this.props.setActiveLoadingOverlay(false);
    }
  }

  loadManHourDates = async () => {
    try {
      const dateRange = getMonthDateRange(moment(this.fromDate).format('YYYY'), moment(this.fromDate).format('MM'));
     
      const start = dateRange.start.format('YYYY-MM-DD');
      const end = dateRange.end.format('YYYY-MM-DD');

      const response = await axios({
        url: `${config.api.manhour}?fromDate=${start}&toDate=${end}&type=${this.state.type}&projectId=${this.projectId}&contractorNameId=${this.user.user.contractorNameId}`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });

      const data = response.data.data.results;
      this.setState({
        manHourDates: data
      });
    } catch(e) {
    }

  }

  async componentDidMount() {
    try {
      const projectsResult = await axios({
        url: `${config.api.project}`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const projects = projectsResult.data.data.results;
      this.projectId = projects.length > 0 ? projects[0].id : 0;
  
      const apiControlUpdateManHourResult = await axios({
        url: `${config.api.apicontrols}/checking?route=/manhours&method=PUT&parameter=/:projectId/:date`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const isAllowedUpdateManHour = apiControlUpdateManHourResult.data.data.isAllowed;
      let contractors = null;
      let manHour = null;

      if(!isAllowedUpdateManHour) {
        const contractorsResult = await axios({
          url: config.api.contractorname,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });
  
        this.contractorId = contractorsResult
        contractors = contractorsResult.data.data.results;
        this.contractorId = contractors.length > 0 ? contractors[0].id : 0;
        this.contractorNameId = contractors.length > 0 ? contractors[0].contractorNameId : 0;
        console.log('contractors = ', contractors);
        try {
          const manHourResult = await axios({
            url: `${config.api.manhour}/${this.projectId}/${this.fromDate}/${this.toDate}/${this.contractorId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
          });
          manHour = manHourResult.data.data;

        } catch(e) {
          manHour = {
            totalWorker: 0,
            totalHour: 0,
            type: 'PLANT'
          }
        }
      } else {
        try {
          await this.loadManHour();
        } catch(e) {
          manHour = {
            totalWorker: 0,
            totalHour: 0,
            type: 'PLANT'
          };
        }
      }

      if(!isAllowedUpdateManHour) {
        const totalHours = manHour.totalHours;
        this.setState({
          projects,
          isAllowedUpdateManHour,
          contractors,
          type: manHour.type,
          totalHours: manHour.totalHour,
          totalWorkers: manHour.totalWorker,
          projectId: manHour.projectId
        });
      } else {
        this.setState({
          projects,
          isAllowedUpdateManHour,
          contractors
        });
      }
      


    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }

  handleChangeProject = async (event) => {
    this.projectId = event.target.value;
    await this.loadManHour();
  }

  handleSave = async () => {
    const header = {
      headers: { 'x-access-token': this.user.token }
    };

    const data = {
      totalHour: this.state.totalHours,
      totalWorker: this.state.totalWorkers,
      type: this.state.type
    };    

    this.setState({
      isSaving: true
    });
    await axios.put(`${config.api.manhour}/${this.projectId}/${this.date}`,
                    data,
                    header)
               .then(async () => {
                 this.setState({
                   successMessage: 'man hour has been saved successfully',
                   isSaving: false
                 });
                 await this.loadManHour();
               });
  }


  async handleMode(event) {
    const mode = event.target.value;
    console.log('Date = ', this.date);
    if(mode === 'EDIT') {
      this.fromDate = this.date;
      this.toDate = this.date;

      this.setState({
        mode
      }, async () => {
        await this.loadManHour();
      });
    } else {
      this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
      this.toDate = moment().format("YYYY-MM-DD"); 


      this.setState({
        isFilterDateShowing: false,
        dateMode: 'LAST_WEEK',
        mode
      }, async () => {
        await this.loadManHour();
      });
    }
  }

  markDateThatHaveTotalWorker = (moment) => {
    const manHourDates = this.state.manHourDates;
    const momentStr = moment.format("YYYY-MM-DD");
    const momentStrDD = moment.format("DD");

    console.log('man hour dates = ', manHourDates);
    const manHourDatesAfterFilter = R.filter(manHourDate => manHourDate.date == momentStr, manHourDates || []);
 
    if(manHourDatesAfterFilter.length > 0) {
      return (<div style={{border: '1px solid #3fc7fa'}} title={`Total worker = ${manHourDatesAfterFilter[0].totalWorker}`}>{momentStrDD}</div>);
    } else {
      return momentStrDD;
    }
  }

  handleContractor = async (event) => {
    this.contractorId = event.target.value;
    await this.loadManHour();
  }

  isAllowedUpdated = () => {
    return this.state.isAllowedUpdateManHour && this.state.mode === 'EDIT';
  }

  handleChangeTotalWorkers = (event) => {
    const totalWorkers = event.target.value;

    this.setState({
      totalWorkers
    });
  }

  handleChangeTotalHours = (event) => {
    const totalHours = event.target.value;

    this.setState({
      totalHours
    });
  }

  handleChangeType = (event) => {
    const type = event.target.value;

    this.setState({
      type
    });
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  async handleDateFilterShortcut(data) {
    const today = moment().format("YYYY-MM-DD");
    this.setState({
      dateMode: data
    });
    if(data === 'LAST_WEEK') {
      
      this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
      this.toDate = today;
      
      const state = await this.loadManHour();
      this.setState({
        isFilterDateShowing: false,
        ...state
      });
    } else if(data === "LAST_2_WEEK") {
      
      this.fromDate = moment().subtract(14, 'days').format("YYYY-MM-DD");
      this.toDate = today;
      
      const state = await this.loadManHour();
      this.setState({
        isFilterDateShowing: false,
        ...state
      });
    } else if(data === "LAST_30_DAYS") {
      this.fromDate = moment().subtract(30, 'days').format("YYYY-MM-DD");
      this.toDate = today;
      
      const state = await this.loadManHour();
      
      this.setState({
        isFilterDateShowing: false,
        ...state
      });
    } else if(data === 'NONE') {
      this.setState({
        isFilterDateShowing: true
      });
    }
  }

  async handleFromChange(from) {
    this.fromDate = moment(from).format('YYYY-MM-DD');
    if(this.toDate) {
      const state = await this.loadManHour();
      this.setState(state);
    } 
  }

  async handleToChange(to) {
    this.toDate = moment(to).format('YYYY-MM-DD');
    let state;
    if(this.fromDate) {
      this.showFromMonth();
      state = await this.loadManHour();
    } else {
      this.showFromMonth();
      state = await this.loadManHour();
    }
    this.setState(state);
  }

  render() {

    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  notifActive: true,
                  successMessage: ''
              });
          }, config.notifDuration);
    }

    // const SelectManHour = <Select/>
    return (
      <div style={{ zIndex: 1000, position: 'relative' }}>
          <br/>
          {this.state.successMessage
          ? <div className="alert alert-success" role="alert">
            {this.state.successMessage}
          </div>
          : null}
          <hr/>
          <h1>Man Hour</h1>
          <hr/>
          {
            this.state.mode === 'EDIT'
            ?
            <div>
              <label><b>Select calendar :</b></label>
              <div className="form-group">
                <FullCalendar
                  onChange={this.onChangeCalendar}
                  style={{ marginTop: 10, marginBottom: 10, width: '100%' }}
                  Select={Select}
                  fullscreen={false}
                  onSelect={this.onSelect}
                  onTypeChange={this.onTypeChange}
                  locale={enUS}
                  dateCellContentRender={this.markDateThatHaveTotalWorker}
                />
              </div>
            </div>
            :
            <div className="form-group">
            <div className="InputFromTo">
            <label><b>Choose date : </b></label>
            <select className="form-control"  
                    onChange={event => this.handleDateFilterShortcut(event.target.value)}
                    value={this.state.dateMode}>
              <option value="LAST_WEEK">Last week</option>
              <option value="LAST_2_WEEK">Last 2 weeks</option>
              <option value="LAST_30_DAYS">Last 30 days</option>
              <option value="NONE">Custom</option>
            </select>
            <div style={{display: this.state.isFilterDateShowing ? 'inline-block' : 'none', marginTop: '10px'}}>
              <DayPickerInput
                value={from}
                placeholder="From"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 1,
                  onDayClick: () => this.to.getInput().focus(),
                }}
                onDayChange={this.handleFromChange}
              />{' '}
              —{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  ref={el => (this.to = el)}
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 1,
                  }}
                  onDayChange={this.handleToChange}
                />
              </span>
          </div>
          <Helmet>
            <style>{`
            .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
              background-color: #f0f8ff !important;
              color: #4a90e2;
            }
            .InputFromTo .DayPicker-Day {
              border-radius: 0 !important;
            }
            .InputFromTo .DayPicker-Day--start {
              border-top-left-radius: 50% !important;
              border-bottom-left-radius: 50% !important;
            }
            .InputFromTo .DayPicker-Day--end {
              border-top-right-radius: 50% !important;
              border-bottom-right-radius: 50% !important;
            }
            .InputFromTo .DayPickerInput-Overlay {
              width: 550px;
            }
            .InputFromTo-to .DayPickerInput-Overlay {
              margin-left: -198px;
            }
            `}</style>
                </Helmet>
              </div>
          </div>
          }
        {this.state.contractors 
        && (this.user && this.user.user.type !== 'PROJECT_MONITOR')
          ?
          <div className="form-group">
            <label><b>Contractor Name :</b></label>
            <select className="form-control"
                    onChange={this.handleContractor}
                    value={this.state.contractorId}>
                {R.map(x => <option value={x.id}>{x.contractorName}</option>,
                        this.state.contractors || [])}
            </select>
          </div>
          :
          null}
          {this.state.projects ?
          <div className="form-group">
            <label><b>Project :</b></label>
            <select className="form-control" onChange={this.handleChangeProject}>
              {R.map(project => <option value={project.id}>{project.projectName}</option>, this.state.projects)}
            </select>  
          </div>
          :
          null}
          <div className="form-group">
            <label><b>Type :</b></label><br/>
            {this.isAllowedUpdated()
            ?
            <select className="form-control" value={this.state.type} onChange={this.handleChangeType}>
              <option value="PLANT">Plant</option>
              <option value="TEMA">Tema</option>
            </select>
            :
            <p>{this.state.type}</p>
            }
          </div>
          <div className="form-group">
              <label><b>Total hours :</b></label><br/>
              {this.isAllowedUpdated()
              ?
              <input className="form-control" 
                     value={this.state.totalHours} 
                     onChange={this.handleChangeTotalHours}/>
              :
              <p>{this.state.totalHours} hours</p>
              }
          </div>
          <div className="form-group">
            <label><b>Total workers :</b></label><br/>
            {this.isAllowedUpdated()
            ?
            <input className="form-control" 
                   value={this.state.totalWorkers} 
                   onChange={this.handleChangeTotalWorkers}/>
            :
            <p>{this.state.totalWorkers} workers</p>
            }
          </div>
        {this.state.isAllowedUpdateManHour
         && !this.state.isSaving
         && this.state.mode === 'EDIT'
         ?
         <div className="form-group">
          <button className="btn"
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={this.handleSave}>Save</button>
          </div>
          :
          null}
         <br/>
         <br/>
      </div>
    );
  }
}


export default ManHour;