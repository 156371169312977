import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class RestoreModal extends Component {
    constructor(props) {
        super(props);

        this.closeModal = props.closeModal;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.refreshTable = props.refreshTable;
        this.id = props.id;
        this.handleRestore = this.handleRestore.bind(this);
        this.user = getObject('user');
        this.deleteUrl = props.deleteUrl;

        this.state = {
            isSuccess: false,
            redirectTo: null
        };
    }

    async handleRestore() {
        try {

            this.setState({
                isActiveLoadingOverlay: true
            });

            if(this.props.clearMessage) {
                this.props.clearMessage();
            }
            
            const response = await axios({
                url: `${this.deleteUrl}/${this.id}/restore`,
                method: 'post',
                headers: { 'x-access-token': this.user.token }
            });

            this.setState({
                isSuccess: true
            });
            if(this.refreshTable) {
                this.refreshTable({
                    page: 0,
                    pageSize: 10,
                    filtered: []
                });
            }

            if(this.setSuccessMessage) {
                this.setSuccessMessage("item restored successfully");
            }

            if(this.props.redirectTo) {
                this.props.history.push(this.props.redirectTo);
            } else {
                this.closeModal();
            }
        } catch(e) {
            const data = e.response.data;
            if(this.setFailedMessage) {
                this.setFailedMessage(data.message);
            }

            if(this.closeModal) {
                this.closeModal();
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="align-middle">
                    <div className="form-group">
                        {this.props.customDeleteMessage  
                         ? this.props.customDeleteMessage    
                         : 'Are you sure want to restore the data ?'}
                    </div>
                </div>
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-6">
                        <button className="btn"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleRestore}>Yes</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.closeModal}>No</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default withRouter(RestoreModal);