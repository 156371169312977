import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class MyAccount extends Component {
    constructor(props) {
        super(props);

        this.props.setActiveLoadingOverlay(true);
        this.email = '';
        this.name = '';
        this.department = '';
        this.handleEmail = this.handleEmail.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleDepartment = this.handleDepartment.bind(this);
        this.handleBack = this.handleBack.bind(this);

        this.handleSave = this.handleSave.bind(this);


        const userLocalStorage = getObject('user');
        if(userLocalStorage) {
            this.user = userLocalStorage;
            const userObj = this.user.user;
            this.userObj = userObj;
            this.email = userObj.email;
            this.name = userObj.name;
            this.department = userObj.department;
            this.state = {
                id: userObj.id,
                typeId: userObj.type,
                newType: userObj.newType,
                companyId: userObj.companyId,
                companyName: userObj.companyName,
                failedMessage: '',
                isSuccess: false,
                isBackToHome: false
            };   
    
            this.props.handleClickMenu('my_account', 'account');
        }

        this.state = {
            isShowingUpdateCurrentUser: true
        }

        setObject('lastRoute', 'myaccount');

    }

    handleBack() {
        this.setState({
            isBackToHome : true
        });
    }

    handleEmail(event) {
        const email = event.target.value;
        this.email = email;
    }

    handleName(event) {
        const name = event.target.value;
        this.name = name;
    }

    handleDepartment(event) {
        const department = event.target.value;
        this.department = department;
    }

    handlePhoneNumber = (event) => {
        const phoneNumber = event.target.value;
        this.phoneNumber = phoneNumber;
    }

    async handleSave() {
        try {
            this.props.setActiveLoadingOverlay(true);
            this.setState({
                isSuccess: false,
                failedMessage: ''
            });
            const response = await axios({
                url: `${config.api.user}`,
                method: 'put',
                headers: { 'x-access-token': this.user.token },
                data: {
                    email: this.email,
                    name: this.name,
                    phoneNumber: this.phoneNumber == "" ? null : this.phoneNumber,
                    isReceivedAuditReportEmail: this.state.isReceivedAuditReportEmail,
                    isReceivedOpenItemEmail: this.state.isReceivedOpenItemEmail,
                    isReceivedWeeklyReportEmail: this.state.isReceivedWeeklyReportEmail,
                    isReceivedFixedOpenItemEmail: this.state.isReceivedFixedOpenItemEmail,
                    isReceivedToolboxTalkEmail: this.state.isReceivedToolboxTalkEmail              
                } 
            });
            this.setState({
                isSuccess: true,
                failedMessage: ''
            });

            const userResponse = response.data.data;
            const user = getObject('user');

            const userObj = user.user;
            user.user = {
                ...userObj,
                email: userResponse.email,
                name: userResponse.name,
                department: userResponse.department
            };

            this.phoneNumber = userResponse.phoneNumber;
            setObject('user', user);
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                isSuccess: false,
                failedMessage
            });        
        } finally {
            this.props.setActiveLoadingOverlay(false);
        }
    }

    componentWillUnmount() {
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    async componentDidMount() {
        try {
            if(this.user) {
                const apiControlUpdateCurrentUserResult = await axios({
                    url: `${config.api.apicontrols}/checking?route=/users&method=PUT&parameter=/`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
                
                const currentUserResult = await axios({
                    url: `${config.api.user}/${this.userObj.id}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
                const currentUser = currentUserResult.data.data;
    
                this.phoneNumber = currentUser ? currentUser.phoneNumber : "";
                this.setState({
                    isShowingUpdateCurrentUser:  apiControlUpdateCurrentUserResult.data.data.isAllowed,
                    id: this.userObj.id,
                    typeId: !currentUser ? "" : currentUser.type,
                    newType: !currentUser ? "" : currentUser.newType,
                    companyId: !currentUser ? "" : currentUser.companyId,
                    companyName: !currentUser ? "" : currentUser.companyName,
                    contractorName: !currentUser ? "" : currentUser.contractorName,
                    department: !currentUser ? "" : currentUser.department,
                    location: !currentUser ? "" : currentUser.location,
                    phoneNumber: !currentUser ? "" : currentUser.phoneNumber,
                    isSendingEmail: !currentUser ? false : currentUser.isSendingEmail,
                    isReceivedAuditReportEmail: !currentUser ? false : currentUser.isReceivedAuditReportEmail,
                    isReceivedOpenItemEmail: !currentUser ? false : currentUser.isReceivedOpenItemEmail,
                    isReceivedWeeklyReportEmail: !currentUser ? false : currentUser.isReceivedWeeklyReportEmail,
                    isReceivedFixedOpenItemEmail: !currentUser ? false : currentUser.isReceivedFixedOpenItemEmail,
                    isReceivedToolboxTalkEmail: !currentUser ? false : currentUser.isReceivedToolboxTalkEmail,
                    failedMessage: '',
                    isSuccess: false,
                    isBackToHome: false
                });
            }
        } finally {
            this.props.setActiveLoadingOverlay(false);
        }
    }

    render() {
        if(!this.user) {
            return <Redirect to="/login"/>;
        }

        if(!this.state.isShowingUpdateCurrentUser) {
            return <Redirect to="/"/>;
        }

        if(this.state.isSuccess) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        isSuccess: false
                    });
                }, config.notifDuration);
        }

        if(this.state.failedMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        failedMessage: false
                    });
                }, config.notifDuration);
        }
        const Component = () => (
            <div>
                <br/>
                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                  </div> 
                : null}
                {this.state.isSuccess
                ? <div className="alert alert-success" role="alert">
                      your account has been updated successfully.
                </div> 
                : null}
                <hr/>
                <h1>My Account</h1>
                <hr/>
                <form>
                    <div className="form-group">
                        <label><b>Email</b></label>
                        <input 
                            type="email"
                            className="form-control"
                            defaultValue={this.email}
                            onChange={this.handleEmail}/>
                    </div>
                    <div className="form-group">
                        <label><b>Name</b></label>
                        <input 
                            type="text"
                            className="form-control"
                            defaultValue={this.name}
                            onChange={this.handleName}/>
                    </div>
                    <div className="form-group">
                        <label><b>Phone number</b></label>
                        <input 
                            type="text"
                            className="form-control"
                            defaultValue={this.phoneNumber}
                            onChange={this.handlePhoneNumber}/>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" 
                               class="form-check-input" 
                               id="isReceivedAuditReportEmail"                             
                               checked={this.state.isReceivedAuditReportEmail}
                               onChange={() => {
                                   this.setState((state, prop) => ({
                                       isReceivedAuditReportEmail: !state.isReceivedAuditReportEmail
                                   }));
                               }} />
                        <label class="form-check-label" for="isReceivedAuditReportEmail"><b>Receive Audit Report via email</b></label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" 
                               class="form-check-input" 
                               id="isReceivedOpenItemEmail"                             
                               checked={this.state.isReceivedOpenItemEmail}
                               onChange={() => {
                                   this.setState((state, prop) => ({
                                       isReceivedOpenItemEmail: !state.isReceivedOpenItemEmail
                                   }));
                               }} />
                        <label class="form-check-label" for="isReceivedOpenItemEmail"><b>Receive Open Item Report via email</b></label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" 
                               class="form-check-input" 
                               id="isReceivedFixedOpenItemEmail"                             
                               checked={this.state.isReceivedFixedOpenItemEmail}
                               onChange={() => {
                                   this.setState((state, prop) => ({
                                       isReceivedFixedOpenItemEmail: !state.isReceivedFixedOpenItemEmail
                                   }));
                               }} />
                        <label class="form-check-label" for="isReceivedFixedOpenItemEmail"><b>Receive Fixed Open Item Report via email</b></label>
                    </div>
                    {this.state.typeId === "ADMIN_LOCATION"
                    || this.state.typeId === "ADMIN_DEPARTMENT"
                    ?
                    <div class="form-check">
                        <input type="checkbox" 
                               class="form-check-input" 
                               id="isReceivedWeeklyReportEmail"                             
                               checked={this.state.isReceivedWeeklyReportEmail}
                               onChange={() => {
                                   this.setState((state, prop) => ({
                                       isReceivedWeeklyReportEmail: !state.isReceivedWeeklyReportEmail
                                   }));
                               }} />
                        <label class="form-check-label" for="isReceivedWeeklyReportEmail"><b>Receive Weekly Report via email</b></label>
                    </div>
                    :
                    null}
                    <div class="form-check">
                        <input type="checkbox" 
                               class="form-check-input" 
                               id="isReceivedToolboxTalkEmail"                             
                               checked={this.state.isReceivedToolboxTalkEmail}
                               onChange={() => {
                                   this.setState((state, prop) => ({
                                       isReceivedToolboxTalkEmail: !state.isReceivedToolboxTalkEmail
                                   }));
                               }} />
                        <label class="form-check-label" for="isReceivedToolboxTalkEmail"><b>Receive Toolbox Talk Report via email</b></label>
                    </div>
                    <br/>
                    {this.state.typeId === 'INSPECTOR'
                    ?
                    <div className="form-group">
                        <label><b>Department</b></label>
                        <p>{this.state.department}</p>
                    </div>
                    :
                    null}
                    {this.state.typeId === 'INSPECTOR'
                    ?
                    <div className="form-group">
                        <label><b>Location</b></label>
                        <p>{this.state.location}</p>
                    </div>
                    :
                    null}
                    {this.state.typeId
                    ?
                    <div className="form-group">
                        <label><b>Type</b></label>
                        <p>{this.state.newType}</p>
                    </div>
                    :
                    null}
                    {
                    this.state.typeId === 'CONTRACTOR' 
                    && this.state.contractorName
                    ? 
                    <div className="form-group">
                        <label><b>Contractor Name</b></label>
                        <p>{this.state.contractorName}</p>
                    </div>
                    :
                    null
                    }
                    {
                    (this.state.typeId === 'ADMIN'
                    || this.state.typeId === 'SUPER_ADMIN'
                    || this.state.typeId === 'INSPECTOR'
                    || this.state.typeId === 'ADMIN_LOCATION'
                    || this.state.typeId === 'ADMIN_DEPARTMENT')
                    && this.state.companyName
                    ?    
                    <div className="form-group">
                        <label><b>Company</b></label>
                        <p>{this.state.companyName}</p>
                    </div>
                    :
                    null
                    }
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                <br/>
                <br/>
            </div>
        );

        return this.user ? <Component /> : <Redirect to="/login" />;
    }
}


export default MyAccount;