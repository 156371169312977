function getColumnWidth (data, accessor, headerText) {
    let max = 0;

    const maxWidth = 400;
    const magicSpacing = 10;

    for (var i = 0; i < data.length; i++) {
        if (data[i] !== undefined && data[i][accessor] !== null) {
            if (JSON.stringify(data[i][accessor] || 'null').length > max) {
                max = JSON.stringify(data[i][accessor] || 'null').length;
            }
        }
    }

    return Math.min(maxWidth, Math.max(max, headerText.length) * magicSpacing);
}

export {
    getColumnWidth
};