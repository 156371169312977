import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class DeleteInspectionPdfModal extends Component {
    constructor(props) {
        super(props);

        this.closeModal = props.closeModal;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.refreshTable = props.refreshTable;
        this.id = props.id;
        this.closeModal = props.closeModal;
        this.inspectionId = props.inspectionId;
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleIsIncludingPositiveFinding = this.handleIsIncludingPositiveFinding.bind(this);
        this.user = getObject('user');
        this.deleteUrl = props.deleteUrl;

        this.state = {
            isSuccess: false,
            redirectTo: null,
            isIncludingPositiveFinding: false
        };
    }

    async handleDownload() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const url = `${config.api.inspection}/${this.inspectionId}/pdf/download?isIncludingPositiveFinding=${this.state.isIncludingPositiveFinding}`;
    
            this.closeModal();
            window.open(url);
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleCloseModal() {
        this.closeModal();
    }

    handleIsIncludingPositiveFinding(event) {
        const target = event.target;
        const isIncludingPositiveFinding = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            isIncludingPositiveFinding
        });
    }

    async handleDelete() {
        try {

            this.setState({
                isActiveLoadingOverlay: true
            });

            if(this.props.clearMessage) {
                this.props.clearMessage();
            }
            
            const response = await axios({
                url: `${this.deleteUrl}/${this.id}`,
                method: 'delete',
                headers: { 'x-access-token': this.user.token }
            });

            this.setState({
                isSuccess: true
            });
            if(this.refreshTable) {
                this.refreshTable({
                    page: 0,
                    pageSize: 10,
                    filtered: []
                });
            }

            if(this.setSuccessMessage) {
                this.setSuccessMessage("item deleted successfully");
            }

            if(this.props.redirectTo) {
                this.props.history.push(this.props.redirectTo);
            } else {
                this.closeModal();
            }
        } catch(e) {
            const data = e.response.data;
            if(this.setFailedMessage) {
                this.setFailedMessage(data.message);
            }

            if(this.closeModal) {
                this.closeModal();
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div className="form-group">
                    <div className="col-md-12">
                        <div className="form-check">
                            <input className="form-check-input" 
                                type="checkbox" 
                                value="" 
                                id="defaultCheck1"
                                onChange={this.handleIsIncludingPositiveFinding}/>
                            <label className="form-check-label" for="defaultCheck1">
                                <b>Include positive finding in report</b>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleDownload}>Download</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default withRouter(DeleteInspectionPdfModal);