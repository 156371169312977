import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject, setSuccessMessage, getSuccessMessage, clearSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import QuestionTemplateCreate from '../components/QuestionTemplateCreate';
import SectionTemplateEdit from '../components/SectionTemplateEdit';
import QuestionTemplateEdit from '../components/QuestionTemplateEdit';
import QuestionTemplateDelete from '../components/QuestionTemplateDelete';
import PointChangedConfirmation from '../components/PointChangedConfirmation';
import QuestionPointEdit from '../components/QuestionPointEdit';
import DeleteModal from '../components/DeleteModal';
import {convertToStd} from '../utils/date';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';

class QuestionTemplate extends Component {

    constructor(props) {
        super(props);
        
        this.filters = [
            {id: 'question', value: ''},
            {id: 'point', value: ''},
            {id: 'nonConformingResponse', value: ''},
            {id: 'recommendation', value: ''}
        ];

        const successMessage = getSuccessMessage();
        if(successMessage) {
            this.successMessage = successMessage;
            clearSuccessMessage();
        }

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showEditQuestionModal: false,                                              
            showDeleteModal: false,
            showDeleteQuestionModal: false,
            showAddModal: false,
            showEditPoint : false,
            showEditPointConfirmation: false,
            editQuestionId: 0,
            editId: 0,
            deleteId: 0,
            deleteQuestionId: 0,
            isBackToInspectionTemplate: false,
            pointFailedMessage: '',
            failedMessage: '',
            pointsToChange: [],
            section: null,
            notifActive: false,
            isShowingUpdateSectionTemplate: false,
            isShowingDeleteSectionTemplate: false,
            isShowingCreateQuestionTemplate: false,
            isShowingUpdateQuestionTemplate: false,
            isShowingDeleteQuestionTemplate: false,
            isAuthorized: true,
            comment: ''
        };
        this.fetchData = this.fetchData.bind(this);
        this.sectionId = props.sectionId;
        this.templateId = props.templateId;
        this.hideNavBar = props.hideNavBar;
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleEditPoint = this.handleEditPoint.bind(this);
        this.handleCloseEditPointModal = this.handleCloseEditPointModal.bind(this);
        this.setPointsToChange = this.setPointsToChange.bind(this);
        this.handleClosePointConfirmationModal = this.handleClosePointConfirmationModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.handleEditQuestion = this.handleEditQuestion.bind(this);
        this.handleCloseEditQuestionModal = this.handleCloseEditQuestionModal.bind(this);
        this.handleCloseDeleteQuestionModal = this.handleCloseDeleteQuestionModal.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
        this.setPointFailedMessage = this.setPointFailedMessage.bind(this);
        this.loadSection = this.loadSection.bind(this);
        this.timer = null;
        this.scoringType = null;

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('template', 'master');

        this.user = getObject('user');
        setObject('lastRoute', `inspectiontemplate/${this.props.templateId}/sectiontemplate/${this.props.sectionId}/questiontemplate`);
    }

    handleEditQuestion(editQuestionId) {
        this.hideNavBar(true);
        this.setState({
            editQuestionId,
            showEditQuestionModal: true
        });
    }

    loadSection(onDone) {
        axios({
            url: `${config.api.sectiontemplate}/${this.sectionId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        })
        .then(response => {
            this.setState(() => {
                const data = response.data.data;
                this.scoringType = data.scoringType;
                return {
                    section: data
                };
            });
            if(onDone) {
                onDone();
            }
        }).catch(e => {
            const data = e.response.data;
        });
    }

    async componentDidMount() {
        try {
            if(this.user) {
                const apiControlUpdateSectionTemplatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/sectiontemplates&method=PUT&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlDeleteSectionTemplatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/sectiontemplates&method=DELETE&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlCreateQuestionTemplatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/questiontemplates&method=POST&parameter=/`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlUpdateQuestionTemplatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/questiontemplates&method=PUT&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlDeleteQuestionTemplatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/questiontemplates&method=DELETE&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const sectionPromise = axios({
                    url: `${config.api.sectiontemplate}/${this.sectionId}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const [apiControlUpdateSectionTemplateResult, 
                       apiControlDeleteSectionTemplateResult, 
                       apiControlCreateQuestionTemplateResult,
                       apiControlUpdateQuestionTemplateResult,
                       apiControlDeleteQuestionTemplateResult,
                       sectionResult] = await Promise.all([
                           apiControlUpdateSectionTemplatePromise, 
                           apiControlDeleteSectionTemplatePromise, 
                           apiControlCreateQuestionTemplatePromise,
                           apiControlUpdateQuestionTemplatePromise,
                           apiControlDeleteQuestionTemplatePromise,
                           sectionPromise]);
        
                this.setState(() => {
                    const data = sectionResult.data.data;
                    this.scoringType = data.scoringType;
                    return {
                        section: data,
                        isShowingUpdateSectionTemplate: apiControlUpdateSectionTemplateResult.data.data.isAllowed,
                        isShowingDeleteSectionTemplate: apiControlDeleteSectionTemplateResult.data.data.isAllowed,
                        isShowingCreateQuestionTemplate: apiControlCreateQuestionTemplateResult.data.data.isAllowed,
                        isShowingUpdateQuestionTemplate: apiControlUpdateQuestionTemplateResult.data.data.isAllowed,
                        isShowingDeleteQuestionTemplate: apiControlDeleteQuestionTemplateResult.data.data.isAllowed
                    };
                });
            }
        } catch(e) {
            this.props.history.push('/');
        }

    }

    handleFilterInput() {
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
    }
    
    setPointFailedMessage(pointFailedMessage) {
        this.setState({
            pointFailedMessage
        })
    }

    handleCloseFilterInput() {
        this.filters = [
          {id: 'question', value: ''},
          {id: 'point', value: ''},
          {id: 'nonConformingResponse', value: ''},
          {id: 'recommendation', value: ''}
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }
    
    handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }

    handleCloseEditPointModal() {
        this.hideNavBar(false);
        this.setState({
            showEditPoint: false
        });
    }

    handleCloseDeleteQuestionModal() {
        this.hideNavBar(false);
        this.setState({
            showDeleteQuestionModal: false
        });
        this.loadSection();
    }

    handleClosePointConfirmationModal() {
        this.setState((state, props) => {
            this.hideNavBar(false);
            return {
                showEditPointConfirmation: false,
                showEditPoint: false
            }
        });
    }

    setPointsToChange(pointsToChange) {
        this.hideNavBar(true);
        this.setState(
            (state, props) => {
                return {pointsToChange, showEditPointConfirmation: true};
        });
    }

    handleBack() {
        this.setState({
            isBackToInspectionTemplate : true
        });
    }

    refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        const filtered = this.filters;

        console.log(filtered);
    
        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = 'orderBy=id&orderDirection=desc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        axios({
            url: `${config.api.sectiontemplate}/${this.sectionId}/questions?size=${size}&page=${page}&${filterString}&${sortedString}`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        }).then(response => {
            const data = response.data.data;
            this.setState({
                data: data.results,
                pages: data.totalPage,
                loading: false
            });
        }).catch(err => {
            const statusCode = err.response.status;
            if(statusCode == 403) {
                this.setState({
                    isAuthorized: false
                });
            }
        });
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleCloseEditQuestionModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditQuestionModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    } 

    handleEditPoint() {
        this.hideNavBar(true);
        this.setState({
            showEditPoint: true,
            pointFailedMessage: ''
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState(() => {
            this.successMessage = successMessage;
            return {
                failedMessage: ''
            };
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }


    componentWillUnmount() {
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    render() {
        if(!this.state.isAuthorized) {
            return <Redirect to="/"/>;
        }

        if(!this.user) {
            return <Redirect to="/login" />;
        }

        if(this.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.successMessage = '';
                    this.setState({
                        notifActive: true
                    })
                }, config.notifDuration);
        } 
        
        const columns = [
            {
                Header: "Question",
                accessor: "question",
                sortable: true
            },
            {
                Header: "Point",
                accessor: "point"
            },
            {
                Header: "Non conforming response",
                accessor: "nonConformingResponse"
            },
            {
                Header: "Recommendation",
                accessor: "recommendation"
            },
            {
                Header: "Created date",
                accessor: "createdDate",
                sortable: true,
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                      return '';
                    }
                    return date;
                }
            },
            {
                Header: "Updated date",
                accessor: "updatedDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                      return '';
                    }
                    return date;
                }
            }
        ];


        if(this.state.isShowingUpdateQuestionTemplate
            || this.state.isShowingDeleteQuestionTemplate) {
            columns.unshift({
                Header: "Action",
                accessor: 'id',
                width: 134,
                filterable: false,
                Cell: cellInfo => {
                    return (
                        <div className="row">
                            <div className="col">
                                {this.state.isShowingUpdateQuestionTemplate
                                ?
                                <button type="button"
                                className="btn btn-sm"
                                onClick={() => this.handleEditQuestion(cellInfo.value)}>Edit</button>
                                :
                                null}
                            </div>
                            <div className="col">
                                {this.state.isShowingDeleteQuestionTemplate
                                ?
                                <button type="button"
                                    className="btn btn-sm"
                                    onClick={() => this.setState({ 
                                        deleteQuestionId: cellInfo.value,
                                        showDeleteQuestionModal: true
                                    })}>Delete</button>
                                :
                                null}
                            </div>
                        </div>);
                }
            });

        }
        const { data, pages, loading } = this.state;
        const component = (<div>
                <br/>
                {this.successMessage
                    ? <div className="alert alert-success" role="alert">
                        {this.successMessage}
                    </div>
                    : null}
                {this.state.failedMessage
                    ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                    </div>
                    : null}
                <hr/>
                {this.state.section
                 ?
                 <div className="grid-container">
                    <div className="grid-item">
                        <h1>{this.state.section.title}</h1>
                    </div>
                    <div className="grid-item">
                        {this.state.isShowingUpdateSectionTemplate
                        ?
                        <button className="btn float-right" onClick={() => this.handleEdit(this.props.sectionId)} title="Edit"><span class="fe fe-edit-2"></span></button>
                        :
                        null}
                    </div>
                    <div className="grid-item">
                        {this.state.isShowingDeleteSectionTemplate
                        ?
                        <button className="btn float-right" onClick={() => this.handleDelete(this.props.sectionId)} title="Delete"><span class="fe fe-trash-2"></span></button>
                        :
                        null}
                    </div>
                 </div>
                 :
                 null}
                {this.state.section 
                 ?
                 <div className="row">
                    <div className="col">
                        <b>Scoring type : </b><br/>
                        <p>{this.state.section.scoringType}</p>
                    </div>
                    <div className="col">
                        <b>Point : </b><br/>
                        <p>{this.state.section.point}</p>
                    </div>
                    <div className="col">
                        <b>Finding type : </b><br/>
                        <p>{this.state.section.findingType}</p>
                    </div>
                    {this.state.section.createdDate
                    ?
                    <div className="col">
                        <b>Created date: </b><br/>
                        <p>{convertToStd(this.state.section.createdDate)}</p>
                    </div>
                    :
                    null}
                    {this.state.section.updatedDate
                    ?
                    <div className="col">
                        <b>Updated date: </b><br/>
                        <p>{convertToStd(this.state.section.updatedDate)}</p>
                    </div>
                    :
                    null}
                 </div>
                 :
                 null}
                 <div className="row">
                    {this.state.section && this.state.section.comment
                    ?
                    <div className="col">
                        <b>Comment : </b><br/>
                        <p>{this.state.section.comment}</p>
                    </div>
                    :
                    null}
                </div>
                <hr/>
                <div className="grid-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="grid-item">
                        {this.state.isShowingCreateQuestionTemplate
                         ?
                         <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Question</span></button>
                         :
                         null}
                    </div>
                    <div className="grid-item">
                    {(this.user.user.type === "ADMIN"
                        || this.user.user.type === "SUPER_ADMIN")
                        && this.state.data.length > 0
                        && this.scoringType === "CUMULATIVE"
                        ?
                        <button type="button" className="btn float-right" onClick={this.handleEditPoint}>Edit point</button>
                        :
                        null}
                    </div>
                    {!this.state.showFiltering
                    ?
                    <div className="grid-item">
                        <button type="button" className="btn float-right" onClick={this.handleFilterInput} title="Search"><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}

                </div>
                {this.state.showFiltering 
                ?
                <div>
                <br/>
                <br/>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Question</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Point</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(1, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Non conforming response</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(2, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Recommendation</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(3, e.target.value)}/>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    <QuestionTemplateCreate
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        setSuccessMessage={this.setSuccessMessage}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showEditPoint}>
                    <QuestionPointEdit
                        closeModal={this.handleCloseEditPointModal}
                        setPointsToChange={this.setPointsToChange}
                        sectionId={this.sectionId} 
                        pointFailedMessage={this.state.pointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showEditModal}
                    contentLabel="Minimal Modal Example">
                    <SectionTemplateEdit
                        closeModal={this.handleCloseEditModal}
                        id={this.state.editId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        clearMessage={this.clearMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        onSuccess={() => this.loadSection()} />
                </ReactModal>
                <ReactModal isOpen={this.state.showEditQuestionModal}>
                    <QuestionTemplateEdit
                        closeModal={this.handleCloseEditQuestionModal}
                        id={this.state.editQuestionId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal isOpen={this.state.showEditPointConfirmation}
                            style={{
                                content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)'
                                }
                            }}>
                    <PointChangedConfirmation 
                        pointsToChange={this.state.pointsToChange}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        closeModal={this.handleClosePointConfirmationModal}
                        closeConfirmationModal={() => this.setState(() => {
                                                    return {showEditPointConfirmation: false} } )}
                        loadSection={this.loadSection}
                        setPointFailedMessage={this.setPointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}>
                    <DeleteModal
                        id={this.state.deleteId}
                        deleteUrl={config.api.sectiontemplate} 
                        redirectTo={`/inspectiontemplate/${this.props.templateId}/sectiontemplate`}
                        closeModal={this.handleCloseDeleteModal}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteQuestionModal}
                        style={{
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)'
                            }
                        }}>
                        <DeleteModal
                            closeModal={this.handleCloseDeleteQuestionModal}
                            id={this.state.deleteQuestionId}
                            refreshTable={this.fetchData}
                            setSuccessMessage={this.setSuccessMessage}
                            setFailedMessage={this.setFailedMessage}
                            deleteUrl={config.api.questiontemplate} />
                    </ReactModal>
                <ReactTable
                    columns={columns}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="col-md-12">
                        <Link to={`/inspectiontemplate/${this.templateId}/sectiontemplate`} 
                              class="btn"><span class="fe fe-arrow-left"> Back </span></Link>
                    </div>
                </div>
                <br/>
            </div>);

        return component;
    }
}

export default withRouter(QuestionTemplate);