import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject, getSuccessMessage, clearSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import InspectionTemplateEdit from '../components/InspectionTemplateEdit';
import SectionTemplateCreate from '../components/SectionTemplateCreate';
import SectionTemplateEdit from '../components/SectionTemplateEdit';
import SectionTemplateDelete from '../components/SectionTemplateDelete';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import {convertToStd} from '../utils/date';

class SectionTemplate extends Component {

    constructor(props) {
        super(props);

        this.filters = [
            {id: 'title', value: ''},
            {id: 'scoringType', value: ''},
            {id: 'point', value: ''}
        ];

        const successMessage = getSuccessMessage();
        if(successMessage) {
            this.successMessage = successMessage;
            clearSuccessMessage();
        }

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showDeleteModal: false,
            showAddModal: false,
            editId: 0,
            deleteId: 0,
            isBackToInspectionTemplate: false,
            failedMessage: '',
            redirectId: 0,
            template: null,
            showFiltering: false,
            isShowingEditInspectionTemplate: false,
            isShowingDeleteInspectionTemplate: false,
            isAuthorized: true
        };

        this.fetchData = this.fetchData.bind(this);
        this.templateId = props.templateId;
        this.hideNavBar = props.hideNavBar;
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleQuestion = this.handleQuestion.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.loadInspection = this.loadInspection.bind(this);
        this.timer = null;

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('template', 'master');
        this.user = getObject('user');
        setObject('lastRoute', `inspectiontemplate/${this.props.templateId}/sectiontemplate`);
    }

    loadInspection() {
        axios({
            url: `${config.api.inspectiontemplate}/${this.templateId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        })
        .then(response => {
            this.setState(() => {
                const data = response.data.data;
                return {
                    template: data
                };
            });
        }).catch(e => {
            const data = e.response.data;
        });
    }

    async componentDidMount() {
        try {
            if(this.user) {
    
                const inspectionTemplatePromise = axios({
                    url: `${config.api.inspectiontemplate}/${this.templateId}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlCheckingInspectionCreatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspectiontemplates&method=PUT&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                  });
        
                const apiControlCheckingInspectionDeletePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspectiontemplates&method=DELETE&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });

                const apiControlCheckingSectionCreatePromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/sectiontemplates&method=POST&parameter=/`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const [inspectionTemplateResult, 
                       apiControlCheckingInspectionCreateResult, 
                       isShowingDeleteInspectionTemplate,
                       isShowingCreateSectionTemplate] = 
                    await Promise.all([
                        inspectionTemplatePromise, 
                        apiControlCheckingInspectionCreatePromise, 
                        apiControlCheckingInspectionDeletePromise,
                        apiControlCheckingSectionCreatePromise]);
        
                this.setState({
                    template: inspectionTemplateResult.data.data,
                    isShowingEditInspectionTemplate: apiControlCheckingInspectionCreateResult.data.data.isAllowed,
                    isShowingDeleteInspectionTemplate: isShowingDeleteInspectionTemplate.data.data.isAllowed,
                    isShowingCreateSectionTemplate: isShowingCreateSectionTemplate.data.data.isAllowed
                });
            }
        } catch(err) {
            console.log(err);
            const statusCode = err.response.status;
            this.props.history.push('/');
        }
    }

    handleFilterInput() {
        this.setState((state) => ({
            showFiltering: !state.showFiltering
        }));
    }

    handleCloseFilterInput() {
        this.filters = [
          {id: 'title', value: ''},
          {id: 'scoringType', value: 'ALL'},
          {id: 'point', value: ''}
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }

    handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }

    handleQuestion(redirectId) {
        this.setState({
            redirectId
        });
    }

    handleBack() {
        this.setState({
            isBackToInspectionTemplate : true
        });
    }

    refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const filtered = this.filters;

        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = '&orderBy=id&orderDirection=desc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        axios({
            url: `${config.api.inspectiontemplate}/${this.templateId}/sections?size=${size}&page=${page}&${filterString}&${sortedString}`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        })
        .then(response => {
            const data = response.data.data;
            this.setState({
                data: data.results,
                pages: data.totalPage,
                loading: false
            });
        })
        .catch(err => {
            console.log(err);
            const statusCode = err.response.status;
            if(statusCode == 403) {
                this.setState({
                    isAuthorized: false
                });
            }
        });
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId: this.props.templateId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState(() => {
            this.successMessage = successMessage;
            return {
                failedMessage: ''
            }
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    componentWillUnmount() {
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    render() {

        if(!this.state.isAuthorized) {
            return <Redirect to="/"/>;
        }

        if(this.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.successMessage = '';
                    this.setState({
                        notifActive: true
                    })
                }, config.notifDuration);
        }

        if(!this.user) {
            return <Redirect to="/login"/>;
        }
        const { data, pages, loading } = this.state;
        const component = (
            <div>
                <br/>
                {this.successMessage
                ? 
                <div className="alert alert-success" role="alert">
                    {this.successMessage}
                </div>
                : null}
                {this.state.failedMessage
                ? 
                <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                </div>
                : null}
                <hr/>
                {this.state.template
                 ?
                 <div className="grid-container">
                    <div className="grid-item">
                        <h1>{this.state.template.title}</h1>
                    </div>
                    <div className="grid-item" style={{ justifySelf: "end" }}>
                        {this.state.isShowingEditInspectionTemplate
                        ?
                        <button className="btn" onClick={this.handleEdit} title="Edit"><span class="fe fe-edit-2"></span></button>
                        :
                        null}
                    </div>
                    <div className="grid-item" style={{ justifySelf: "end" }}>
                        {this.state.isShowingDeleteInspectionTemplate
                        ?
                        <button className="btn" onClick={() => this.handleDelete(this.props.templateId)} title="Delete"><span class="fe fe-trash-2"></span></button>
                        :
                        null}
                    </div>
                 </div>
                 :
                 null}
                {this.state.template 
                 ?
                <div className="row">
                    <div className="col">
                        <b>Description : </b><br/>
                        <p>{this.state.template.description}</p>
                    </div>
                    {this.state.template.createdDate
                    ?
                    <div className="col">
                        <b>Created date: </b><br/>
                        <p>{convertToStd(this.state.template.createdDate)}</p>
                    </div>
                    :
                    null}
                    {this.state.template.updatedDate
                    ?
                    <div className="col">
                        <b>Updated date: </b><br/>
                        <p>{convertToStd(this.state.template.updatedDate)}</p>
                    </div>
                    :
                    null}
                </div>
                 :
                 null}
                <hr/>
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="col">
                        {this.state.isShowingCreateSectionTemplate
                        ?
                        <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Section</span></button>
                        :
                        null}
                    </div>
                    {!this.state.showFiltering
                    ?
                    <div className="col">
                        <button type="button" className="btn float-right" onClick={this.handleFilterInput} title="Search"><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}
                </div>
                {this.state.showFiltering 
                ?
                <div>
                <br/>
                <br/>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Title</b></label>
                            <input  className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)} />
                        </div>
                        <div className="col-md-auto">
                            <label><b>Scoring type</b></label>
                            <select className="form-control" onChange={e => this.handleSearch(1, e.target.value)}>
                                <option value="ALL">All</option>
                                <option value="CUMULATIVE">Cumulative</option>
                                <option value="ALL_OR_NOTHING">All or nothing</option>
                            </select>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Point</b></label>
                            <input  className="form-control" 
                                    type="number"
                                    defaultValue=""
                                    onChange={e => this.handleSearch(2, e.target.value)} />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    <SectionTemplateCreate
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        templateId={this.templateId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        hideNavBar={this.hideNavBar} />
                </ReactModal>
                <ReactModal isOpen={this.state.showEditModal}
                        contentLabel="Minimal Modal Example">
                        <InspectionTemplateEdit
                            closeModal={this.handleCloseEditModal}
                            id={this.state.editId}
                            refreshTable={this.fetchData}
                            setSuccessMessage={this.setSuccessMessage}
                            setFailedMessage={this.setFailedMessage} 
                            clearMessage={this.clearMessage}
                            tablePaging={{
                                page: this.page,
                                pageSize: this.pageSize
                            }}
                            onSuccess={() => this.loadInspection() }/>
                </ReactModal>
                {/* <ReactModal isOpen={this.state.showEditModal}
                    contentLabel="Minimal Modal Example">
                    <SectionTemplateEdit
                        closeModal={this.handleCloseEditModal}
                        id={this.state.editId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        clearMessage={this.clearMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }} />
                </ReactModal> */}
                <ReactModal isOpen={this.state.showDeleteModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}>
                    <DeleteModal
                        id={this.state.deleteId}
                        deleteUrl={config.api.inspectiontemplate} 
                        redirectTo='/inspectiontemplate'
                        closeModal={this.handleCloseDeleteModal}/>
                </ReactModal>
                <ReactTable
                    columns={[
                        {
                            Header: "Action",
                            accessor: 'id',
                            width: 56,
                            filterable: false,
                            Cell: cellInfo => {
                                return (
                                    <div className="row">
                                        <div className="col-md-auto">
                                            <Link to={`/inspectiontemplate/${this.templateId}/sectiontemplate/${cellInfo.value}/questiontemplate`} 
                                                  class="btn btn-sm">Edit</Link>                      
                                        </div>
                                    </div>);
                            }
                        },
                        {
                            Header: "Title",
                            accessor: "title",
                            sortable: true
                        },
                        {
                            Header: "Scoring type",
                            accessor: "scoringType",
                            Filter: ({ filter, onChange }) =>
                            <select
                              onChange={event => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : "ALL"}
                            >
                              <option value="ALL">All</option>
                              <option value="CUMULATIVE">Cumulative</option>
                              <option value="ALL_OR_NOTHING">All or nothing</option>
                            </select>
                        },
                        {
                            Header: "Point",
                            accessor: "point"
                        },
                        {
                            Header: "Finding type",
                            accessor: "findingType"
                        },
                        {
                            Header: "Created date",
                            accessor: "createdDate",
                            Cell: cellInfo => {
                                const date = convertToStd(cellInfo.value);
                                if(date == 'Invalid date') {
                                  return '';
                                }
                                return date;
                            }
                        },
                        {
                            Header: "Updated date",
                            accessor: "updatedDate",
                            Cell: cellInfo => {
                                const date = convertToStd(cellInfo.value);
                                if(date == 'Invalid date') {
                                  return '';
                                }
                                return date;
                            }
                        }
                    ]}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="col-md-12">
                        <Link to={`/inspectiontemplate`} 
                              class="btn"><span class="fe fe-arrow-left"> Back </span></Link>                      
                    </div>
                </div>
            </div>
        );

        return component;
    }
}

export default withRouter(SectionTemplate);