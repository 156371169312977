import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import axios from 'axios';
import {setObject, getObject} from '../utils/localstorage';
import config from '../config';
import mainLogo from '../logo.png';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {isMobileOrTablet, getRedirectToOpenItem} from '../utils/mobile';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', 
            password: '', 
            isLoginSuccess: false,
            failedMessage: '',
            isShowing: 'fe fe-eye-off'
        };

        this.user = getObject('user');

        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickShowingPassword = this.handleClickShowingPassword.bind(this);
    }

    handleClickShowingPassword(event) {
        const value = event.target.value;
        if(this.state.isShowing === 'fe fe-eye-off') {
            this.setState({
                isShowing: 'fe fe-eye'
            });
        } else {
            this.setState({
                isShowing: 'fe fe-eye-off'
            });
        }
    }

    handleEmail(event) {
        this.setState({email: event.target.value});
    }

    handlePassword(event) {
        this.setState({password: event.target.value});
    }
    
    async handleSubmit(event) {
        event.preventDefault();
        try {
            this.setState({
                isLoginSuccess: false,
                failedMessage: '',
                isActiveLoadingOverlay: true
            });
            const response = await axios.post(`${config.api.user}/signin`, {
                email: this.state.email,
                password: this.state.password
            });        
            const data = response.data;
            if(data.code == 200) {
                const userData = data.data;
                setObject('user', userData);
                this.props.setUserType(userData.user.type);
                this.setState({
                    isLoginSuccess: true
                });
            }
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });

        }
    }
    
    render() {

     const lastRoute = getObject('lastRoute');
     const isAlreadyLogin = getObject('isAlreadyLogin');
     if(isMobileOrTablet() && lastRoute === 'inspectionopenitem' && !isAlreadyLogin) {
        setObject('isAlreadyLogin', true);
        window.location = getRedirectToOpenItem();
        return null;
     }

     if(this.user || this.state.isLoginSuccess) {
         const lastRoute = getObject('lastRoute');
         if(lastRoute) {
            window.location = lastRoute;
         } else {
             window.location = "/"; 
         }
     } 

     return (
        <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
        <div className="row justify-content-center">       
            <div className="col-12 col-md-5 col-xl-4 my-5">            
                <img src={mainLogo} width={300} height={220}/>
                <h1 className="display-4 text-center mb-3">
                    Sign in
                </h1>                
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                
                    <label>Email Address</label>

                
                    <input type="email" 
                           className="form-control" 
                           placeholder="name@address.com" 
                           value={this.state.email} 
                           onChange={this.handleEmail}/>

                    </div>

                    <div className="form-group">

                    <div className="row">
                        <div className="col">
                            
                        <label>Password</label>

                        </div>
                        <div className="col-auto">
                        
                        </div>
                    </div> 
                    <div className="input-group">


                        <input type={this.state.isShowing === "fe fe-eye" ? "text" : "password"} 
                               className="form-control form-control-appended" 
                               placeholder="Enter your password"
                               value={this.state.password}
                               onChange={this.handlePassword} />

                        <div className="col-md-2">
                            <button class="btn" 
                                    type="button" 
                                    onClick={this.handleClickShowingPassword}
                                    title={this.state.isShowing === "fe fe-eye" ? "Hide password" : "Show password"}><span className={this.state.isShowing}></span></button>
                        </div>                       
                    </div>
                    </div>

                    {this.state.failedMessage
                    ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                    </div>
                    : null}

                    <button className="btn btn-lg btn-block">
                    Sign in
                    </button>
                </form>
            </div>
        </div> 
        </BlockUi>
      );
    }
  }

export default Login;