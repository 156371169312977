import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import EscalationProcessCreate from '../components/EscalationProcessCreate';
import EscalationProcessEdit from '../components/EscalationProcessEdit';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import {convertToStd} from '../utils/date';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';

class EscalationProcess extends Component {

  constructor(props) {
    super(props);

    this.filters = [
      {id: 'title', value: ''},
      {id: 'description', value: ''}
    ];

    this.state = {
      data: [],
      pages: null,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showAddModal: false,
      isBackToHome: false,
      editId: 0,
      deleteId: 0,
      redirectId: 0,
      successMessage: '',
      failedMessage: '',
      isForbidden: false,
      isShowingGetCompany: true,
      isShowingCreateCompany: false,
      isShowingUpdateCompany: false,
      isShowingDeleteCompany: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.setFailedMessage = this.setFailedMessage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
    this.hideNavBar = props.hideNavBar;

    this.page = 0;
    this.pageSize = 10;

    this.props.handleClickMenu('escalationprocess', 'master');

    this.user = getObject('user');
    setObject('lastRoute', 'escalationprocess');
  }

  handleFilterInput() {
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
  }

  handleCloseFilterInput() {
    this.filters = [
      {id: 'title', value: ''},
      {id: 'description', value: ''}
    ];
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleSearch(idx, value) {
    this.filters[idx].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleBack() {
    this.setState({
        isBackToHome : true
    });
  }

  handleSection(redirectId) {
    this.setState({
      redirectId
    });
  }

  refreshTable(state) {
    const user = getObject('user');
    const size = state.pageSize;
    const page = parseInt(state.page) + 1;

    this.page = parseInt(state.page);
    this.pageSize = state.pageSize;

    const filtered = this.filters;

    let filterString = '';
    const filterXs = 
          R.pipe(
            R.filter(x => x.value !== ''),
            R.map(x => `${x.id}=${x.value}`)
          )(filtered);
    
    filterString = filterXs.join('&');

    const sortedXs = R.map(
      x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
      state.sorted || []);
    let sortedString = '&orderBy=id&orderDirection=desc';

    if(sortedXs.length > 0) {
        sortedString = sortedXs.join('&');
    }

    if(user) {
      axios({
        url: `${config.api.escalationprocess}?size=${size}&page=${page}&${filterString}&${sortedString}`,
        method: 'get',
        headers: { 'x-access-token': user.token }
      })
      .then(response => {
          const data = response.data.data;
          this.setState({
            data: data.results,
            pages: data.totalPage,
            loading: false
          });
        })
        .catch(err => {
          const statusCode = err.response.status;
          if(statusCode == 403) {
            // this.setState({
            //   isForbidden: true
            // });
          }
        });
    }
  }

  fetchData(state, instance) {
    this.clearMessage();
    this.setState({ loading: true });
    this.refreshTable(state);
  }

  handleAdd() {
    this.hideNavBar(true);
    this.setState({
      showAddModal: true
    });
  }

  handleEdit(editId) {
    this.hideNavBar(true);
    this.setState({
      showEditModal: true,
      editId
    });
  }

  handleCloseAddModal() {
    this.hideNavBar(false);
    this.setState({
      showAddModal: false
    });
  }

  handleCloseEditModal(id) {
    this.hideNavBar(false);
    this.setState({
      showEditModal: false
    });
  }

  handleDelete(deleteId) {
    this.setState({
      showDeleteModal: true,
      deleteId
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      showDeleteModal: false
    });
  }

  setSuccessMessage(successMessage) {
    this.setState({
      successMessage,
      failedMessage: ''
    });
  }

  setFailedMessage(failedMessage) {
    this.setState({
      failedMessage,
      successMessage: ''
    });
  }

  clearMessage() {
    this.setState({
      successMessage: '',
      failedMessage: ''
    });
  }


  async componentDidMount() {
    if(this.user) {
      const apiControlPostEscalationProcessResult = await axios({
        url: `${config.api.apicontrols}/checking?route=/escalationprocesses&method=POST&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      if(!apiControlPostEscalationProcessResult.data.data.isAllowed) {
          this.props.history.push('/');
      }
    }
  }

  componentWillUnmount() {
    if(this.timer){
        clearTimeout(this.timer);
    }
  }

  render() {

    if(!this.user 
      || this.state.isForbidden) {
      this.props.history.push('/login');
    }

    if(!this.state.isShowingGetCompany) {
      this.props.history.push('/');
    }

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  successMessage: ''
              });
          }, config.notifDuration);
    }


    const { data, pages, loading } = this.state;
    var modalStyles = {overlay: {zIndex: 10}};

    const columns = [
      {
        Header: "Title",
        accessor: "title",
        sortable: true
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true
      },
      {
        Header: "Created date",
        accessor: "createdDate",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      },
      {
        Header: "Updated date",
        accessor: "updatedDate",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      }
    ];

    columns.unshift({
      Header: "Action",
      accessor: 'id',
      width: 134,
      filterable: false,
      Cell: cellInfo => {
        return (
          <div className="row">
            <div className="col-md-auto">
                <button type="button" 
                        className="btn btn-sm" 
                        onClick={() => this.handleEdit(cellInfo.value)}>Edit</button>
            </div>
            <div className="col-md-auto">
                <button type="button" 
                className="btn btn-sm" 
                onClick={() => this.handleDelete(cellInfo.value)}>Delete</button>
            </div>
          </div>);
      }
    });

    const component = (
      <div>
        <br/>
        {this.state.successMessage
          ? <div className="alert alert-success" role="alert">
            {this.state.successMessage}
          </div>
          : null}
        {this.state.failedMessage
          ? <div className="alert alert-danger" role="alert">
            {this.state.failedMessage}
          </div>
          : null}
        <hr/>
        <h1>Escalation Process</h1>
        <hr/>
        <div className="grid2-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
          <div className="grid-item">
            <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Escalation Process</span></button>
          </div>
          {!this.state.showFiltering
          ?
          <div className="grid-item" style={{ justifySelf: "end" }}>
            <button type="button" className="btn" onClick={this.handleFilterInput} title="Search"><span class="fe fe-search"></span></button>
          </div>
          :
          null}
        </div>
        {this.state.showFiltering 
        ?
        <div>
          <br/>
          <br/>
          <div className="card">
            <div className="pull-right" style={{ marginRight: '10px'}}>
                <button type="button"  
                        className="pull-right clickable close-icon close" 
                        onClick={this.handleCloseFilterInput}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-auto">
                  <label><b>Title</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(0, e.target.value)}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Description</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(1, e.target.value)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        null}
        <ReactModal isOpen={this.state.showAddModal}
          contentLabel="Minimal Modal Example" style={modalStyles}>
          <EscalationProcessCreate
            closeModal={this.handleCloseAddModal}
            refreshTable={this.fetchData}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }}
            setSuccessMessage={this.setSuccessMessage}/>
        </ReactModal>
        <ReactModal isOpen={this.state.showEditModal}
          contentLabel="Minimal Modal Example">
          <EscalationProcessEdit
            closeModal={this.handleCloseEditModal}
            id={this.state.editId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }} />
        </ReactModal>
        <ReactModal isOpen={this.state.showDeleteModal}
          contentLabel="Minimal Modal Example"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }
          }}>
          <DeleteModal
            closeModal={this.handleCloseDeleteModal}
            id={this.state.deleteId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage}
            deleteUrl={config.api.escalationprocess} />
        </ReactModal>
        <ReactTable
          columns={columns}
          manual 
          data={data}
          pages={pages} 
          loading={loading} 
          onFetchData={this.fetchData} 
          filterable={false}
          defaultPageSize={10}
          className="-striped -highlight"
          sortable={false}
        />
        <br />
      </div>
    );

    return component;


  }
}

export default withRouter(EscalationProcess);