import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter} from "react-router-dom";
import axios from 'axios';
import { setObject, getObject, setSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class InspectionTemplateCreate extends Component {
    constructor(props) {
        super(props);
        this.inspectionTemplateCards = [{
            title: '',
            description: '',
            isSuccess: false,
            failedMessage: '',
            isDeleted: false
        }];

        this.handleAddNewCard = this.handleAddNewCard.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
        this.totalCard = 1;

        this.state = {
            inspectionTemplateCards: this.inspectionTemplateCards
        };
    }

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }

    handleDeleteCard(idx) {
        if(this.totalCard > 1) {
            this.totalCard--;
            const oldValue = this.inspectionTemplateCards[idx];
            this.inspectionTemplateCards[idx] = {
                ...oldValue,
                isDeleted: true
            };
    
            this.setState({
                inspectionTemplateCards: this.inspectionTemplateCards
            });
        }
    }

    handleTitle(idx, title) {
        const oldValue = this.inspectionTemplateCards[idx];
        this.inspectionTemplateCards[idx] = {
            ...oldValue,
            title
        };
    }

    handleDescription(idx, description) {
        const oldValue = this.inspectionTemplateCards[idx];
        this.inspectionTemplateCards[idx] = {
            ...oldValue,
            description
        };
    }

    handleAddNewCard() {
        this.totalCard++;
        const newCard = this.inspectionTemplateCards;
        newCard.push({ title: '', description: '' });
        this.setState({
            inspectionTemplateCards: newCard
        });
    }

    handleSave() {
        const user = getObject('user');
        const inspectionTemplateAPI = config.api.inspectiontemplate;

        const forEachIndex = R.addIndex(R.forEach);

        forEachIndex((x, index) => {
            const config = {
                headers: { 'x-access-token': user.token }
            };

            if (!this.inspectionTemplateCards[index].isSuccess) {
                axios.post(inspectionTemplateAPI,
                    { title: x.title, description: x.description },
                    config)
                    .then(res => {
                        const idx = index;
                        const newTemplateCards = this.inspectionTemplateCards;
                        const newTemplateCard = newTemplateCards[idx];
                        const template = res.data.data;
                        newTemplateCards[idx] = {
                            ...newTemplateCard,
                            isSuccess: true,
                            failedMessage: '',
                            templateId: template.id
                        };

                        this.props.hideNavBar(false);
                        setSuccessMessage('inspection template has been created successfully');
                        this.props.history.push(`/inspectiontemplate/${template.id}/sectiontemplate`);
                    }).catch(e => {
                        const idx = index;
                        const data = e.response.data;
                        if (data.code === 400) {
                            const newTemplateCards = this.inspectionTemplateCards;
                            const newTemplateCard = newTemplateCards[idx];
                            newTemplateCards[idx] = { ...newTemplateCard, failedMessage: data.message };

                            this.setState({
                                inspectionTemplateCards: newTemplateCards
                            });
                        }
                        console.log(e.response.data);
                    });
            }
        })(this.inspectionTemplateCards);
    }

    render() {
        const Card = ({ idx, title, description, isSuccess, failedMessage, templateId }) => (
            <div>
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Title</label>
                        <input type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            defaultValue={title}
                            onChange={(event) => this.handleTitle(idx, event.target.value)}
                            disabled={isSuccess} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Description</label>
                        <textarea className="form-control"
                            onChange={(event => this.handleDescription(idx, event.target.value))}
                            defaultValue={description}
                            disabled={isSuccess}>
                        </textarea>
                    </div>
                </form>
                {isSuccess 
                    ? <div className="alert alert-success" role="alert">
                        inspection template has been created successfully
                        </div> 
                    : null}
                {failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {failedMessage}
                        </div> 
                    : null}
            </div>
        );

        var mapIndexed = R.addIndex(R.map);
        const filteredCard = 
                mapIndexed((item, index) => 
                                item.isDeleted 
                                ? null 
                                : <Card title={item.title}
                                        description={item.description}
                                        idx={index}
                                        key={index}
                                        isSuccess={item.isSuccess}
                                        failedMessage={item.failedMessage}
                                        templateId={item.templateId} />
                                , this.state.inspectionTemplateCards);
        return (
            <div>
                {/* {this.state.redirectTo 
                ? 
                <Redirect
                    to={{
                        pathname: `/inspectiontemplate/${this.state.redirectTo}/sectiontemplate`,
                        state: { 
                            successMessage: "inspection template has been created successfully" 
                        }
                    }}
                />
                :
                null} */}
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                {filteredCard}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            
            </div>
        );
    }
}


export default withRouter(InspectionTemplateCreate);