import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Select from 'react-select';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda';
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import DateTime from 'react-datetime';
import moment from 'moment';

class InspectionSubmissionConfirmation extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleIsIncludingPositiveFinding = this.handleIsIncludingPositiveFinding.bind(this);
        this.handleIsIncludingBackDate = this.handleIsIncludingBackDate.bind(this);
        this.user = getObject('user');
        const nowMoment = moment();
        this.nowTime = nowMoment; 
        this.state = {
            contractorOptions: [],
            assignedContractorIds: null,
            assignedContractors: [],
            isDisabledYesButton: true,
            isSubmitInspectionCurrentUser: false,
            isIncludingPositiveFinding: true,
            isIncludingBackDate: false,
            backDate: this.nowTime.format("YYYY-MM-DD HH:mm:ss")
        };
    }


    handleBackDate = (value) => {
        this.setState({
            backDate: value.format("YYYY-MM-DD HH:mm:ss")
        });
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const apiControlSubmitCurrentUserResult = await axios({
                url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId/done`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            if(apiControlSubmitCurrentUserResult.data.data.isAllowed) {
                this.setState({
                    isSubmitInspectionCurrentUser: true,
                    isDisabledYesButton: false
                });
            } else {
                const contractorResult = await axios({
                    url: `${config.api.project}/${this.props.projectId}/contractors?type=PROJECT_MONITOR`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const contractors = contractorResult.data.data.results;
                const contractorOptions = 
                    R.map(x => ({ value: x.userId, 
                                  label: x.email,
                                  ...x}), 
                            contractors);
        
                this.setState({
                    contractorOptions
                });
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleIsIncludingPositiveFinding(event) {
        const target = event.target;
        const isIncludingPositiveFinding = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            isIncludingPositiveFinding
        });
    }

    handleIsIncludingBackDate(event) {
        const target = event.target;
        const isIncludingBackDate = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            isIncludingBackDate
        });
    }

    handleChangeContractor = (contractor) => {
        console.log('contractor = ', contractor);
        const isDisabledYesButton = contractor == null;    
        const assignedContractorIds = [contractor.value];

        // console.log('assigned contractors = ', contractor);

        this.setState({
            assignedContractorIds,
            assignedContractors: [contractor],
            assignedContractorId: [contractor],
            isDisabledYesButton
        });

    }

    async handleSubmit() {
        try {
            this.setState({isActiveLoadingOverlay: true});
            let axiosCall = null;

            const data = {};

            if(this.state.isIncludingBackDate && this.state.backDate) {
                data.backDate = this.state.backDate;
            }

            if(this.state.isSubmitInspectionCurrentUser) {
                data.isIncludingPositiveFinding = this.state.isIncludingPositiveFinding;
                axiosCall = axios({
                    url: `${config.api.inspection}/${this.props.inspectionId}/done`,
                    method: 'put',
                    headers: { 'x-access-token': this.user.token },
                    data
                });
            } else {
                data.assignedContractorIds = this.state.assignedContractorIds;
                data.isIncludingPositiveFinding = this.state.isIncludingPositiveFinding;
                axiosCall = axios({
                    url: `${config.api.inspection}/${this.props.inspectionId}/contractors/done`,
                    method: 'put',
                    headers: { 'x-access-token': this.user.token },
                    data
                });
            }
            const response = await axiosCall;
            this.props.setSuccessMessage("inspection has been submitted successfully");
            this.props.loadInspection();
            this.props.closeModal();                
        } catch(e) {
            const data = e.response.data;
            this.props.setFailedMessage(data.message);
            
            this.props.closeModal();
        }  finally {
            this.setState({isActiveLoadingOverlay: false});
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.props.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                {this.state.isSubmitInspectionCurrentUser
                ?
                <div className="align-middle">
                    <div className="form-group">
                        <b>Are you sure wanto to submit the inspection ?</b>
                    </div>
                </div>
                :
                null}
                {!this.state.isSubmitInspectionCurrentUser
                ?
                <div className="align-middle">
                    <div className="form-group">
                        <div className="col-md-12">
                            <b>Please select the contractor</b>
                        </div>
                    </div>
                </div>
                :
                null}
                {!this.state.isSubmitInspectionCurrentUser
                ?
                <div className="form-group">
                    <div className="col-md-12">
                        <Select
                            onChange={this.handleChangeContractor}
                            options={this.state.contractorOptions}
                            placeholder="Please select contractor"/>
                        <br/>
                        {R.map(inspection => {
                            return (<div>
                                        <div className="form-group">
                                            <label><b>Email</b></label>
                                            <p>{inspection.email}</p>
                                        </div>
                                        <div className="form-group">
                                            <label><b>Full Name</b></label>
                                            <p>{inspection.name}</p>
                                        </div>
                                        <div className="form-group">
                                            <label><b>Type</b></label>
                                            <p>{inspection.newType}</p>
                                        </div>
                                        {inspection.contractorName
                                        ?
                                        <div className="form-group">
                                            <label><b>Contractor Company</b></label>
                                            <p>{inspection.contractorName}</p>
                                        </div>
                                        :
                                        null}
                                    </div>);
                        }, this.state.assignedContractors || [])}
                    </div>
                </div>
                :
                null}
                <div className="form-group">
                    <div className="col-md-12">
                        <div className="form-check">
                            <input className="form-check-input" 
                                type="checkbox" 
                                defaultChecked={this.state.isIncludingPositiveFinding}
                                id="defaultCheck1"
                                onChange={this.handleIsIncludingPositiveFinding}/>
                            <label className="form-check-label" for="defaultCheck1">
                                <b>Include positive finding in report</b>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-md-12">
                        <div className="form-check">
                            <input className="form-check-input" 
                                type="checkbox" 
                                defaultChecked={this.state.isIncludingBackDate}
                                id="isIncludingBackDate"
                                onChange={this.handleIsIncludingBackDate}/>
                            <label className="form-check-label" for="isIncludingBackDate">
                                <b>Include back date</b>
                            </label>
                        </div>
                    </div>
                </div>
                {this.state.isIncludingBackDate 
                ?
                <div className="form-group">
                    <div className="col-md-12">
                        <label><b>Back date</b></label>
                        <DateTime onChange={this.handleBackDate} defaultValue={this.nowTime}/>
                    </div>
                </div>
                :
                null}
                <div className="form-group" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            disabled={this.state.isDisabledYesButton}
                            onClick={this.handleSubmit}>Submit</button>
                    </div>
                </div>
                {/* <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-6">
                    {!this.state.isSubmitInspectionCurrentUser
                    ?
                    <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            disabled={this.state.isDisabledYesButton}
                        onClick={this.handleSubmit}>Yes</button>
                    :
                    <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                        onClick={this.handleSubmit}>Yes</button>
                    }
                    </div>
                    <div className="col-md-6">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.props.closeModal}>No</button>
                    </div>
                </div> */}
                </BlockUi>
            </div>
        );
    }
}


export default InspectionSubmissionConfirmation;