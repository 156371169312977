import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class ProjectEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: '',
            location: '',
            failedMessage: '',
            companyId: '',
            companyOptions: []
        };

        this.handleProjectName = this.handleProjectName.bind(this);
        this.handleLocation = this.handleLocation.bind(this);
        this.handleCompanyId = this.handleCompanyId.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.handleContractorNameId = this.handleContractorNameId.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
    }

    async componentDidMount() {
        try {

            this.setState({
                isActiveLoadingOverlay: true
            });
            const projectIdPromise = axios({
                url: `${config.api.project}/${this.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const companiesPromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const departmentsPromise = axios({
                url: `${config.api.department}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const locationsPromise = axios({
                url: `${config.api.location}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const contractorNamePromise = axios({
                    url: `${config.api.contractorname}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
            });

            const [projectIdResult, companiesResult, departmentsResult, locationsResult, contractorNameResult] = 
                await Promise.all([
                    projectIdPromise,
                    companiesPromise,
                    departmentsPromise,
                    locationsPromise,
                    contractorNamePromise
                ]);

            const companies = companiesResult.data.data.results;
            const projectId = projectIdResult.data.data;
            const departments = departmentsResult.data.data.results;
            const locations = locationsResult.data.data.results;
            const contractorNames = contractorNameResult.data.data.results;

            this.setState({
                projectName: projectId.projectName,
                location: projectId.location,
                companyId: projectId.companyId,
                companies,
                companyOptions: companies,
                departmentOptions: departments,
                departmentId: projectId.departmentId ? projectId.departmentId : departments[0].id,
                locationOptions: locations,
                locationId: projectId.locationId ? projectId.locationId : locations[0].id,
                contractorNameOptions: contractorNames,
                contractorNameId: projectId.contractorNameId ? projectId.contractorNameId : contractorNames[0].id
            });
        } catch(e) {
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleDeleteCard(idx) {
        this.closeModal();
    }

    handleContractorNameId(contractorNameId) {
        this.setState({
            contractorNameId
        });        
    }

    handleLocation(event) {
        const location = event.target.value;
        this.setState({
            location
        });
    }

    handleCompanyId(event) {
        const companyId = event.target.value
        this.setState({
            companyId
        });
    }

    handleProjectName(event) {
        const projectName = event.target.value;
        this.setState({
            projectName
        });
    }

    handleDepartmentId = (departmentId) => {
        this.setState({
            departmentId
        });
    }

    handleLocationId = (locationId) => {
        this.setState({
            locationId
        });
    }

    async handleSave() {        
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const response = await axios({
                url: `${config.api.project}/${this.id}`,
                method: 'put',
                headers: { 'x-access-token': this.user.token },
                data: {
                    projectName: this.state.projectName,
                    companyId: this.state.companyId,
                    departmentId: this.state.departmentId,
                    locationId: this.state.locationId,
                    contractorNameId: this.state.contractorNameId
                } 
            });
            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('project has been updated successfully.');
            this.closeModal();
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="clickable close-icon close" 
                            onClick={() =>this.handleDeleteCard(1)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label>Project name</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.projectName}
                            onChange={this.handleProjectName}/>
                    </div>
                    <div className="form-group">
                        <label>Department</label>
                        <select onChange={event => this.handleDepartmentId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                value={this.state.departmentId}>
                                {R.map(
                                    x => <option value={x.id}>{x.department}</option>,
                                    this.state.departmentOptions || [])}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Location</label>
                        <select onChange={event => this.handleLocationId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                value={this.state.locationId}>
                                {R.map(
                                    x => <option value={x.id}>{x.location}</option>,
                                    this.state.locationOptions || [])}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Contractor name</label>
                        <select onChange={event => this.handleContractorNameId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                value={this.state.contractorNameId}>
                                {R.map(
                                    x => <option value={x.id}>{x.contractorName}</option>,
                                    this.state.contractorNameOptions || [])}
                        </select>
                    </div>
                </form>

                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                  </div> 
                : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default ProjectEdit;