import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import RestoreModal from '../components/RestoreModal';
import UserCreate from '../components/UserCreate';
import UserCreateLocAndDepAdmin from '../components/UserCreateLocAndDepAdmin';
import UserEdit from '../components/UserEdit';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import {convertToStd} from '../utils/date';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';

class UserAdminDepartment extends Component {

    constructor(props) {
        super(props);

        this.filters = [
            {id: 'name', value: ''},
            {id: 'department', value: ''},
            {id: 'email', value: ''},
            {id: 'location', value: ''},
            {id: 'phoneNumber', value: ''},
            {id: 'isRemoved', value: false}
        ];

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showDeleteModal: false,
            showAddModal: false,
            isBackToHome: false,
            editId: 0,
            deleteId: 0,
            redirectId: 0,
            successMessage: '',
            failedMessage: '',
            isShowingGetUser: true,
            isShowingCreateUser: false,
            isShowingUpdateUser: false,
            isShowingDeleteUser: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.handleSection = this.handleSection.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.handleCloseRestoreModal = this.handleCloseRestoreModal.bind(this);
        this.handleRestoreUser = this.handleRestoreUser.bind(this);

        this.hideNavBar = props.hideNavBar;

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('user', 'master');

        const userObj = getObject('user');
        this.userObj = userObj;

        if(userObj) {
            this.user = userObj.user;
            this.userType = this.user.type;
        } else {
            this.props.history.push('/login');
        }

        setObject('lastRoute', 'user');
    }

    handleFilterInput() {
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
    }

    handleCloseRestoreModal() {
        this.setState({
          showRestoreModal: false
        });
    }

    async handleRestoreUser(restoreId) {
        this.setState({
          showRestoreModal: true,
          restoreId
        });
    }
    
    handleCloseFilterInput() {
        this.filters = [
            {id: 'name', value: ''},
            {id: 'department', value: ''},
            {id: 'email', value: ''},
            {id: 'location', value: ''},
            {id: 'phoneNumber', value: ''},
            {id: 'isRemoved', value: false}
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
      }
    
      handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
      }

    handleBack() {
        this.setState({
            isBackToHome : true
        });
    }

    handleSection(redirectId) {
        this.setState({
            redirectId
        });
    }

    refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const filtered = this.filters;

        console.log(filtered);
    
        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = '&orderBy=id&orderDirection=desc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        if(user) {
            axios({
                url: `${config.api.user}?size=${size}&page=${page}&type=ADMIN_DEPARTMENT&${filterString}&${sortedString}`,
                method: 'get',
                headers: { 'x-access-token': user.token }
            })
            .then(response => {
                const data = response.data.data;
                console.log(data);
                this.setState({
                    data: data.results,
                    pages: data.totalPage,
                    loading: false
                });
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState({
            successMessage,
            failedMessage: ''
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    componentWillUnmount() {
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    async componentDidMount() {
        if(this.user) {
            const apiControlGetUserResult = await axios({
                url: `${config.api.apicontrols}/checking?route=/users&method=GET&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            console.log('Masuk ke sini = ', apiControlGetUserResult.data.data);
            if(!apiControlGetUserResult.data.data.isAllowed) {
                console.log('masuk ke sini bro');
                this.props.history.push('/');
            }
    
            const apiControlCreateUserPromise = axios({ 
                url: `${config.api.apicontrols}/checking?route=/users&method=POST&parameter=/`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            const apiControlUpdateUserPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/users&method=PUT&parameter=/:id`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            const apiControlDeleteUserPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/users&method=DELETE&parameter=/:id`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            const apiControlContractorProjectPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/users&method=GET&parameter=/:id/projects`,
                method: 'get',
                headers: { 'x-access-token': this.userObj.token }
            });
    
            const [apiControlCreateUserResult,
                   apiControlUpdateUserResult,
                   apiControlDeleteUserResult,
                   apiControlContractorProjectResult] = 
                   await Promise.all([
                       apiControlCreateUserPromise, 
                       apiControlUpdateUserPromise,
                       apiControlDeleteUserPromise,
                       apiControlContractorProjectPromise]);
    
            this.filterTypes = [
                { value: "ALL", label: "All" }
            ];

            if(this.userType === 'SUPER_ADMIN') {
                this.filterTypes = this.filterTypes.concat([
                    { value: "INSPECTOR", label: "Inspector" },
                    { value: "CONTRACTOR", label: "Sub Contractor" },
                    { value: "ADMIN", label: "Admin" },
                    { value: "ADMIN_LOCATION", label: "Admin Location" },
                    { value: "ADMIN_DEPARTMENT", label: "Admin Department" }
                ]);
            } else if(this.userType === 'ADMIN') {
                this.filterTypes = this.filterTypes.concat([
                    { value: "INSPECTOR", label: "Inspector" },
                    { value: "CONTRACTOR", label: "Sub Contractor" },
                    { value: "ADMIN_LOCATION", label: "Admin Location" },
                    { value: "ADMIN_DEPARTMENT", label: "Admin Department" }
                ]);
            } else if(this.userType === 'ADMIN_LOCATION') {
                this.filterTypes = this.filterTypes.concat([
                    { value: "INSPECTOR", label: "Inspector" },
                    { value: "CONTRACTOR", label: "Sub Contractor" }
                ]);
            } else if(this.userType === 'ADMIN_DEPARTMENT') {
                this.filterTypes = this.filterTypes.concat([
                    { value: "INSPECTOR", label: "Inspector" }
                ]);
            }

            this.setState({
                isShowingCreateUser: apiControlCreateUserResult.data.data.isAllowed,
                isShowingUpdateUser: apiControlUpdateUserResult.data.data.isAllowed,
                isShowingDeleteUser: apiControlDeleteUserResult.data.data.isAllowed,
                isShowingContractorProject: apiControlContractorProjectResult.data.data.isAllowed
            });
        }
    }

    render() {

        if(this.state.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        successMessage: ''
                    });
                }, config.notifDuration);
        }

        const columns = [
            {
                Header: "Name",
                accessor: "name",
                sortable: true
            },
            {
                Header: "Department",
                accessor: "department"
            },
            {
                Header: "Location",
                accessor: "location"
            },
            {
                Header: "Email",
                accessor: "email"
            },
            {
                Header: "Phone number",
                accessor: "phoneNumber"
            },
            {
                Header: "Company",
                accessor: "companyName"
            },
            {
                Header: "Created date",
                accessor: "createdDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                        return '';
                    }
                    return date;
                }
            },
            {
                Header: "Updated date",
                accessor: "updatedDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                        return '';
                    }
                    return date;
                }
            }
        ];

        if(this.state.isShowingUpdateUser
           || this.state.isShowingDeleteUser) {
            columns.unshift({
                Header: "Action",
                width: 134,
                filterable: false,
                id: "user",
                accessor: x => x,
                Cell: cellInfo => {

                    console.log('cell info = ', cellInfo);
                    const user = cellInfo.original;
                    if(!user.isExisted) {
                      return (
                        <div className="row">
                            <div className="col-md-auto">
                            <button type="button"
                                    className="btn btn-sm"
                                    style={{width: 'auto'}}
                                    onClick={() => this.handleRestoreUser(user.id)}>Restore</button>
                            </div>
                        </div>);
                    }

                    return (
                        <div className="row">
                            <div className="col-md-auto">
                                {this.state.isShowingUpdateUser
                                ?
                                <button type="button"
                                    className="btn btn-sm"
                                    onClick={() => this.handleEdit(cellInfo.original.id)}>Edit</button>
                                :
                                null}
                            </div>
                            <div className="col-md-auto">
                                {this.state.isShowingDeleteUser
                                ?
                                <button type="button"
                                    className="btn btn-sm"
                                    onClick={() => this.handleDelete(cellInfo.original.id)}>Delete</button>
                                :
                                null}
                            </div>
                        </div>);
                }
            });
        }

        const { data, pages, loading } = this.state;
        const component = (
            <div>
                <br/>
                {this.state.successMessage
                    ? <div className="alert alert-success" role="alert">
                        {this.state.successMessage}
                    </div>
                    : null}
                {this.state.failedMessage
                    ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                    </div>
                    : null}
                <h1>Admin Department</h1>
                <hr/>
                <div className="grid2-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="grid-item">
                    </div>
                    {!this.state.showFiltering
                    ?
                    <div className="grid-item" style={{ justifySelf: "end" }}>
                        <button type="button" className="btn" onClick={this.handleFilterInput} title="Search"><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}
                </div>
                {this.state.showFiltering 
                ?
                <div>
                <br/>
                <br/>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Name</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)} />
                        </div>
                        <div className="col-md-auto">
                            <label><b>Department</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(1, e.target.value)} />
                        </div>
                        <div className="col-md-auto">
                            <label><b>Email</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(2, e.target.value)} />
                        </div>
                        <div className="col-md-auto">
                            <label><b>Location</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(3, e.target.value)} />
                        </div>
                        <div className="col-md-auto">
                            <label><b>Phone number</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(4, e.target.value)} />
                        </div>
                        {this.user
                        && this.user.type === 'SUPER_ADMIN'
                        ?
                        <div className="col-md-auto">
                            <label><b>Removed</b></label>
                            <select className="form-control" 
                                    onChange={e => this.handleSearch(5, e.target.value)}>
                                <option value={false}>No</option>
                                <option value={true}>Yes</option>
                            </select>
                        </div>
                        :
                        null}
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showRestoreModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)'
                    }
                    }}>
                    <RestoreModal
                    closeModal={this.handleCloseRestoreModal}
                    id={this.state.restoreId}
                    refreshTable={this.fetchData}
                    setSuccessMessage={this.setSuccessMessage}
                    setFailedMessage={this.setFailedMessage}
                    deleteUrl={config.api.user} />
                </ReactModal>
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    {
                        this.userType === 'ADMIN_LOCATION'
                        || this.userType === 'ADMIN_DEPARTMENT'
                        ?
                        <UserCreateLocAndDepAdmin
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        setSuccessMessage={this.setSuccessMessage} />
                        :
                        <UserCreate
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        setSuccessMessage={this.setSuccessMessage} />
                    }

                </ReactModal>
                <ReactModal isOpen={this.state.showEditModal}
                    contentLabel="Minimal Modal Example">
                    <UserEdit
                        closeModal={this.handleCloseEditModal}
                        id={this.state.editId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }} />
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}>
                    <DeleteModal
                        closeModal={this.handleCloseDeleteModal}
                        id={this.state.deleteId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        deleteUrl={config.api.user} />
                </ReactModal>
                <ReactTable
                    columns={columns}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
            </div>
        );

        return component;

    }
}

export default withRouter(UserAdminDepartment);