import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class CompanyCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: ''
        };

        this.handleCompany = this.handleCompany.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handlePostcode = this.handlePostcode.bind(this);
        this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
    }

    handleCompany(event) {
        const company = event.target.value;
        this.setState({
            company
        });
    }

    handleAddress(event) {
        const address = event.target.value;
        this.setState({
            address
        });
    }

    handleState(event) {
        const state = event.target.value;
        this.setState({
            state
        });
    }

    handlePostcode(event) {
        const postcode = event.target.value;
        this.setState({
            postcode
        });
    }

    handlePhoneNumber(event) {
        const phoneNumber = event.target.value;
        this.setState({
            phoneNumber
        });
    }

    handleCountry(event) {
        const country = event.target.value;
        this.setState({
            country
        });
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const companyAPI = config.api.company;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const res = await axios.post(companyAPI,
                      { company: this.state.company,
                        address: this.state.address,
                        state: this.state.state,
                        postcode: this.state.postcode,
                        phoneNumber: this.state.phoneNumber,
                        country: this.state.country },
                       header)

            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('company has been created successfully');
            this.closeModal();
        } catch(e) {
            const data = e.response.data;
            if (data.code === 400) {
            }
            console.log(e.response.data);            
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label>Company</label>
                        <input type="text"
                            className="form-control"
                            onChange={this.handleCompany}/>
                    </div>
                    <div className="form-group">
                        <label>Address</label>
                        <input type="text"
                            className="form-control"
                            onChange={this.handleAddress}/>
                    </div>
                    <div className="form-group">
                        <label>State</label>
                        <input type="text"
                            className="form-control"
                            onChange={this.handleState}/>
                    </div>
                    <div className="form-group">
                        <label>Postcode</label>
                        <input type="text"
                            className="form-control"
                            onChange={this.handlePostcode}/>
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input type="text"
                            className="form-control"
                            onChange={this.handlePhoneNumber}/>
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <input type="text"
                            className="form-control"
                            onChange={this.handleCountry}/>
                    </div>
                </form>
                {this.state.failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {this.state.failedMessage}
                        </div> 
                    : null}

                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div> 
                </BlockUi>
            </div>
        );
    }
}


export default CompanyCreate;