import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class D12IncidentEdit  extends Component {
    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
        this.totalCard = 1;
        this.selectedProjectId = 0;
        this.selectedTemplateId = 0;
        this.user = getObject('user');
        this.state = {
            companyValue: '',
            projectName: '',
            inspectionName: '',
            location: '',
            projectSuggestions: [],
            templateSuggestions: [],
            projects: [],
            templateValue: '',
            templateDescription: '',
            failedMessage: '',
            isActiveLoadingOverlay: true
        };

        this.getProjectSuggestions = async value => {
            const result = await axios({
                url: `${config.api.project}?projectName=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedProjectId = 0;
            this.setState({
                location: '',
                companyName: ''
            });
            return data.results;
        };
 
        this.getProjectSuggestionValue = suggestion => {
            this.selectedProjectId = suggestion.id;
            this.setState({
                location: suggestion.location,
                companyName: suggestion.company
            });
            return suggestion.projectName;
        };

        this.getTemplateSuggestionValue = suggestion => {
            this.selectedTemplateId = suggestion.id;
            this.setState({
                templateDescription: suggestion.description
            });
            return suggestion.title;
        };

        this.renderProjectSuggestion = suggestion => (
            <span>{suggestion.projectName}</span>);

        this.renderTemplateSuggestion = suggestion => (
                <span>{suggestion.title}</span>);

        this.handleInspectionName = this.handleInspectionName.bind(this);
        this.user = getObject('user');
    }
    
    async componentDidMount() {
        try {
            const d12IncidentPromise = axios({
                url: `${config.api.d12incident}/${this.props.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const companiesPromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const severitiesPromise = axios({
                url: `${config.api.severity}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const stop6Promise = axios({
                url: `${config.api.stop6}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const [d12IncidentResult, 
                   companiesResult, 
                   severitiesResult,
                   stop6Result] = 
                await Promise.all([d12IncidentPromise, 
                                   companiesPromise,
                                   severitiesPromise,
                                   stop6Promise]);

            const d12Incident = d12IncidentResult.data.data;
            const companies = companiesResult.data.data.results;
            const severities = severitiesResult.data.data.results;
            const stop6 = stop6Result.data.data.results;


            this.setState({
                companies: R.map(x => ({value: x.id, label: x.company}), companies),
                companyValue : { value: d12Incident.companyId, label: d12Incident.company},
                severities: R.map(x => ({value: x.id, label: x.severity}), severities),
                severityValue: {value: d12Incident.severityId, label: d12Incident.severity},
                stop6: R.map(x => ({value: x.id, label: x.stop6}), stop6),
                stop6Value: { value: d12Incident.stop6Id, label: d12Incident.stop6 },
                dateTime: d12Incident.dateTime,
                name: d12Incident.name,
                email: d12Incident.email,
                injury: d12Incident.injury,
                activity: d12Incident.activity,
                description: d12Incident.description
            });
        } catch(e) {
            console.log(e);
            // const data = e.response.data;
            // this.setFailedMessage(data.message);
            // this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleInspectionName(inspectionName) {
        this.setState({
            inspectionName
        });
    }

    onProjectSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getProjectSuggestions(value);
        this.setState({
            projectSuggestions: values
        });
    };

    onTemplateSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getTemplateSuggestions(value);
        this.setState({
            templateSuggestions: values
        });
    };

    onProjectSuggestionsClearRequested = () => {
        this.setState({
          projectSuggestions: []
        });
    };

    onTemplateSuggestionsClearRequested = () => {
        this.setState({
          templateSuggestions: []
        });
    };

    onProjectChange = (event, { newValue }) => {
        this.setState({
          companyValue: newValue
        });
    };

    onTemplateChange = (event, { newValue }) => {
        this.setState({
          templateValue: newValue
        });
    };

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }

    handleChangeCompany = async (companyValue) => {
        try {
            this.setState({
                companyValue
            });
        } catch(e) {

        }
    }

    handleChangeSeverity = async (severityValue) => {
        try {
            this.setState({
                severityValue
            });
        } catch(e) {

        }
    }

    handleChangeStop6 = async (stop6Value) => {
        try {
            this.setState({
                stop6Value
            });
        } catch(e) {

        }
    }

    handleChangeInjury = async (event) => {
        const injury = event.target.value;
        this.setState({
            injury,
        });
    }

    handleChangeActivity = async (event) => {
        const activity = event.target.value;
        this.setState({
            activity,
        });
    }

    handleChangeDescription = async (event) => {
        const description = event.target.value;
        this.setState({
            description,
        });
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const d12IncidentApi = config.api.d12incident;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const res = await axios.put(`${d12IncidentApi}/${this.props.id}`,
                       {
                           severityId: this.state.severityValue.value,
                           stop6Id: this.state.stop6Value.value,
                           dateTime: this.state.dateTime,
                           injury: this.state.injury,
                           activity: this.state.activity,
                           description: this.state.description
                       },
                       header);

            this.props.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('d12incident has been updated successfully');
            this.props.closeModal();

            if(this.props.onSuccess) {
                this.props.onSuccess();
            }
        } catch(e) {
            const data = e.response.data;
            if (data.code === 400) {
                this.setState({
                    failedMessage: data.message
                });
            }                 
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleDateTime = (value) => {
        this.setState({
            dateTime: value.format("YYYY-MM-DD HH:mm:ss")
        });
    }

    render() {
        const { companyValue, severityValue, stop6Value, isActiveLoadingOverlay } = this.state;

        if(!companyValue) {
            return null;
        }
        return (
            <div>
                <BlockUi tag="div" blocking={isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div>
                    <form>
                        <div className="form-group">
                            <label><b>Contractor name</b></label>
                            <p>{this.state.name}</p>
                        </div>
                        <div className="form-group">
                            <label><b>Contractor email</b></label>
                            <p>{this.state.email}</p>
                        </div>
                        <div className="form-group">
                            <label><b>Date time</b></label>
                            <DateTime onChange={this.handleDateTime} defaultValue={moment(this.state.dateTime)}/>
                        </div>
                        <div className="form-group">
                            <label><b>Severity</b></label>
                            <Select
                                value={severityValue}
                                onChange={this.handleChangeSeverity}
                                options={this.state.severities}/>
                        </div>
                        <div className="form-group">
                            <label><b>Stop 6</b></label>
                            <Select
                                value={stop6Value}
                                onChange={this.handleChangeStop6}
                                options={this.state.stop6}/>
                        </div>
                        <div className="form-group">
                            <label><b>Injury</b></label>
                            <input type="text"
                                className="form-control"
                                value={this.state.injury}
                                onChange={this.handleChangeInjury}/>
                        </div>
                        <div className="form-group">
                            <label><b>Activity</b></label>
                            <input type="text"
                                className="form-control"
                                value={this.state.activity}
                                onChange={this.handleChangeActivity}/>
                        </div>
                        <div className="form-group">
                            <label><b>Description</b></label>
                            <input type="text"
                                className="form-control"
                                value={this.state.description}
                                onChange={this.handleChangeDescription}/>
                        </div>
                    </form>
                    {this.state.failedMessage 
                        ? <div className="alert alert-danger" role="alert">
                                {this.state.failedMessage}
                            </div> 
                        : null}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default D12IncidentEdit;