import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class PasswordChange extends Component {
    constructor(props) {
        super(props);

        this.handleOldPassword = this.handleOldPassword.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
        this.handleReTypeNewPassword = this.handleReTypeNewPassword.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClickShowingOldPassword = this.handleClickShowingOldPassword.bind(this);
        this.handleClickShowingNewPassword = this.handleClickShowingNewPassword.bind(this);
        
        this.state = {
            oldPassword: '',
            newPassword: '',
            reTypeNewPassword: '',
            failedMessage: '',
            isSuccess: false,
            isShowingUpdateCurrentUser: true,
            isShowingOldPassword: 'fe fe-eye-off',
            isShowingNewPassword: 'fe fe-eye-off'
        };   
        
        this.props.handleClickMenu('password', 'account');
     
        const userObj = getObject('user');
        this.userObj = userObj;
        if(userObj) {
            this.user = userObj.user;
            this.token = userObj.token;
        }

        setObject('lastRoute', 'passwordchange');
    }

    handleBack() {
        this.setState({
            isBackToHome : true
        });
    }

    handleOldPassword(event) {
        const oldPassword = event.target.value;
        this.setState({
            oldPassword
        });
    }

    handleNewPassword(event) {
        const newPassword = event.target.value;
        this.setState({
            newPassword
        });
    }

    handleReTypeNewPassword(event) {
        const reTypeNewPassword = event.target.value;
        this.setState({
            reTypeNewPassword
        });
    }

    async handleSave() {
        try {
            this.props.setActiveLoadingOverlay(true);
            this.setState({
                isSuccess: false,
                failedMessage: ''
            });
    
            if(this.state.newPassword !== this.state.reTypeNewPassword) {
                this.setState({
                    failedMessage: "New password doesn't match."
                });
            } else {
                const signInResult = await axios({
                    url: `${config.api.user}/signin`,
                    method: 'post',
                    data: {
                        email: this.user.email,
                        password: this.state.oldPassword               
                    } 
                });

                const updateUserResult = await axios({
                    url: `${config.api.user}`,
                    method: 'put',
                    headers: { 'x-access-token': this.token },
                    data: {
                        password: this.state.newPassword               
                    } 
                });

                this.setState({
                    isSuccess: true,
                    failedMessage: '',
                    oldPassword: '',
                    newPassword: '',
                    reTypeNewPassword: ''
                });
            }
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            // this.setState({
            //     isSuccess: false,
            //     failedMessage,
            // });
            this.setState({
                failedMessage: "Old password doesn't match"
            });
        } finally {
            this.props.setActiveLoadingOverlay(false);
        }
    }


    async componentDidMount() {
        const apiControlUpdateCurrentUserResult = await axios({
            url: `${config.api.apicontrols}/checking?route=/users&method=PUT&parameter=/`,
            method: 'get',
            headers: { 'x-access-token': this.userObj.token }
        });
        
        this.setState({
            isShowingUpdateCurrentUser:  apiControlUpdateCurrentUserResult.data.data.isAllowed
        });
    }

    componentWillUnmount() {
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    handleClickShowingOldPassword(event) {
        const value = event.target.value;
        if(this.state.isShowingOldPassword === 'fe fe-eye-off') {
            this.setState({
                isShowingOldPassword: 'fe fe-eye'
            });
        } else {
            this.setState({
                isShowingOldPassword: 'fe fe-eye-off'
            });
        }
    }

    handleClickShowingNewPassword(event) {
        const value = event.target.value;
        if(this.state.isShowingNewPassword === 'fe fe-eye-off') {
            this.setState({
                isShowingNewPassword: 'fe fe-eye'
            });
        } else {
            this.setState({
                isShowingNewPassword: 'fe fe-eye-off'
            });
        }
    }

    render() {
        if(!this.user) {
            return <Redirect to="/login"/>;
        }

        if(!this.state.isShowingUpdateCurrentUser) {
            return <Redirect to="/"/>;
        }

        if(this.state.isSuccess) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        isSuccess: false
                    });
                }, config.notifDuration);
        }

        if(this.state.failedMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        failedMessage: ''
                    });
                }, config.notifDuration);
        }

        const component = (
            <div>
                <br/>
                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                  </div> 
                : null}
                {this.state.isSuccess
                ? <div className="alert alert-success" role="alert">
                      your account has been updated successfully.
                </div> 
                : null}
                <hr/>
                <h1>My Password</h1>
                <hr/>
                <form>
                    <label><b>Old password</b></label>
                    <div className="row">
                        <div className="col-md-11">
                            <input 
                                type={this.state.isShowingOldPassword === "fe fe-eye" ? "text" : "password"}
                                className="form-control"
                                value={this.state.oldPassword}
                                onChange={this.handleOldPassword}/>
                        </div>
                        <div className="col-md-1">
                            <button class="btn" 
                                    type="button" 
                                    title={this.state.isShowingOldPassword === "fe fe-eye" ? "Hide password" : "Show password"}
                                    onClick={this.handleClickShowingOldPassword}><span className={this.state.isShowingOldPassword}></span></button>
                        </div>     
                    </div>
                    <label><b>New password</b></label>
                    <div className="row">
                        <div className="col-md-11">
                            <input 
                                type={this.state.isShowingNewPassword === "fe fe-eye" ? "text" : "password"}
                                className="form-control"
                                value={this.state.newPassword}
                                onChange={this.handleNewPassword}/>
                        </div>
                        <div className="col-md-1">
                            <button class="btn" 
                                    type="button" 
                                    title={this.state.isShowingNewPassword === "fe fe-eye" ? "Hide password" : "Show password"}
                                    onClick={this.handleClickShowingNewPassword}><span className={this.state.isShowingNewPassword}></span></button>
                        </div>     
                    </div>
                    <div className="form-group">
                        <label><b>Re-type new password</b></label>
                        <input 
                            type="password"
                            className="form-control"
                            value={this.state.reTypeNewPassword}
                            onChange={this.handleReTypeNewPassword}/>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            </div>
        );

        return this.user ? component : <Redirect to="/login" />;
    }
}


export default PasswordChange;