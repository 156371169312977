import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject, getSuccessMessage, clearSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import SectionTemplateCreate from '../components/SectionTemplateCreate';
import SectionTemplateEdit from '../components/SectionTemplateEdit';
import InspectionEdit from '../components/InspectionEdit';
import SectionTemplateDelete from '../components/SectionTemplateDelete';
import DownloadInspectionPdfModal from '../components/DownloadInspectionPdfModal';
import InspectionSubmissionConfirmation from '../components/InspectionSubmissionConfirmation';
import EditAssignedContractorInspection from '../components/EditAssignedContractorInspection';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import {convertToStd} from '../utils/date';
import moment from 'moment'


class SectionInspection extends Component {

    constructor(props) {
        super(props);

        this.filters = [
            {id: 'title', value: ''},
            {id: 'scoringType', value: ''}
        ];

        const successMessage = getSuccessMessage();
        if(successMessage) {
            this.successMessage = successMessage;
            clearSuccessMessage(); 
        }

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showDeleteModal: false,
            showAddModal: false,
            showConfirmationModal: false,
            editAssignedContractorInspectionModal: false,
            editId: 0,
            deleteId: 0,
            isBackToInspectionTemplate: false,
            failedMessage: '',
            redirectId: 0,
            inspection: null,
            showFiltering: false,
            isShowingUpdateInspection: false,
            isShowingDeleteInspection: false,
            isShowingSubmitInspection: false,
            isShowingEditASsignedContractor: false,
            showDownloadInspectionPdfModal: false,
            inspectionId: 0,
            isIncludingPositiveFinding: false
        };

        this.fetchData = this.fetchData.bind(this);
        this.templateId = props.templateId;
        this.hideNavBar = props.hideNavBar;
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleQuestion = this.handleQuestion.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDownloadPdf = this.handleDownloadPdf.bind(this);
        this.handleCloseDownloadInspectionPdfModal = this.handleCloseDownloadInspectionPdfModal.bind(this);
        this.loadInspection = this.loadInspection.bind(this);

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('inspection', 'inspection');
        this.user = getObject('user');

        setObject('lastRoute', `inspection/${this.props.inspectionId}/template/${this.props.templateId}/section`);
    }


    loadInspection() {
        axios({
            url: `${config.api.inspection}/${this.props.inspectionId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        })
        .then(response => {
            this.setState(() => {
                const data = response.data.data;
                return {
                    inspection: data
                };
            });
        }).catch(e => {
            const data = e.response.data;
        });
    }

    async componentDidMount() {
        if(this.user) {
            try {
                const inspectionPromise = axios({
                    url: `${config.api.inspection}/${this.props.inspectionId}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });

                const apiControUpdateInspectionPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlDeleteInspectionPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspections&method=DELETE&parameter=/:id`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
        
                const apiControlSubmitInspectionPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId/done`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
                const apiControlSubmitInspectionWithContractorsPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId/contractors/done`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });

                const apiControlEditAssignedContractorPromise = axios({
                    url: `${config.api.apicontrols}/checking?route=/inspections&parameter=/:inspectionId/assignedcontractor&method=PUT`,
                    method: 'get',
                    headers: {'x-access-token': this.user.token}
                })
            
                const [apiControlUpdateInspectionResult, 
                       apiControlDeleteInspectionResult, 
                       apiControlSubmitInspectionResult,
                       apiControlSubmitInspectionWithContractorsResult,
                       apiControlEditAssignedContractorResult,
                       inspectionResult] = await
                    Promise.all([apiControUpdateInspectionPromise, 
                                 apiControlDeleteInspectionPromise,
                                 apiControlSubmitInspectionPromise, 
                                 apiControlSubmitInspectionWithContractorsPromise,
                                 apiControlEditAssignedContractorPromise,
                                 inspectionPromise]);
                
                const inspection = inspectionResult.data.data;
                this.setState({
                    isShowingUpdateInspection: apiControlUpdateInspectionResult.data.data.isAllowed,
                    isShowingDeleteInspection: apiControlDeleteInspectionResult.data.data.isAllowed,
                    isShowingSubmitInspection: apiControlSubmitInspectionResult.data.data.isAllowed || apiControlSubmitInspectionWithContractorsResult.data.data.isAllowed, 
                    isShowingEditASsignedContractor: apiControlEditAssignedContractorResult.data.data.isAllowed,
                    inspection,
                    inspectionId: inspection.id 
                });
            } catch(err) {
                const statusCode = err.response.status;
                if(statusCode == 403
                   || statusCode == 400) {
                    this.props.history.push('/');
                    // this.setState({
                    //     isForbidden: true
                    // });
                }
            }


        }
    }

    handleDownloadPdf() {
        this.setState({
            showDownloadInspectionPdfModal: true
        });
    }

    handleCloseDownloadInspectionPdfModal() {
        this.setState({
            showDownloadInspectionPdfModal: false
        });
    }

    handleFilterInput() {
        this.setState((state) => ({
            showFiltering: !state.showFiltering
        }));
    }

    handleCloseFilterInput() {
        this.filters = [
          {id: 'title', value: ''},
          {id: 'scoringType', value: 'ALL'}
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }

    handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }

    handleQuestion(redirectId) {
        this.setState({
            redirectId
        });
    }

    handleBack() {
        this.setState({
            isBackToInspectionTemplate : true
        });
    }

    refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const filtered = this.filters;

        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = '&orderBy=title&orderDirection=asc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        axios({
            url: `${config.api.inspection}/${this.props.inspectionId}/sections?size=${size}&page=${page}&${filterString}&${sortedString}`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        })
        .then(response => {
            const data = response.data.data;
            this.setState({
                data: data.results,
                pages: data.totalPage,
                loading: false
            });
        })
        .catch(err => {
        });
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState(() => {
            this.successMessage = successMessage;
            return {
                failedMessage: ''
            }
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    handleSubmitInspection = () => {
        this.hideNavBar(true); 
        this.setState({showConfirmationModal: true});
    }

    handleEditAssignedContractorInspection = () => {
        this.hideNavBar(true); 
        this.setState({editAssignedContractorInspectionModal: true});
    }

    render() {

        if(!this.user) {
            return <Redirect to="/login"/>;
        }

        if(this.state.isForbidden) {
            return <Redirect to="/"/>;   
        }

        if(this.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.successMessage = '';
                    this.setState({
                        notifActive: true
                    })
                }, config.notifDuration);
        } 

        if(this.state.failedMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.setState({
                        failedMessage: '',
                        notifActive: true
                    })
                }, config.notifDuration);
        }
         
        const { data, pages, loading } = this.state;
        const component = (
            <div>
                <br/>
                {this.successMessage
                ? 
                <div className="alert alert-success" role="alert">
                    {this.successMessage}
                </div>
                : null}
                {this.state.failedMessage
                    ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                    </div>
                    : null}
                <hr/>
                {this.state.inspection
                 ?
                 <div className="grid4-container">
                    <div className="grid-item">
                        <h1>{this.state.inspection.name}</h1>
                    </div>
                    <div className="grid-item" style={{ justifySelf: "end" }}>
                        {this.state.isShowingSubmitInspection
                        && (this.state.inspection && !this.state.inspection.submissionDate)
                        ?
                            <button className="btn" 
                                    onClick={this.handleSubmitInspection} 
                                    title="Submit">Submit</button>
                        :
                        null}
                        {this.state.inspection.submissionDate
                        ?
                        <div className="col-md">
                            <button className="btn"
                                    onClick={this.handleDownloadPdf}
                                    title="Download inspection pdf">
                                    <span class="fe fe-download"></span>                                
                            </button>
                        </div>
                        :
                        null}
                    </div>
                    <div className="grid-item" style={{ justifySelf: "end" }}>
                        {this.state.isShowingUpdateInspection
                        && (this.state.inspection && !this.state.inspection.submissionDate)
                        ?
                        <button className="btn" onClick={this.handleEdit} title="Edit"><span class="fe fe-edit-2"></span></button>
                        :
                        null}
                        {this.state.inspection.submissionDate
                        && this.state.isShowingEditASsignedContractor 
                        ?
                        <div className="col-md">
                            <button className="btn" 
                            onClick={this.handleEditAssignedContractorInspection} 
                            title="Edit assigned contractor"><span class="fe fe-edit-2"></span></button>
                        </div>
                        :
                        null}
                    </div>
                    <div className="grid-item" style={{ justifySelf: "end" }}>
                        {this.state.isShowingDeleteInspection
                        ?
                        <button className="btn" onClick={() => this.handleDelete(this.props.templateId)} title="Delete"><span class="fe fe-trash-2"></span></button>
                        :
                        null}
                    </div>
                 </div>
                 :
                 null}
                {this.state.inspection 
                 ?
                <div className="row">
                    <div className="col">
                        <b>Project name : </b><br/>
                        <p>{this.state.inspection.projectName}</p>
                    </div>
                    <div className="col">
                        <b>Project location : </b><br/>
                        <p>{this.state.inspection.location}</p>
                    </div>
                    <div className="col">
                        <b>Company : </b><br/>
                        <p>{this.state.inspection.company}</p>
                    </div>
                    <div className="col">
                        <b>Point possible : </b><br/>
                        <p>{this.state.inspection.pointPossible}</p>
                    </div>
                    <div className="col">
                        <b>Point received : </b><br/>
                        <p>{this.state.inspection.pointReceived}</p>
                    </div>
                    {this.state.inspection.dateTimeConducted
                    ?
                    <div className="col">
                        <b>Date time conducted: </b><br/>
                        <p>{moment(this.state.inspection.dateTimeConducted, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</p>
                    </div>
                    :
                    null}
                    {this.state.inspection.submissionDate
                    ?
                    <div className="col">
                        <b>Submission date: </b><br/>
                        <p>{moment(this.state.inspection.submissionDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</p>
                    </div>
                    :
                    null}
                </div>
                 :
                 null}
                <hr/>
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    {!this.state.showFiltering
                    ?
                    <div className="col-md-12">
                        <button type="button" 
                                className="btn float-right" 
                                onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}
                </div>
                {this.state.showFiltering 
                ?
                <div>
                <br/>
                <br/>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Title</b></label>
                            <input  className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Scoring type</b></label>
                            <select className="form-control" onChange={e => this.handleSearch(1, e.target.value)}>
                                <option value="ALL">All</option>
                                <option value="CUMULATIVE">Cumulative</option>
                                <option value="ALL_OR_NOTHING">All or nothing</option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showEditModal}
                contentLabel="Minimal Modal Example">
                <InspectionEdit
                    closeModal={this.handleCloseEditModal}
                    id={this.props.inspectionId}
                    refreshTable={this.fetchData}
                    setSuccessMessage={() => this.setSuccessMessage('inspection has been updated successfully')}
                    setFailedMessage={this.setFailedMessage} 
                    clearMessage={this.clearMessage}
                    tablePaging={{
                        page: this.page,
                        pageSize: this.pageSize
                    }}
                    onSuccess={this.loadInspection}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showConfirmationModal}>
                    {this.state.inspection
                    ?
                    <InspectionSubmissionConfirmation 
                        inspectionId={this.props.inspectionId}
                        projectId={this.state.inspection.projectId}
                        closeModal={() =>  {  this.hideNavBar(false); this.setState({showConfirmationModal: false}); }}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        loadInspection={this.loadInspection}/>
                    :
                    null}
                </ReactModal>
                <ReactModal isOpen={this.state.editAssignedContractorInspectionModal}>
                    {this.state.inspection
                    ?
                    <EditAssignedContractorInspection 
                        inspectionId={this.props.inspectionId}
                        projectId={this.state.inspection.projectId}
                        closeModal={() =>  {  this.hideNavBar(false); this.setState({editAssignedContractorInspectionModal: false}); }}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        loadInspection={this.loadInspection}/>
                    :
                    null}
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteModal}
                            contentLabel="Minimal Modal Example"
                            style={{
                                content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)'
                                }
                            }}>
                    <DeleteModal
                        id={this.props.inspectionId}
                        deleteUrl={config.api.inspection}
                        customDeleteMessage={
                            this.state.inspection 
                            && (this.user && this.user.user.id !== this.state.inspection.preparedById) 
                            ? `Do you want to delete the inspection created by ${this.state.inspection.preparedBy}` 
                            : ''}
                        redirectTo={`/inspection`} 
                        closeModal={this.handleCloseDeleteModal} />
                </ReactModal>
                <ReactModal isOpen={this.state.showDownloadInspectionPdfModal}
                            contentLabel="Minimal Modal Example"
                            style={{
                                content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)'
                                }
                            }}>
                    <DownloadInspectionPdfModal 
                        inspectionId={this.state.inspectionId}
                        closeModal={this.handleCloseDownloadInspectionPdfModal} />
                </ReactModal>
                <ReactTable
                    columns={[
                        {
                            Header: "Action",
                            accessor: "id",
                            width: 74,
                            filterable: false,
                            Cell: cellInfo => {
                                return (
                                    <div className="row">
                                        <div className="col-md-auto">
                                            <Link to={`/inspection/${this.props.inspectionId}/template/${this.props.templateId}/section/${cellInfo.value}/question`} 
                                                  class="btn btn-sm">Open</Link>
                                        </div>
                                    </div>);
                            }
                        },
                        {
                            Header: "Section title",
                            accessor: "title",
                            sortable: true
                        },
                        {
                            Header: "Scoring type",
                            accessor: "scoringType",
                            Filter: ({ filter, onChange }) =>
                            <select
                              onChange={event => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : "ALL"}
                            >
                              <option value="ALL">All</option>
                              <option value="CUMULATIVE">Cumulative</option>
                              <option value="ALL_OR_NOTHING">All or nothing</option>
                            </select>
                        },
                        {
                            Header: "Section point",
                            accessor: "point"
                        },
                        {
                            Header: "Point possible",
                            accessor: "pointPossible"
                        },
                        {
                            Header: "Point received",
                            accessor: "pointReceived"
                        },
                        {
                            Header: "Created date",
                            accessor: "createdDate",
                            Cell: cellInfo => {
                                const date = convertToStd(cellInfo.value);
                                if(date == 'Invalid date') {
                                  return '';
                                }
                                return date;
                            }
                        },
                        {
                            Header: "Updated date",
                            accessor: "updatedDate",
                            Cell: cellInfo => {
                                const date = convertToStd(cellInfo.value);
                                if(date == 'Invalid date') {
                                  return '';
                                }
                                return date;
                            }
                        }
                    ]}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <div className="col-md-12">
                        <Link to={`/inspection`} 
                            class="btn"><span class="fe fe-arrow-left"> Back</span></Link>
                    </div>
                </div>
            </div>
        );

        return component;
    }
}

export default withRouter(SectionInspection);