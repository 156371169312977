import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import QuestionTemplateCreate from '../components/QuestionTemplateCreate';
import QuestionOpenItemEdit from '../components/QuestionOpenItemEdit';
import QuestionTemplateDelete from '../components/QuestionTemplateDelete';
import PointChangedConfirmation from '../components/PointChangedConfirmation';
import QuestionPointEdit from '../components/QuestionPointEdit';
import DeleteModal from '../components/DeleteModal';
import {convertToStd} from '../utils/date';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import moment from 'moment';

class OpenItemContractor extends Component {

    constructor(props) {
        super(props);
        
        this.filters = [
            {id: 'inspectionName', value: ''},
            {id: 'question', value: ''},
            {id: 'note', value: ''},
            {id: 'status', value: 'ALL'}
        ];

        if(this.props.location.state) {
            this.successMessage = this.props.location.state.successMessage; 
        }

        const history = createBrowserHistory();
        history.push({ state: {successMessage: ""} });

        this.state = {
            data: [],
            pages: null,
            loading: true,
            showEditModal: false,
            showDeleteModal: false,
            showAddModal: false,
            showEditPoint : false,
            showEditPointConfirmation: false,
            editId: 0,
            deleteId: 0,
            isBackToInspectionTemplate: false,
            pointFailedMessage: '',
            failedMessage: '',
            pointsToChange: [],
            section: null,
            isShowingApiControlAnsweringInspection: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.sectionId = props.sectionId;
        this.hideNavBar = props.hideNavBar;
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        this.setSuccessMessage = this.setSuccessMessage.bind(this);
        this.setFailedMessage = this.setFailedMessage.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleEditPoint = this.handleEditPoint.bind(this);
        this.handleCloseEditPointModal = this.handleCloseEditPointModal.bind(this);
        this.setPointsToChange = this.setPointsToChange.bind(this);
        this.handleClosePointConfirmationModal = this.handleClosePointConfirmationModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
        this.setPointFailedMessage = this.setPointFailedMessage.bind(this);
        this.loadSection = this.loadSection.bind(this);

        this.page = 0;
        this.pageSize = 10;

        this.props.handleClickMenu('contractor', '');

        this.user = getObject('user');
    }

    loadSection(onDone) {

    }

    async componentDidMount() {
        if(this.user) {
            const apiControlAnsweringInspectionPromise = axios({
                url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId/answers/raw`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    

            const contractorStatPromise = axios({
                url: `${config.api.openitems}/contractors/${this.props.contractorId}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const [apiControlAnsweringInspectionResult,
                   contractorStatResult] = 
                await Promise.all([apiControlAnsweringInspectionPromise,
                                   contractorStatPromise]);
            
            this.setState({
                contractorStat: contractorStatResult.data.data,
                isShowingApiControlAnsweringInspection: apiControlAnsweringInspectionResult.data.data.isAllowed
            });
        }

    }

    handleFilterInput() {
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
    }
    
    setPointFailedMessage(pointFailedMessage) {
        this.setState({
            pointFailedMessage
        })
    }

    handleCloseFilterInput() {
        this.filters = [
            {id: 'inspectionName', value: ''},
            {id: 'question', value: ''},
            {id: 'note', value: ''},
            {id: 'status', value: 'ALL'}
        ];
        this.setState((state) => ({
          showFiltering: !state.showFiltering
        }));
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        });
    }
    
    handleSearch(idx, value) {
        this.filters[idx].value = value;
        this.refreshTable({
            page: this.page,
            pageSize: this.pageSize
        });
    }

    handleCloseEditPointModal() {
        this.hideNavBar(false);
        this.setState({
            showEditPoint: false
        });
    }

    handleClosePointConfirmationModal() {
        this.setState((state, props) => {
            this.hideNavBar(false);
            return {
                showEditPointConfirmation: false,
                showEditPoint: false
            }
        });
    }

    setPointsToChange(pointsToChange) {
        this.hideNavBar(true);
        this.setState(
            (state, props) => {
                return {pointsToChange, showEditPointConfirmation: true};
        });
    }

    handleBack() {
        this.setState({
            isBackToInspectionTemplate : true
        });
    }

    refreshTable(state) {
        const user = getObject('user');
        const size = state.pageSize;
        const page = parseInt(state.page) + 1;

        const filtered = this.filters;

        console.log(filtered);
    
        let filterString = '';
        const filterXs = 
              R.pipe(
                R.filter(x => x.value !== ''),
                R.map(x => `${x.id}=${x.value}`)
              )(filtered);
        
        filterString = filterXs.join('&');

        this.page = parseInt(state.page);
        this.pageSize = state.pageSize;

        const sortedXs = R.map(
            x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
            state.sorted || []);
        let sortedString = 'orderBy=question&orderDirection=asc';

        if(sortedXs.length > 0) {
            sortedString = sortedXs.join('&');
        }

        axios({
            url: `${config.api.openitems}/all/?assignedContractorId=${this.props.contractorId}&size=${size}&page=${page}&${filterString}&${sortedString}`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        }).then(response => {
            const data = response.data.data;
            this.setState({
                data: data.results,
                pages: data.totalPage,
                loading: false
            });
        }).catch(err => {
                console.log(err);
        });
    }

    fetchData(state, instance) {
        this.clearMessage();
        this.setState({ loading: true });
        this.refreshTable(state);
    }

    handleAdd() {
        this.hideNavBar(true);
        this.setState({
            showAddModal: true
        });
    }

    handleEdit(editId) {
        this.hideNavBar(true);
        this.setState({
            showEditModal: true,
            editId
        });
    }

    handleCloseAddModal() {
        this.hideNavBar(false);
        this.setState({
            showAddModal: false
        });
    }

    handleCloseEditModal(id) {
        this.hideNavBar(false);
        this.setState({
            showEditModal: false
        });
    }

    handleDelete(deleteId) {
        this.setState({
            showDeleteModal: true,
            deleteId
        });
    } 

    handleEditPoint() {
        this.hideNavBar(true);
        this.setState({
            showEditPoint: true
        });
    }

    handleCloseDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    setSuccessMessage(successMessage) {
        this.setState(() => {
            this.successMessage = successMessage;
            return {
                failedMessage: ''
            };
        });
    }

    setFailedMessage(failedMessage) {
        this.setState({
            failedMessage,
            successMessage: ''
        });
    }

    clearMessage() {
        this.setState({
            successMessage: '',
            failedMessage: ''
        });
    }

    renderView = (cellInfo) => {
        if(cellInfo.value) {
            return (
            <button className="btn btn-sm" title="View image" onClick={() => {
                this.setState(() => {
                    this.hideNavBar(true);
                    return {imageToBeDisplayed: cellInfo.value}
                });
            }}><span className="fe fe-eye"></span></button>);
        }
        return null;
    }

    render() {
        if(!this.user) {
            return <Redirect to="/login" />;
        }
        const columns = [
            {
                Header: "Inspection name",
                accessor: "inspectionName"
            },
            {
                Header: "Question name",
                accessor: "question"
            },
            {
                Header: "Status",
                Cell: ({ row, original }) => {
                    return original.note 
                           || original.image1
                           || original.image2
                           || original.image3
                           || original.image4 
                           ? "Closed"
                           : "Open";
                }
            },
            {
                Header: "Fix note",
                accessor: "note"
            },
            {
                Header: "Fix image 1",
                accessor: "image1",
                Cell: this.renderView
            },
            {
                Header: "Fix image 2",
                accessor: "image2",
                Cell: this.renderView
            },
            {
                Header: "Fix image 3",
                accessor: "image3",
                Cell: this.renderView
            },
            {
                Header: "Fix image 4",
                accessor: "image4",
                Cell: this.renderView
            },
            {
                Header: "Date fixed",
                accessor: "updatedDate",
                Cell: cellInfo => {
                    const date = convertToStd(cellInfo.value);
                    if(date == 'Invalid date') {
                      return '';
                    }
                    return date;
                }
            }
        ];

        if(this.state.inspection
           && !this.state.inspection.openItemSubmissionDate) {
            columns.unshift({
                Header: "Action",
                width: 60,
                filterable: false,
                id: "answer",
                accessor: x => x,
                Cell: cellInfo => {
                    const openItem = cellInfo.original;
                    return (<div className="row">
                                <div className="col-md-auto">
                                    <button type="button"
                                        className="btn btn-sm"
                                        title="Fix open item"
                                        onClick={() => this.handleEdit(openItem.id)}><span class="fe fe-edit"></span></button>
                                </div>
                            </div>);
                }
            });
        }

        const { data, pages, loading } = this.state;
        if(this.successMessage) {
            clearTimeout(this.timer);
            this.timer =
                setTimeout(() => {
                    this.successMessage = '';
                    this.setState({
                        notifActive: true
                    })
                }, config.notifDuration);
        } 
        const previewStyle = {
            position: 'absolute',
            top: '50%',
            left: '54%',
            width: '440px',
            height: '300px',
            marginTop: '-150px', /* Half the height */
            marginLeft: '-250px' /* Half the width */
        };
        const component = (<div>
                <br/>
                {this.successMessage
                ? <div className="alert alert-success" role="alert">
                        {this.successMessage}
                    </div>
                : null}
                {this.state.failedMessage
                ? <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                  </div>
                : null}
                <hr/>
                <h1>Open items</h1>
                {this.state.contractorStat
                 ?
                 <div className="row">
                    <div className="col">
                        <b>Total : </b><br/>
                        <p>{this.state.contractorStat.total}</p>
                    </div>
                 <div className="col">
                     <b>Closed : </b><br/>
                     <p>{this.state.contractorStat.totalFixed}</p>
                 </div>
                 <div className="col">
                     <b>Contractor name : </b><br/>
                     <p>{this.state.contractorStat.name}</p>
                 </div>
                 <div className="col">
                     <b>Contractor email : </b><br/>
                     <p>{this.state.contractorStat.email}</p>
                 </div>
                 <div className="col">
                     <b>Company : </b><br/>
                     <p>{this.state.contractorStat.companyName}</p>
                 </div>
             </div>
             :
            null}
                <hr/>
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    {!this.state.showFiltering
                    ?
                    <div className="col-md-12">
                        <button type="button" className="btn float-right" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
                    </div>
                    :
                    null}
                </div>
                {this.state.showFiltering 
                ?
                <div>
                <br/>
                <br/>
                <div className="card">
                    <div className="pull-right" style={{ marginRight: '10px'}}>
                        <button type="button"  
                                className="pull-right clickable close-icon close" 
                                onClick={this.handleCloseFilterInput}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-auto">
                            <label><b>Inspection name</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(0, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Question</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(1, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Note</b></label>
                            <input className="form-control" 
                                    onChange={e => this.handleSearch(2, e.target.value)}/>
                        </div>
                        <div className="col-md-auto">
                            <label><b>Status</b></label>
                            <select 
                                className="form-control"
                                onChange={e => this.handleSearch(3, e.target.value)}>
                                <option value="ALL">All</option>
                                <option value="OPEN">Open</option>
                                <option value="CLOSED">Closed</option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                :
                null}
                <ReactModal isOpen={this.state.showAddModal}
                    contentLabel="Minimal Modal Example">
                    <QuestionTemplateCreate
                        closeModal={this.handleCloseAddModal}
                        refreshTable={this.fetchData}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        setSuccessMessage={this.setSuccessMessage}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showEditPoint}>
                    <QuestionPointEdit
                        closeModal={this.handleCloseEditPointModal}
                        setPointsToChange={this.setPointsToChange}
                        sectionId={this.sectionId} 
                        pointFailedMessage={this.state.pointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.imageToBeDisplayed}
                                    style={{
                                        content: {
                                            marginTop: '6%',
                                            marginLeft: '20%',
                                            marginBottom: '6%',
                                            marginRight: '20%'
                                        }
                                    }}> 
                        <div>
                            <div className="pull-right" style={{marginRight: '10px'}}>
                                    <button type="button"  
                                            className="pull-right clickable close-icon close" 
                                            onClick={() => this.setState(() => {
                                                this.hideNavBar(false);
                                                return {imageToBeDisplayed: ''};
                                            })}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            <br/>
                            <br/>
                            <div className="align-middle">
                                <div className="form-group">
                                    <img src={this.state.imageToBeDisplayed} style={previewStyle}/>
                                </div>
                            </div>
                        </div>
                </ReactModal>
                <ReactModal isOpen={this.state.showEditModal}>
                    <QuestionOpenItemEdit
                        closeModal={this.handleCloseEditModal}
                        id={this.state.editId}
                        questionId={this.state.editId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        loadSection={this.loadSection} />
                </ReactModal>
                <ReactModal isOpen={this.state.showEditPointConfirmation}
                            style={{
                                content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)'
                                }
                            }}>
                    <PointChangedConfirmation 
                        pointsToChange={this.state.pointsToChange}
                        sectionId={this.sectionId}
                        tablePaging={{
                            page: this.page,
                            pageSize: this.pageSize
                        }}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        closeModal={this.handleClosePointConfirmationModal}
                        closeConfirmationModal={() => this.setState(() => {
                                                    return {showEditPointConfirmation: false} } )}
                        loadSection={this.loadSection}
                        setPointFailedMessage={this.setPointFailedMessage}/>
                </ReactModal>
                <ReactModal isOpen={this.state.showDeleteModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}>
                    <DeleteModal
                        closeModal={() => {
                            this.loadSection(() => {
                                this.handleCloseDeleteModal();
                            });
                        }}
                        id={this.state.deleteId}
                        refreshTable={this.fetchData}
                        setSuccessMessage={this.setSuccessMessage}
                        setFailedMessage={this.setFailedMessage}
                        deleteUrl={config.api.questiontemplate} />
                </ReactModal>
                <ReactTable
                    columns={columns}
                    manual
                    data={data}
                    pages={pages}
                    loading={loading}
                    onFetchData={this.fetchData}
                    filterable={false}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    sortable={false}
                />
                <br />
                <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                    {this.state.isBackToInspectionTemplate ? <Redirect to={`/contractor`}/> : null}
                    <div className="col-md-12">
                        <button type="button" className="btn" onClick={this.handleBack}><span class="fe fe-arrow-left"> Back</span></button>
                    </div>
                </div>
                <br/>
            </div>);

        return component;
    }
}

export default OpenItemContractor;