import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Select from 'react-select';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda';
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class EditOpenItemAssignedContractor extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleIsIncludingPositiveFinding = this.handleIsIncludingPositiveFinding.bind(this);
        this.user = getObject('user');
        this.state = {
            contractorOptions: [],
            assignedContractorIds: null,
            assignedContractors: [],
            isDisabledYesButton: true,
            isSubmitInspectionCurrentUser: false,
            isIncludingPositiveFinding: false,
            openItemId: props.openItemId
        };
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const apiControlSubmitCurrentUserResult = await axios({
                url: `${config.api.apicontrols}/checking?route=/inspections&method=PUT&parameter=/:inspectionId/done`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    

            const contractorResult = await axios({
                url: `${config.api.project}/${this.props.projectId}/contractors?type=CONTRACTOR`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const contractors = contractorResult.data.data.results;
            const contractorOptions = 
                R.map(x => ({ value: x.userId, 
                                label: x.email,
                                ...x}), 
                        contractors);
    
            this.setState({
                contractorOptions
            });

        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleIsIncludingPositiveFinding(event) {
        const target = event.target;
        const isIncludingPositiveFinding = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            isIncludingPositiveFinding
        });
    }

    handleChangeContractor = (contractor) => {
        console.log('contractor = ', contractor);
        const isDisabledYesButton = contractor == null;    
        const assignedContractorIds = [contractor.value];

        // console.log('assigned contractors = ', contractor);

        this.setState({
            assignedContractorIds,
            assignedContractors: [contractor],
            assignedContractorId: [contractor],
            isDisabledYesButton
        });

    }

    async handleSubmit() {
        try {
            this.setState({isActiveLoadingOverlay: true});
            const axiosCall = axios({
                    url: `${config.api.openitems}/${this.state.openItemId}/contractors`,
                    method: 'put',
                    headers: { 'x-access-token': this.user.token },
                    data: {
                        assignedContractorId: this.state.assignedContractorIds[0]
                    }
                });
            const response = await axiosCall;
            this.props.setSuccessMessage("inspection has been submitted successfully");
            // this.props.refeshTable({
            //     page: this.props.tablePaging.page,
            //     pageSize: this.props.tablePaging.pageSize,
            //     filtered: [],
            //     isLoading: false
            // });
            this.props.closeModal();                
        } catch(e) {
            const data = e.response.data;
            this.props.setFailedMessage(data.message);
            
            this.props.closeModal();
        }  finally {
            this.setState({isActiveLoadingOverlay: false});
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.props.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                {this.state.isSubmitInspectionCurrentUser
                ?
                <div className="align-middle">
                    <div className="form-group">
                        <b>Are you sure wanto to submit the inspection ?</b>
                    </div>
                </div>
                :
                null}
                {!this.state.isSubmitInspectionCurrentUser
                ?
                <div className="align-middle">
                    <div className="form-group">
                        <div className="col-md-12">
                            <b>Please select the sub contractor</b>
                        </div>
                    </div>
                </div>
                :
                null}
                {!this.state.isSubmitInspectionCurrentUser
                ?
                <div className="form-group">
                    <div className="col-md-12">
                        <Select
                            onChange={this.handleChangeContractor}
                            options={this.state.contractorOptions}
                            placeholder="Please select contractor"/>
                        <br/>
                        {R.map(inspection => {
                            return (<div>
                                        <div className="form-group">
                                            <label><b>Email</b></label>
                                            <p>{inspection.email}</p>
                                        </div>
                                        <div className="form-group">
                                            <label><b>Full Name</b></label>
                                            <p>{inspection.name}</p>
                                        </div>
                                        <div className="form-group">
                                            <label><b>Type</b></label>
                                            <p>{inspection.newType}</p>
                                        </div>
                                        {inspection.contractorName
                                        ?
                                        <div className="form-group">
                                            <label><b>Contractor Company</b></label>
                                            <p>{inspection.contractorName}</p>
                                        </div>
                                        :
                                        null}
                                    </div>);
                        }, this.state.assignedContractors || [])}
                    </div>
                </div>
                :
                null}
                <div className="form-group" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            disabled={this.state.isDisabledYesButton}
                            onClick={this.handleSubmit}>{this.state.isSubmitInspectionCurrentUser ? 'Submit' : 'Save'}</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default EditOpenItemAssignedContractor;