import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class PointChangedConfirmation extends Component {
    constructor(props) {
        super(props);

        this.closeModal = props.closeModal;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.refreshTable = props.refreshTable;
        this.id = props.id;
        this.handleSavePoint = this.handleSavePoint.bind(this);
        this.user = getObject('user');
        this.deleteUrl = props.deleteUrl;
    }

    handleSavePoint() {
        axios({
            url: `${config.api.sectiontemplate}/${this.props.sectionId}/questions/point`,
            method: 'put',
            headers: { 'x-access-token': this.user.token },
            data : this.props.pointsToChange
        })
        .then(response => {
            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('question point has been updated successfully.');
            this.props.loadSection();
            this.closeModal();
        }).catch(e => {
            const data = e.response.data;
            const failedMessage = data.message;
            this.props.setPointFailedMessage(failedMessage);
            this.props.closeConfirmationModal();
        });
    }

    render() {
        return (
            <div>
                <div className="align-middle">
                    <div className="form-group">
                        Are you sure want to save the data ?
                    </div>
                </div>
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-6">
                        <button className="btn"
                                style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSavePoint}>Yes</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.props.closeConfirmationModal}>No</button>
                    </div>
                </div>
            </div>
        );
    }
}


export default PointChangedConfirmation;