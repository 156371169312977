import { template } from "handlebars";

const successMessageLabel = 'successMessage';

function setObject(key, value) {
    if(value) {
        localStorage.setItem(key, JSON.stringify(value)); 
        if(key === 'isAlreadyLogin') {
            localStorage.setItem('isAlreadyLogin', true); 
        } else {
            localStorage.setItem('isAlreadyLogin', false);
        }
    } else {
        localStorage.setItem(key, value);
    }
}

function getObject(key) {
    const item = localStorage.getItem(key);
    if(item) {
        return JSON.parse(item);
    }
    return item;
}

function setSuccessMessage(successMessage) {
    setObject(successMessageLabel, successMessage);
}

function getSuccessMessage() {
    return getObject(successMessageLabel);
}

function clearSuccessMessage() {
    setSuccessMessage(null);
}

export {
    setObject,
    getObject,
    setSuccessMessage,
    getSuccessMessage,
    clearSuccessMessage
};