import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class QuestionTemplateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            point: 0,
            question: '',
            nonConformingResponse: '',
            recommendation: '',
            isSuccess: false,
            failedMessage: '',
            isDeleted: false,
            isPointChanged: false
        };

        this.sectionId = props.sectionId;
        this.handleQuestion = this.handleQuestion.bind(this);
        this.handleNonConformingResponse = this.handleNonConformingResponse.bind(this);
        this.handleRecommendation = this.handleRecommendation.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handlePoint = this.handlePoint.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
    }

    componentDidMount() {
        const user = getObject('user');
        axios({
            url: `${config.api.sectiontemplate}/${this.sectionId}/questions/ispointchanged`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        })
        .then(x => {
            const data = x.data;
            this.setState({
                isPointChanged: data.data
            });
        })
        .catch(x => {

        });
    }


    handlePoint(point) {
        this.setState({
            point
        });
    }

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }


    handleQuestion(question) {
        this.setState({
            question
        });
    }

    handleRecommendation(recommendation) {
        this.setState({
            recommendation
        });
    }

    handleNonConformingResponse(nonConformingResponse) {
        this.setState({
            nonConformingResponse
        });
    }

    handleSave() {
        const user = getObject('user');
        const questionTemplateAPI = config.api.questiontemplate;

        const header = {
            headers: { 'x-access-token': user.token }
        };

        const data = {
            sectionId: this.sectionId, 
            question: this.state.question
        };

        if(this.state.nonConformingResponse) {
            data.nonConformingResponse = this.state.nonConformingResponse;
        }

        if(this.state.isPointChanged) {
            data.point = this.state.point;
        }

        if(this.state.recommendation) {
            data.recommendation = this.state.recommendation;
        }

        axios.post(
                questionTemplateAPI,
                data,
                header)
            .then(res => {
                this.props.loadSection();
                this.props.setSuccessMessage('question template has been created successfully');
                this.handleCloseModal();
            }).catch(e => {
                const data = e.response.data;
                if (data.code === 400) {
                    this.setState({
                        failedMessage: data.message
                    });
                }
            });   
    }

    render() {
        return (
            <div>
                <div className="pull-right" style={{marginRight: '10px'}}>
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    {this.state.isPointChanged 
                    ?
                    <div className="form-group">
                        <label>Point <span style={{color: "red"}}>*</span></label>
                        <input 
                            className="form-control"
                            type="number"
                            defaultValue={this.state.point}
                            onChange={(event) => this.handlePoint(event.target.value)}/>
                    </div>
                    :
                    null}
                    <div className="form-group">
                        <label>Question <span style={{color: "red"}}>*</span></label>
                        <textarea 
                            className="form-control"
                            defaultValue={this.state.question}
                            onChange={(event) => this.handleQuestion(event.target.value)}>
                        </textarea>
                    </div>
                    <div className="form-group">
                        <label>Non conforming response <span style={{color: "red"}}>(Optional)</span></label>
                        <textarea 
                            className="form-control"
                            defaultValue={this.state.nonConformingResponse}
                            onChange={(event) => this.handleNonConformingResponse(event.target.value)}>
                        </textarea>
                    </div>
                    <div className="form-group">
                        <label>Recommendation <span style={{color: "red"}}>(Optional)</span></label>
                        <textarea 
                            className="form-control"
                            defaultValue={this.state.recommendation}
                            onChange={(event) => this.handleRecommendation(event.target.value)}>
                        </textarea>
                    </div>
                </form>
                {this.state.failedMessage 
                 ?
                 <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                 </div> 
                 :
                 null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            </div>
        );
    }
}


export default QuestionTemplateCreate;