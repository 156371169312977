import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import ProjectContractorCreate from '../components/ProjectContractorCreate';
import ProjectEdit from '../components/ProjectEdit';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import {convertToStd} from '../utils/date';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';

class ProjectContractor extends Component {

  constructor(props) {
    super(props);

    this.filters = [
      {id: 'email', value: ''},
      {id: 'name', value: ''},
      {id: 'contractorName', value: ''}
    ];

    this.state = {
      data: [],
      pages: null,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showAddModal: false,
      isBackToHome: false,
      editId: 0,
      deleteId: 0,
      redirectId: 0,
      successMessage: '',
      failedMessage: '',
      isBackToInspectionTemplate: false,
      project: null,
      isShowingGetProjectContractor: true,
      isShowingCreateProjectContractor: false,
      isShowingDeleteProjectContractor: false,
      isAuthorized: true
    };
    
    this.fetchData = this.fetchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.setFailedMessage = this.setFailedMessage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
    this.hideNavBar = props.hideNavBar;

    this.page = 0;
    this.pageSize = 10;

    this.props.handleClickMenu('project', 'master');

    this.user = getObject('user');
    setObject('lastRoute', `project/${this.props.projectId}/contractor`);
  }

  handleFilterInput() {
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
  }

  handleCloseFilterInput() {
    this.filters = [
      {id: 'email', value: ''},
      {id: 'name', value: ''},
      {id: 'contractorName', value: ''}
    ];
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleSearch(idx, value) {
    this.filters[idx].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleBack() {
    this.setState({
        isBackToHome : true
    });
  }

  handleSection(redirectId) {
    this.setState({
      redirectId
    });
  }

  refreshTable(state) {
    const user = getObject('user');
    const size = state.pageSize;
    const page = parseInt(state.page) + 1;

    this.page = parseInt(state.page);
    this.pageSize = state.pageSize;

    const filtered = this.filters;

    let filterString = '';
    const filterXs = 
          R.pipe(
            R.filter(x => x.value !== ''),
            R.map(x => `${x.id}=${x.value}`)
          )(filtered);
    
    filterString = filterXs.join('&');

    const sortedXs = R.map(
      x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
      state.sorted || []);
    let sortedString = '&orderBy=id&orderDirection=desc';

    if(sortedXs.length > 0) {
        sortedString = sortedXs.join('&');
    }
    axios({
      url: `${config.api.project}/${this.props.projectId}/contractors?size=${size}&page=${page}&${filterString}&${sortedString}`,
      method: 'get',
      headers: { 'x-access-token': user.token }
    })
    .then(response => {
        const data = response.data.data;
        this.setState({
          data: data.results,
          pages: data.totalPage,
          loading: false
        });
      })
      .catch(err => {
        const statusCode = err.response.status;
        if(statusCode == 403) {
            this.setState({
                isAuthorized: false
            });
        }
      });
  }

  fetchData(state, instance) {
    this.clearMessage();
    this.setState({ loading: true });
    this.refreshTable(state);
  }

  handleAdd() {
    this.hideNavBar(true);
    this.setState({
      showAddModal: true
    });
  }

  handleEdit(editId) {
    this.hideNavBar(true);
    this.setState({
      showEditModal: true,
      editId
    });
  }

  handleCloseAddModal() {
    this.hideNavBar(false);
    this.setState({
      showAddModal: false
    });
  }

  handleCloseEditModal(id) {
    this.hideNavBar(false);
    this.setState({
      showEditModal: false
    });
  }

  handleDelete(deleteId) {
    this.setState({
      showDeleteModal: true,
      deleteId
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      showDeleteModal: false
    });
  }

  setSuccessMessage(successMessage) {
    this.setState({
      successMessage,
      failedMessage: ''
    });
  }

  setFailedMessage(failedMessage) {
    this.setState({
      failedMessage,
      successMessage: ''
    });
  }

  clearMessage() {
    this.setState({
      successMessage: '',
      failedMessage: ''
    });
  }

  async componentDidMount() {

    if(this.user) {
      const apiControlGetProjectContractorResult = await axios({
        url: `${config.api.apicontrols}/checking?route=/projects&method=GET&parameter=/:id/contractors`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      if(!apiControlGetProjectContractorResult.data.data.isAllowed) {
        this.setState({
          isShowingGetProjectContractor: false
        });
      }
  
      const apiControlCreateProjectContractorPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/projectcontractors&method=POST&parameter=/`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const apiControlDeleteProjectContractorPromise = axios({
        url: `${config.api.apicontrols}/checking?route=/projectcontractors&method=DELETE&parameter=/:id`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const getProjectByIdPromise = axios({
        url: `${config.api.project}/${this.props.projectId}`,
        method: 'get',
        headers: { 'x-access-token': this.user.token }
      });
  
      const [apiControlCreateProjectContractorResult,
             apiControlDeleteProjectContractorResult,
             getProjectByIdResult] = 
         await Promise.all([apiControlCreateProjectContractorPromise,
                            apiControlDeleteProjectContractorPromise, 
                            getProjectByIdPromise]);
  
      this.setState(() => {
          return {
              project: getProjectByIdResult.data.data,
              isShowingCreateProjectContractor: apiControlCreateProjectContractorResult.data.data.isAllowed,
              isShowingDeleteProjectContractor: apiControlDeleteProjectContractorResult.data.data.isAllowed
          };
      });
    }
  }

  componentWillUnmount() {
    if(this.timer){
        clearTimeout(this.timer);
    }
  }

  render() {

    if(!this.state.isAuthorized) {
      return <Redirect to="/"/>;
    }

    if(!this.user) {
      return <Redirect to="/login"/>;
    }

    if(!this.state.isShowingGetProjectContractor) {
      return <Redirect to="/"/>
    }

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  successMessage: ''
              });
          }, config.notifDuration);
    }

    const columns = [
      {
        Header: "Email",
        accessor: "email",
        sortable: true
      },
      {
          Header: "Full Name",
          accessor: "name",
          sortable: false
      },
      {
          Header: "Contractor Name",
          accessor: "contractorName",
          sortable: false
      },
      {
        Header: "Type",
        accessor: "newType",
        sortable: false
      },
      {
        Header: "Created date",
        accessor: "createdDate",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      }];

    if(this.state.isShowingDeleteProjectContractor) {
      columns.unshift({
        Header: "Action",
        accessor: 'id',
        width: 70,
        filterable: false,
        Cell: cellInfo => {
          const projectContractor = cellInfo.original;
          if(projectContractor.type === 'PROJECT_MONITOR') {
            return (
              <div>

              </div>
            );
          }
          return (
            <div className="row">
              <div className="col-md-auto">
                <button type="button" 
                        className="btn btn-sm" 
                        onClick={() => this.handleDelete(projectContractor.id)}>Delete</button>
              </div>
            </div>);
        }
      });
    }

    const { data, pages, loading } = this.state;
    var modalStyles = {overlay: {zIndex: 10}};
    const component = (
      <div>
        <br/>
        {this.state.successMessage
          ? <div className="alert alert-success" role="alert">
            {this.state.successMessage}
          </div>
          : null}
        {this.state.failedMessage
          ? <div className="alert alert-danger" role="alert">
            {this.state.failedMessage}
          </div>
          : null}
        <hr/>
        <h1>Contractor</h1>
        {this.state.project 
          ?
        <div className="card">
          <div className="card-body">
              <h2 class="card-title">Project</h2>
              <div className="row">
                  <div className="col">
                      <b>Project name : </b><br/>
                      <p>{this.state.project.projectName}</p>
                  </div>
                  <div className="col">
                      <b>Location : </b><br/>
                      <p>{this.state.project.location}</p>
                  </div>
                  <div className="col">
                      <b>Company : </b><br/>
                      <p>{this.state.project.company}</p>
                  </div>
                  {this.state.project.createdDate
                  ?
                  <div className="col">
                      <b>Created date: </b><br/>
                      <p>{convertToStd(this.state.project.createdDate)}</p>
                  </div>
                  :
                  null}
                  {this.state.project.updatedDate
                  ?
                  <div className="col">
                      <b>Updated date: </b><br/>
                      <p>{convertToStd(this.state.project.updatedDate)}</p>
                  </div>
                  :
                  null}
              </div>
          </div>
        </div>
        :
        null}
        <hr/>
        <div className="grid2-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
          <div className="grid-item">
            {this.state.isShowingCreateProjectContractor
            ?
            <button type="button" className="btn" onClick={this.handleAdd}><span className="fe fe-plus"> Assign Sub Contractor</span></button>
            :
            null}
          </div>
          {!this.state.showFiltering
          ?
          <div className="grid-item" style={{ justifySelf: "end" }}>
            <button type="button" className="btn" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
          </div>
          :
          null}
        </div>
        {this.state.showFiltering 
        ?
        <div>
          <br/>
          <br/>
          <div className="card">
            <div className="pull-right" style={{ marginRight: '10px'}}>
                <button type="button"  
                        className="pull-right clickable close-icon close" 
                        onClick={this.handleCloseFilterInput}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-auto">
                  <label><b>Email</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(0, e.target.value)} 
                        value={this.filters[0].value}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Name</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(1, e.target.value)} 
                        value={this.filters[1].value}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Contractor name</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(2, e.target.value)} 
                        value={this.filters[2].value}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        null}
        <ReactModal isOpen={this.state.showAddModal}
          contentLabel="Minimal Modal Example" style={modalStyles}>
          <ProjectContractorCreate
            closeModal={this.handleCloseAddModal}
            refreshTable={this.fetchData}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }}
            setSuccessMessage={this.setSuccessMessage}
            projectId={this.props.projectId}
            contractorNameId={this.state.project ? this.state.project.contractorNameId : null}/>
        </ReactModal>
        <ReactModal isOpen={this.state.showEditModal}
          contentLabel="Minimal Modal Example">
          <ProjectEdit
            closeModal={this.handleCloseEditModal}
            id={this.state.editId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }} />
        </ReactModal>
        <ReactModal isOpen={this.state.showDeleteModal}
          contentLabel="Minimal Modal Example"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }
          }}>
          <DeleteModal
            closeModal={this.handleCloseDeleteModal}
            id={this.state.deleteId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage}
            deleteUrl={config.api.projectcontractor} />
        </ReactModal>
        <ReactTable
          columns={columns}
          manual 
          data={data}
          pages={pages} 
          loading={loading} 
          onFetchData={this.fetchData} 
          filterable={false}
          defaultPageSize={10}
          className="-striped -highlight"
          sortable={false}
        />
        <br />
        <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
            <div className="col-md-12">
                <Link to={`/project`} 
                      class="btn"><span class="fe fe-arrow-left"> Back </span></Link>
            </div>
        </div>
      </div>
    );

    return component;
  }
}

export default ProjectContractor;