import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import Select from 'react-select';
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class UserCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            department: '',
            typeId: 'ADMIN_LOCATION',
            companyId: 0,
            password: '',
            reTypePassword: '',
            successMessage: '',
            failedMessage: '',
            isShowing: 'fe fe-eye-off',
            locationIds: []
        };

        this.handleEmail = this.handleEmail.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleDepartment = this.handleDepartment.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleTypeId = this.handleTypeId.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCompanyId = this.handleCompanyId.bind(this);
        this.handleClickShowingPassword = this.handleClickShowingPassword.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeLocations = this.handleChangeLocations.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;

        this.user = getObject('user');

        console.log('User = ', this.user);
        this.typeOptions = [
            {
                value: 'ADMIN_LOCATION',
                text: 'Admin location'
            }
        ];

        const userObj = this.user.user;
        if(userObj.type === "SUPER_ADMIN") {
            this.typeOptions.push({
                value: 'ADMIN',
                text: 'Admin'
            });
        }
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const companiePromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const contractorNamePromise = axios({
                url: `${config.api.contractorname}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
            
            const departmentsPromise = axios({
                url: `${config.api.department}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const locationsPromise = axios({
                url: `${config.api.location}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const [companiesResult,
                   contractorNameResult,
                   departmentsResult,
                   locationsResult] =
                   await Promise.all([
                        companiePromise,
                        contractorNamePromise,
                        departmentsPromise,
                        locationsPromise
                   ]);
    
    
            const companies = companiesResult.data.data.results;
            const contractorNames = contractorNameResult.data.data.results;
            const departments = departmentsResult.data.data.results;
            const locations = locationsResult.data.data.results;
    
            const company = companies.length > 0 ? companies[0] : null; 
            this.setState({
                companyOptions: companies,
                companyId: company ? company.id : null,
                companyAddress: company ? company.address : null,
                companyState: company ? company.state : null,
                companyPostcode: company ? company.postcode : null,
                companyPhoneNumber: company ? company.phoneNumber : null,
                companyCountry: company ? company.country : null,
                contractorNameOptions: contractorNames,
                contractorNameId: contractorNames.length > 0 ? contractorNames[0].id : null,
                departmentOptions: departments,
                departmentId: departments.length > 0 ? departments[0].id : null,
                locationOptions: locations,
                locationId: locations.length > 0 ? locations[0].id : null,
                locationValues: R.map(l => ({value: l.id, label: l.location}), locations)
            });
        } catch(e) {

        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }


    async handleChangeLocations(locations) {
        this.setState({
            locationIds: R.map(l => l.value, locations)
        });
    }


    handleClickShowingPassword(event) {
        const value = event.target.value;
        if(this.state.isShowing === 'fe fe-eye-off') {
            this.setState({
                isShowing: 'fe fe-eye'
            });
        } else {
            this.setState({
                isShowing: 'fe fe-eye-off'
            });
        }
    }


    async handleCompanyId(companyId) {
        try {

            this.setState({
                isActiveLoadingOverlay: true
            });

            const companyResult = await axios({
                url: `${config.api.company}/${companyId}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const company = companyResult.data.data
            this.setState({
                companyId,
                companyAddress: company.address,
                companyState: company.state,
                companyPostcode: company.postcode,
                companyPhoneNumber: company.phoneNumber,
                companyCountry: company.country
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleContractorNameId = (contractorNameId) => {
        this.setState({
            contractorNameId
        });
    }

    handleCloseModal() {

        this.closeModal();
    }

    handleEmail(email) {
        this.setState({
            email
        });
    }

    handleName(name) {
        this.setState({
            name
        });
    }

    handleDepartment(department) {
        this.setState({
            department
        });
    }

    handleTypeId(typeId) {
        this.setState({
            typeId
        });
    }

    handlePassword(password) {
        this.setState({
            password
        });
    }

    handleReTypePassword(reTypePassword) {
        this.setState({
            reTypePassword
        });
    }

    handleDepartmentId = (departmentId) => {
        this.setState({
            departmentId: parseInt(departmentId)
        });
    }

    handleLocationId = (locationId) => {
        this.setState({
            locationId: parseInt(locationId)
        });
    }

    handleChangeType(event) {
        const typeId = event.target.value;

        this.setState({typeId});
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const userAPI = config.api.user;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const data = {
                email: this.state.email, 
                password: this.state.password,
                type: this.state.typeId,
                name: this.state.name
            };
    
    
            if(this.user.user.type === 'ADMIN') {
                if(this.state.typeId === 'ADMIN_DEPARTMENT') {
                    data.companyId = this.user.user.companyId;
                    data.departmentId = this.state.departmentId;
                    data.locationIds = this.state.locationIds;
                    data.locationId = this.state.locationId;
                } 

                if(this.state.typeId === 'ADMIN_LOCATION') {
                    data.companyId = this.user.user.companyId;
                    data.locationId = this.state.locationId;
                }
        
                if(this.state.typeId === 'CONTRACTOR') {
                    data.contractorNameId = this.state.contractorNameId;
                }
            }
    
            if(this.user.user.type === 'SUPER_ADMIN') {
                data.companyId = this.state.companyId;
                data.type = 'ADMIN';
            }
    
            if(this.state.password !== this.state.reTypePassword) {
                this.setState({
                    failedMessage: "password doesn't match"
                })
            } else {
                console.log('data = ', data);
                const res = await axios.post(
                    userAPI,
                    data,
                    header);

                this.refreshTable({
                    page: this.props.tablePaging.page,
                    pageSize: this.props.tablePaging.pageSize,
                    filtered: []
                });
                this.props.setSuccessMessage('user has been created successfully')
                this.closeModal();
            }
        } catch(e) { 
            const data = e.response.data;
            console.log(data);
            if (data.code === 400) {
                this.setState({
                    failedMessage: data.message
                });
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                {
                    this.user.user.type === 'SUPER_ADMIN'
                    ?
                    <div className="form-group">
                        <label><b>Type</b></label>
                        <p>Admin</p>
                    </div>
                    :
                    null
                }
                {
                    this.user.user.type === 'ADMIN'
                    ?
                    <div className="form-group">
                        <label><b>Type</b></label>
                        <p><b>Admin location</b></p>
                    </div>
                    :
                    null
                }
                <div className="form-group">
                    <label><b>Email</b></label>
                    <input 
                        type="email"
                        className="form-control"
                        onChange={(event) => this.handleEmail(event.target.value)}/>
                </div>
                <div className="form-group">
                    <label><b>Full Name</b></label>
                    <input 
                        type="text"
                        className="form-control"
                        onChange={(event) => this.handleName(event.target.value)}/>
                </div>
                {/* {
                    this.user.user.type === 'ADMIN' 
                    ?
                    <div className="form-group">
                        <label><b>Type</b></label>
                        <select onChange={event => this.handleTypeId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control">
                                {R.map(
                                    x => <option value={x.value}>{x.text}</option>,
                                    this.typeOptions)}
                        </select>
                    </div>
                    :
                    null
                } */}
                {
                    this.user && this.user.user.type === 'SUPER_ADMIN'
                    ?
                    <div>
                        <div className="form-group">
                            <label><b>Company</b></label>
                            <select onChange={event => this.handleCompanyId(event.target.value)}
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    defaultValue={this.state.companyId}>
                                    {R.map(
                                        x => <option value={x.id}>{x.company}</option>,
                                        this.state.companyOptions || [])}
                            </select>
                        </div>
                        {this.state.companyAddress 
                        ?
                        <div className="form-group">
                            <label><b>Company Address</b></label>
                            <p>{this.state.companyAddress}</p>
                        </div>
                        :
                        null}
                        {this.state.companyState 
                        ?
                        <div className="form-group">
                            <label><b>Company State</b></label>
                            <p>{this.state.companyState}</p>
                        </div>
                        :
                        null}
                        {this.state.companyPostcode 
                        ?
                        <div className="form-group">
                            <label><b>Company postcode</b></label>
                            <p>{this.state.companyPostcode}</p>
                        </div>
                        :
                        null}
                        {this.state.companyPhoneNumber 
                        ?
                        <div className="form-group">
                            <label><b>Company Phone Number</b></label>
                            <p>{this.state.companyPhoneNumber}</p>
                        </div>
                        :
                        null}
                        {this.state.companyCountry 
                        ?
                        <div className="form-group">
                            <label><b>Company Country</b></label>
                            <p>{this.state.companyCountry}</p>
                        </div>
                        :
                        null}
                    </div>
                    :
                    null
                }
                {
                    this.state.typeId === 'CONTRACTOR'
                    ?
                    <div className="form-group">
                        <label><b>Contractor Name</b></label>
                        <select onChange={event => this.handleContractorNameId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                defaultValue={this.state.contractorNameId}>
                                {R.map(
                                    x => <option value={x.id}>{x.contractorName}</option>,
                                    this.state.contractorNameOptions || [])}
                        </select>
                    </div>
                    :
                    null
                }
                {this.user 
                && this.user.user.type === 'ADMIN' 
                && (this.state.typeId === 'INSPECTOR'
                    || this.state.typeId === 'ADMIN_LOCATION'
                    || this.state.typeId === 'ADMIN_DEPARTMENT')
                ?
                <div className="form-group">
                    <label><b>Main Location</b></label>
                    <select onChange={event => this.handleLocationId(event.target.value)}
                            style={{ width: "100%" }}
                            className="form-control"
                            defaultValue={this.state.locationId}>
                            {R.map(
                                x => <option value={x.id}>{x.location}</option>,
                                this.state.locationOptions || [])}
                    </select>
                </div>
                :
                null}
                {this.user 
                && this.user.user.type === 'ADMIN' 
                && (this.state.typeId === 'INSPECTOR'
                    || this.state.typeId === 'ADMIN_DEPARTMENT')
                ?
                <div className="form-group">
                    <label><b>Other locations</b></label>
                    <Select
                        onChange={this.handleChangeLocations}
                        options={R.filter(locationValue => locationValue.value !== this.state.locationId,
                                          this.state.locationValues)}
                        placeholder="Please select location(s)"
                        isMulti={true}/>
                </div>
                :
                null}
                {this.user 
                && this.user.user.type === 'ADMIN' 
                && (this.state.typeId === 'ADMIN_DEPARTMENT')
                ?
                <div className="form-group">
                    <label><b>Department</b></label>
                    <select onChange={event => this.handleDepartmentId(event.target.value)}
                            style={{ width: "100%" }}
                            className="form-control"
                            defaultValue={this.state.departmentId}>
                            {R.map(
                                x => <option value={x.id}>{x.department}</option>,
                                this.state.departmentOptions || [])}
                    </select>
                </div>
                :
                null}
                <div className="form-group">
                    <label><b>Password</b></label>
                    <div className="row">
                        <div className="col-md-11">
                        <input 
                            type={this.state.isShowing === "fe fe-eye" ? "text" : "password"}
                            className="form-control"
                            onChange={(event) => this.handlePassword(event.target.value)} />
                        </div>
                        <div className="col-md-1">
                            <button class="btn" 
                                    type="button" 
                                    title={this.state.isShowing === "fe fe-eye" ? "Hide password" : "Show password"}
                                    onClick={this.handleClickShowingPassword}><span className={this.state.isShowing}></span></button>
                        </div>     
                    </div>
                </div>
                <div className="form-group">
                    <label><b>Re type password</b></label>
                    <input 
                        type="password"
                        className="form-control"
                        onChange={(event) => this.handleReTypePassword(event.target.value)} />
                </div>
                {this.state.failedMessage
                ? <div className="alert alert-danger" role="alert">
                    {this.state.failedMessage}
                 </div> 
                : null}
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default UserCreate;