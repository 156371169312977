import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject, setSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';

class SectionTemplateCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            scoringType: 'CUMULATIVE',
            findingType: 'MAJOR',
            sectionId: 0,
            isSuccess: false,
            failedMessage: '',
            isDeleted: false,
            redirectTo: '',
            comment: ''
        };

        this.templateId = props.templateId;
        this.handleTitle = this.handleTitle.bind(this);
        this.handlePoint = this.handlePoint.bind(this);
        this.handleScoringType = this.handleScoringType.bind(this);
        this.handleFindingType = this.handleFindingType.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleComment = this.handleComment.bind(this);
        this.closeModal = props.closeModal;
    }

    handleTitle(title) {
        this.setState({
            title
        });
    }

    handleComment(event) {
        const comment = event.target.value;
        this.setState({
            comment
        });
    }

    handleScoringType(scoringType) {
        this.setState({
            scoringType
        });
    }

    handleFindingType(findingType) {
        this.setState({
            findingType
        });
    }

    handlePoint(point) {
        this.setState({
            point
        });
    }

    handleSave() {
        const user = getObject('user');
        const inspectionTemplateAPI = config.api.sectiontemplate;

        const header = {
            headers: { 'x-access-token': user.token }
        };
        axios.post(
            inspectionTemplateAPI,
            { title: this.state.title, 
              scoringType: this.state.scoringType,
              point: this.state.point, 
              templateId: this.templateId,
              findingType: this.state.findingType,
              comment: this.state.comment },
            header)
            .then(res => {
                const section = res.data.data;

                this.props.hideNavBar(false);
                setSuccessMessage('section template has been created successfully');
                this.props.history.push(`/inspectiontemplate/${this.templateId}/sectiontemplate/${section.id}/questiontemplate`);
            }).catch(e => {
                const data = e.response.data;
                if (data.code === 400) {
                    this.setState({
                        failedMessage: data.message
                    });
                }
            });
    }

    render() {
        return (
            <div>
                <div className="pull-right" style={{marginRight: '10px'}}>
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Title</label>
                        <input 
                            type="text"
                            className="form-control"
                            defaultValue={this.state.title}
                            onChange={(event) => this.handleTitle(event.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Scoring Type</label>
                        <select className="form-control" 
                                onChange={event => this.handleScoringType(event.target.value)}>
                            <option value="CUMULATIVE" selected={this.state.scoringType === "CUMULATIVE"}>Cumulative</option>
                            <option value="ALL_OR_NOTHING" selected={this.state.scoringType === "ALL_OR_NOTHING"}>All or nothing</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Point</label>
                        <input 
                            type="number"
                            className="form-control"
                            defaultValue={this.state.point}
                            onChange={(event) => this.handlePoint(event.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Finding Type</label>
                        <select className="form-control" 
                                onChange={event => this.handleFindingType(event.target.value)}>
                            <option value="MAJOR" selected={this.state.findingType === "MAJOR"}>Major</option>
                            <option value="MINOR" selected={this.state.findingType === "MINOR"}>Minor</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Comment</label>
                        <textarea 
                            className="form-control"
                            onChange={this.handleComment}
                            value={this.state.comment}
                            rows={10}>
                        </textarea>
                    </div>
                </form>
                {this.state.failedMessage ?
                    <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                    </div> :
                    null
                }
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(SectionTemplateCreate);