import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import config from '../config';
import {Bar} from 'react-chartjs-2';
import moment from 'moment';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import * as R from 'ramda';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from 'react-select';
import ReactTable from "react-table";
import {convertToStd} from '../utils/date';

var showZeroPlugin = {
  beforeRender: function (chartInstance) {
      var datasets = chartInstance.config.data.datasets;

      for (var i = 0; i < datasets.length; i++) {
          var meta = datasets[i]._meta;
          // It counts up every time you change something on the chart so
          // this is a way to get the info on whichever index it's at
          var metaData = meta[Object.keys(meta)[0]];
          var bars = metaData.data;

          for (var j = 0; j < bars.length; j++) {
              var model = bars[j]._model;

              if (metaData.type === "horizontalBar" && model.base === model.x) {
                  model.x = model.base + 2;
              } else if (model.base === model.y) {
                  model.y = model.base - 2;
              }
          }
      }

  }
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


class Home extends Component {
  constructor(props) {
    super(props);
    this.user = getObject('user');

    this.props.handleClickMenu('dashboard');
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleDateFilterShortcut = this.handleDateFilterShortcut.bind(this);
    this.loadContractorNames = this.loadContractorNames.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.filters = [];
    this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    this.toDate = moment().format("YYYY-MM-DD");
    this.templateId = 'ALL';
    this.projectId = 'ALL';
    this.locationId = 'ALL';
    this.preparedBy = 'ALL';
    this.assignedContractorNameId = 'ALL';

    this.page = 0;
    this.pageSize = 10;
    setObject('lastRoute', '/');
    this.props.setActiveLoadingOverlay(true);
    this.state = {
      totalOpenItem: null,
      totalInspection: null,
      isFilterDateShowing: false,
      data: [],
      pages: null,
      loading: true,
    };

  }

  async refreshTable(state) {
    try {
      this.props.setActiveLoadingOverlay(true);
      let manHour = {
        totalWorker: 0,
        totalHour: 0,
        type: 'ALL'
      }
      const user = getObject('user');
      const size = state.pageSize;
      const page = parseInt(state.page) + 1;
  
      this.page = parseInt(state.page);
      this.pageSize = state.pageSize;
  
      const filtered = this.filters;
  
      let filterString = '';
      const filterXs = 
            R.pipe(
              R.filter(x => x.value !== ''),
              R.map(x => `${x.id}=${x.value}`)
            )(filtered);
      
      filterString = filterXs.join('&');
  
      const sortedXs = R.map(
        x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
        state.sorted || []);
      let sortedString = '&orderBy=date&orderDirection=asc';
  
      if(sortedXs.length > 0) {
          sortedString = sortedXs.join('&');
      }
  
      const response = await axios({
        url: this.generateUrl(`${config.api.inspection}/point/`) + `&page=${page}&size=${size}`,

        method: 'get',
        headers: { 'x-access-token': user.token }
      });

      const data = response.data.data;
      this.setState({
        data: data.results,
        pages: data.totalPage,
        totalHours: data.totalHour,
        totalWorkers: data.totalWorker,
        loading: false
      });

    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }


  async fetchData(state, instance) {
    await this.refreshTable(state, instance);
  }

  async handleDateFilterShortcut(data) {
    const today = moment().format("YYYY-MM-DD");
    if(data === 'LAST_WEEK') {
      
      this.fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
      this.toDate = today;
      
      const state = await this.loadGraphAndCounter();
      this.setState({
        isFilterDateShowing: false,
        ...state
      });
    } else if(data === "LAST_2_WEEK") {
      
      this.fromDate = moment().subtract(14, 'days').format("YYYY-MM-DD");
      this.toDate = today;
      
      const state = await this.loadGraphAndCounter();
      this.setState({
        isFilterDateShowing: false,
        ...state
      });
    } else if(data === "LAST_30_DAYS") {
      this.fromDate = moment().subtract(30, 'days').format("YYYY-MM-DD");
      this.toDate = today;
      
      const state = await this.loadGraphAndCounter();
      
      this.setState({
        isFilterDateShowing: false,
        ...state
      });
    } else if(data === 'NONE') {
      this.setState({
        isFilterDateShowing: true
      });
    }
  }

  generateUrl = (url) => {
    const fullUrl = `${url}?fromDate=${this.fromDate}&toDate=${this.toDate}&templateId=${this.templateId}&projectId=${this.projectId}&locationId=${this.locationId}&preparedBy=${this.preparedBy}&contractorNameId=${this.assignedContractorNameId}`;
    return fullUrl;
  }

  async countOpenItemByDay() {
    const url = this.generateUrl(`${config.api.openitems}/countbyday`);
    
    return axios({
      url,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });
  }

  async countInspection() {
    const url = this.generateUrl(`${config.api.inspection}/count`);  

    return axios({
      url,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });    
  }

  async countClosedItem() {
    const url = this.generateUrl(`${config.api.openitems}/count/closed`);  

    return axios({
      url,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });    
  }

  async countOpenItem() {
    const url = this.generateUrl(`${config.api.openitems}/count`);  

    return axios({
      url,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });
  }

  async countAverageScore() {
    const url = this.generateUrl(`${config.api.inspection}/averagepoint`);  

    return axios({
      url,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });
  }

  async loadContractorNames() {
    const contractorNamesResult = await axios({
      url: `${config.api.contractorname}/${this.locationId}`,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });

    const result = contractorNamesResult.data.data.results;

    const finalResult = R.map(x => ({value: x.id, label: x.contractorName}), result); 
    console.log('final result = ', finalResult);
    return finalResult;
  }

  async componentDidMount() {
    try {
      if(this.user) {
        // const inspectionTemplatesPromise = axios({
        //   url: `${config.api.inspectiontemplate}`,
        //   method: 'get',
        //   headers: { 'x-access-token': this.user.token }
        // });
  
        const projectsPromise = axios({
          url: `${config.api.project}/multilocations`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });
  
        // const contractorsPromise = axios({
        //   url: `${config.api.user}/contractors`,
        //   method: 'get',
        //   headers: { 'x-access-token': this.user.token }
        // });

        const locationsPromise = axios({
          url: `${config.api.location}`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });

        const inspectorsPromise = axios({
          url: `${config.api.user}/inspectors`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });
  
        const [projectsResult, locationsResult, inspectorsResult] = await Promise.all([
          projectsPromise, locationsPromise, inspectorsPromise
        ]);
  
        const projects = projectsResult.data.data.results;
        const locations = locationsResult.data.data.results;
        const inspectors = inspectorsResult.data.data;
  
        if(this.user && this.user.user.type === 'ADMIN' || this.user.user.type === 'SUPER_ADMIN') {
          console.log('locations = ', locations);
          this.locationId = locations.length > 0 ? locations[0].id : 0;
 
          this.locationValue = locations.length > 0 ? {value: locations[0].id, label: locations[0].location} : {};
        } else {
          this.locationId = this.user.user.locationId;
        }

        const contractorNames = await this.loadContractorNames();
        const state = await this.loadGraphAndCounter();
        this.setState({
          projects: R.map(x => ({ value: x.id, 
                                  label: x.projectName}), 
                          projects),
          locations: R.map(x => ({
                                value: x.id,
                                label: x.location
                             }),
                             locations),
          inspectors: R.map(x => ({
                                value: x.id,
                                label: x.name
                            }),
                            inspectors),
          contractorNames,
          ...state
        });
      }
    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
   
  async handleFromChange(from) {
    this.fromDate = moment(from).format('YYYY-MM-DD');
    if(this.toDate) {
      const state = await this.loadGraphAndCounter();
      this.setState(state);
    } 
  }

  async loadGraphAndCounter() {
    try {
      this.props.setActiveLoadingOverlay(true);
      const [openItemResult, openItemByDayResult, inspectionResult, closedItemResult, averageScoreResult, refreshTable] = await Promise.all([
        this.countOpenItem(),
        this.countOpenItemByDay(),
        this.countInspection(),
        this.countClosedItem(),
        this.countAverageScore(),
        this.refreshTable({
          page: this.page,
          pageSize: this.pageSize
        })
      ]);
  
      return {
        totalOpenItem: openItemResult.data.data.total,
        currentInspectionFinding: openItemByDayResult.data.data,
        totalInspection: inspectionResult.data.data.total,
        totalClosedItem: closedItemResult.data.data.total,
        totalAverageScore: averageScoreResult.data.data.total,
        refreshTable
      }; 
    } finally {
      this.props.setActiveLoadingOverlay(false);
    }
  }

  async handleToChange(to) {
    this.toDate = moment(to).format('YYYY-MM-DD');
    let state;
    if(this.fromDate) {
      this.showFromMonth();
      state = await this.loadGraphAndCounter();
    } else {
      this.showFromMonth();
      state = await this.loadGraphAndCounter();
    }
    this.setState(state);
  }

  handleChangeInspectionTemplate = async ({value, label}) => {
    this.templateId = value ? value : 'ALL';
    const state = await this.loadGraphAndCounter();
    this.setState(state);
  }

  handleChangeProject = async ({value, label}) => {
    this.projectId = value ? value : 'ALL';
    const state = await this.loadGraphAndCounter();
    this.setState(state);
  }

  handleChangeContractor = async ({value, label}) => {
    this.locationId = value ? value : 'ALL';
    this.locationValue = {value, label}; 
    const contractorNames = await this.loadContractorNames();
    const state = await this.loadGraphAndCounter();
    this.setState({...state, contractorNames});
  }

  handleChangeContractorName = async ({value, label}) => {
    this.assignedContractorNameId = value ? value : 'ALL';
    const state = await this.loadGraphAndCounter();
    this.setState(state);
  }

  handleChangeInspector = async ({value, label}) => {
    this.preparedBy = value ? value : 'ALL';
    const state = await this.loadGraphAndCounter();
    this.setState(state);
  }
  
  render() {
    if(!this.user) {
      return <Redirect to="/login" />;
    }

    const { data, pages, loading } = this.state;

    const columns = [
      {
        Header: "Submission date",
        accessor: "submissionDate",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      },
      {
        Header: "Inspection name",
        accessor: "name"
      },
      {
          Header: "Project",
          accessor: "projectName"
      },
      {
        Header: "Inspector",
        accessor: "inspector"
      },
      {
        Header: "Location",
        accessor: "location"
      },
      {
        Header: "Type",
        accessor: "selfContractorName",
        Cell: cellInfo => {
          if(cellInfo.value) {
            return 'External inspector';
          }
          return 'Internal inspector';
        }
      },
      {
        Header: "Contractor name",
        accessor: "contractorName"
      },
      {
        Header: "Score",
        accessor: "score"
      }
    ];

    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const datasets = [];

    console.log('current inspection finding = ', this.state.currentInspectionFinding);
    return (
      <div className="justify-content-center">
      { this.user ? null : <Redirect to="/login" /> }
      <br/>
      <br/>
      <div className="row d-flex justify-content-center">
        <div className="col-md">
            <label><b>Choose inspector:</b></label>
            <Select
                onChange={this.handleChangeInspector}
                options={this.state.inspectors}
            />
        </div>
        {this.user
        && this.user.user.type === 'ADMIN' 
        || this.user.user.type === 'SUPER_ADMIN'
        || this.user.user.type === 'ADMIN_DEPARTMENT'
        ?
        <div className="col-md">
          <label><b>Choose location: </b></label>
          <Select
              onChange={this.handleChangeContractor}
              options={this.state.locations}
              value={this.locationValue}
              />
        </div>
        :
        null}
        {this.user
        && this.user.user.type != 'CONTRACTOR'
        && this.user.user.type != 'PROJECT_MONITOR'
        ?
        <div className="col-md">
          <label><b>Choose contractor name: </b></label>
          <Select
              onChange={this.handleChangeContractorName}
              options={this.state.contractorNames}
              />
        </div>
        :
        null}

        {/* <div className="col-md">
          <label><b>Choose template: </b></label>
          <Select
              onChange={this.handleChangeInspectionTemplate}
              options={this.state.inspectionTemplates}
          />
        </div> */}
        <div className="col-md">
          <label><b>Choose project: </b></label>
          <Select
              onChange={this.handleChangeProject}
              options={this.state.projects}
          />
        </div>
      </div>
      <br/>
      <div className="row d-flex justify-content-center">
      <div className="col-auto">
          <div className="InputFromTo">
          <label><b>Choose date : </b></label>
          <select className="form-control"  
                  onChange={event => this.handleDateFilterShortcut(event.target.value)}>
            <option value="LAST_WEEK">Last week</option>
            <option value="LAST_2_WEEK">Last 2 weeks</option>
            <option value="LAST_30_DAYS">Last 30 days</option>
            <option value="NONE">Custom</option>
          </select>
          <br/>
          <div style={{display: this.state.isFilterDateShowing ? 'inline-block' : 'none'}}>
            <DayPickerInput
              value={from}
              placeholder="From"
              format="LL"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { after: to },
                toMonth: to,
                modifiers,
                numberOfMonths: 1,
                onDayClick: () => this.to.getInput().focus(),
              }}
              onDayChange={this.handleFromChange}
            />{' '}
            —{' '}
            <span className="InputFromTo-to">
              <DayPickerInput
                ref={el => (this.to = el)}
                value={to}
                placeholder="To"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { before: from },
                  modifiers,
                  month: from,
                  fromMonth: from,
                  numberOfMonths: 1,
                }}
                onDayChange={this.handleToChange}
              />
            </span>
        </div>
        <Helmet>
          <style>{`
          .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
          }
          .InputFromTo .DayPicker-Day {
            border-radius: 0 !important;
          }
          .InputFromTo .DayPicker-Day--start {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
          }
          .InputFromTo .DayPicker-Day--end {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
          }
          .InputFromTo .DayPickerInput-Overlay {
            width: 550px;
          }
          .InputFromTo-to .DayPickerInput-Overlay {
            margin-left: -198px;
          }
          `}</style>
              </Helmet>
            </div>
        </div>
      </div>
      <br/>
      <div className="row d-flex justify-content-center">
        <div className="col-md-3">
          <div class="card" style={{marginTop: '10px'}}>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-2">
                    Inspection
                  </h6>
                  <span class="h2 mb-0">
                    {this.state.totalInspection}
                  </span>

                </div>
                <div class="col-auto">
                  <i class="fe fe-flag"></i>
                </div>
              </div>
            </div>
          </div>
        </div>      
        <div className="col-md-3">
          <div class="card" style={{marginTop: '10px'}}>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-2">
                    Open item
                  </h6>

                  <span class="h2 mb-0">
                    {this.state.totalOpenItem}
                  </span>

                </div>
                <div class="col-auto">
                  <i class="fe fe-flag"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div class="card" style={{marginTop: '10px'}}>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-2">
                    Closed item
                  </h6>

                  <span class="h2 mb-0">
                    {this.state.totalClosedItem}
                  </span>

                </div>
                <div class="col-auto">
                  <i class="fe fe-flag"></i>
                </div>
              </div>
            </div>
          </div>
        </div>      
        <div className="col-md-3">
          <div class="card" style={{marginTop: '10px'}}>
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-2">
                    Average score
                  </h6>

                  <span class="h2 mb-0">
                    {this.state.totalAverageScore}
                  </span>

                </div>
                <div class="col-auto">
                  <i class="fe fe-flag"></i>
                </div>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div className="d-flex justify-content-center">
      <ul className="nav nav-tabs" id="myTab" role="tablist">                    
        <li className="nav-item">
            <a className={"nav-link active"} id="userAdmin-tab" data-toggle="tab" href="#userAdmin" role="tab" aria-controls="userAdmin" aria-selected={"true"}>Chart</a>
        </li>
        
        <li className="nav-item">
            <a className={"nav-link"} id="userAdminLocation-tab" data-toggle="tab" href="#userAdminLocation" role="tab" aria-controls="userAdminLocation" aria-selected={"true"}>Score</a>
        </li>
      </ul>

      </div>
      <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="userAdmin" role="tabpanel" aria-labelledby="userAdmin-tab">
            <br/>
            {R.map(
            inspectionFinding => (     
            <Bar data={{
                    labels: this.state.currentInspectionFinding.labels,
                    datasets: [inspectionFinding.company, inspectionFinding.contractor]
                }}
                plugins={[showZeroPlugin]}
                options= {{
                    title: {
                      display: true,
                      text: `Inspection Findings : ${inspectionFinding.company.label} vs ${inspectionFinding.contractor.label}`
                    },
                    scales:{ 
                      yAxes: [{
                        ticks: {
                          beginAtZero: true,
                          callback: function(value) {if (value % 1 === 0) {return value;}}
                        }
                      }]
                    }
                  }
              }/>), 
            this.state.currentInspectionFinding ? this.state.currentInspectionFinding.data : [])}
          </div>
          <div className={"tab-pane fade"} id="userAdminLocation" role="tabpanel" aria-labelledby="userAdminLocation-tab">
          <br/>
          <ReactTable
            columns={columns}
            manual 
            data={data}
            pages={pages} 
            loading={loading} 
            onFetchData={this.fetchData} 
            filterable={false}
            sortable={false}
            defaultPageSize={10}
            className="-striped -highlight"
          />
          </div>
      </div>
      <br/>
    </div>
    );
  }
}

export default Home;