import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import DateTime from 'react-datetime';
import { setObject, getObject, setSuccessMessage } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { now } from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class D12IncidentCreate extends Component {
    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
        this.totalCard = 1;
        this.selectedProjectId = 0;
        this.selectedTemplateId = 0;
        this.user = getObject('user');

        this.state = {
            projectValue: '',
            projectName: '',
            inspectionName: '',
            dateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            location: '',
            projectSuggestions: [],
            templateSuggestions: [],
            inspectionTemplates: [],
            projects: [],
            templateValue: '',
            templateDescription: '',
            failedMessage: ''
        };

        this.getProjectSuggestions = async value => {
            const result = await axios({
                url: `${config.api.project}?projectName=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedProjectId = 0;
            this.setState({
                location: '',
                companyName: ''
            });
            return data.results;
        };

        this.getTemplateSuggestions = async value => {
            const result = await axios({
                url: `${config.api.inspectiontemplate}?title=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedTemplateId = 0;
            this.setState({
                templateDescription: ''
            });
            return data.results;
        };
 
        this.getProjectSuggestionValue = suggestion => {
            this.selectedProjectId = suggestion.id;
            this.setState({
                location: suggestion.location,
                companyName: suggestion.company
            });
            return suggestion.projectName;
        };

        this.getTemplateSuggestionValue = suggestion => {
            this.selectedTemplateId = suggestion.id;
            this.setState({
                templateDescription: suggestion.description
            });
            return suggestion.title;
        };

        this.renderProjectSuggestion = suggestion => (
            <span>{suggestion.projectName}</span>);

        this.renderTemplateSuggestion = suggestion => (
                <span>{suggestion.title}</span>);

        this.handleInspectionName = this.handleInspectionName.bind(this);
    }
    
    handleDateTime = (value) => {
        this.setState({
            dateTime: value.format("YYYY-MM-DD HH:mm:ss")
        });
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const companyPromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const severityPromise = axios({
                url: `${config.api.severity}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const stop6Promise = axios({
                url: `${config.api.stop6}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const [companyResult, severityResult, stop6Result] = 
                await Promise.all([companyPromise, severityPromise, stop6Promise]);
            
                const companies = companyResult.data.data.results;    
                const severities = severityResult.data.data.results;    
                const stop6 = stop6Result.data.data.results;

            this.setState({
                companies : R.map(x => ({ 
                                    value: x.id, 
                                    label: x.company
                                  }), 
                                  companies),
                severities: R.map(x => ({
                                    value: x.id,
                                    label: x.severity
                                  }), 
                                  severities),
                stop6: R.map(x => ({
                                value: x.id,
                                label: x.stop6
                             }),
                             stop6)
            });
        } catch(e) {

        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    }

    handleChangeCompany = async ({value, label}) => {
        this.setState({
            companyValue: value,
        });
    }

    handleChangeSeverity = async ({value, label}) => {
        this.setState({
            severityValue: value,
        });
    }

    handleChangeStop6 = async ({value, label}) => {
        this.setState({
            stop6Value: value,
        });
    }

    handleInspectionName(inspectionName) {
        this.setState({
            inspectionName
        });
    }

    onProjectSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getProjectSuggestions(value);
        this.setState({
            projectSuggestions: values
        });
    };

    onTemplateSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getTemplateSuggestions(value);
        this.setState({
            templateSuggestions: values
        });
    };

    onProjectSuggestionsClearRequested = () => {
        this.setState({
          projectSuggestions: []
        });
    };

    onTemplateSuggestionsClearRequested = () => {
        this.setState({
          templateSuggestions: []
        });
    };

    onProjectChange = (event, { newValue }) => {
        this.setState({
          projectValue: newValue
        });
    };

    handleChangeInjury = async (event) => {
        const injury = event.target.value;
        this.setState({
            injury,
        });
    }

    handleChangeActivity = async (event) => {
        const activity = event.target.value;
        this.setState({
            activity,
        });
    }

    handleChangeDescription = async (event) => {
        const description = event.target.value;
        this.setState({
            description,
        });
    }

    onTemplateChange = async (event) => {
        const templateValue = event.target.value;
        
        const inspectionTemplateResult = await axios({
            url: `${config.api.inspectiontemplate}/${templateValue}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        });

        const inspectionTemplate = inspectionTemplateResult.data.data;
        this.setState({
            templateValue,
            templateDescription: inspectionTemplate.description
        });  
    };

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const d12incidentAPI = config.api.d12incident;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const data = {
                severityId: this.state.severityValue,
                stop6Id: this.state.stop6Value,
                dateTime: this.state.dateTime,
                injury: this.state.injury,
                activity: this.state.activity,
                description: this.state.description
            };
    
            const res = await axios.post(d12incidentAPI,
                       data,
                       header)
            this.props.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('d12incident has been added successfully');
            const dataResult = res.data.data;
            setSuccessMessage('d12incident has been added successfully');
            this.props.hideNavBar(false);
            this.props.closeModal();
        } catch(e) {
            const data = e.response.data;
            if (data.code === 400) {
                this.setState({
                    failedMessage: data.message
                });
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }

    }

    handleDateTime = (value) => {
        this.setState({
            dateTime: value.format("YYYY-MM-DD HH:mm:ss")
        });
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div>
                    <form>
                        <div className="form-group">
                            <label><b>Date time</b></label>
                            <DateTime onChange={this.handleDateTime} defaultValue={now()}/>
                        </div>
                        <div className="form-group">
                            <label><b>Severity</b></label>
                            <Select
                                onChange={this.handleChangeSeverity}
                                options={this.state.severities}/>
                        </div>
                        <div className="form-group">
                            <label><b>Stop 6</b></label>
                            <Select
                                onChange={this.handleChangeStop6}
                                options={this.state.stop6}/>
                        </div>
                        <div className="form-group">
                            <label><b>Injury</b></label>
                            <input type="text"
                                className="form-control"
                                onChange={this.handleChangeInjury}/>
                        </div>
                        <div className="form-group">
                            <label><b>Activity</b></label>
                            <input type="text"
                                className="form-control"
                                onChange={this.handleChangeActivity}/>
                        </div>
                        <div className="form-group">
                            <label><b>Description</b></label>
                            <input type="text"
                                className="form-control"
                                onChange={this.handleChangeDescription}/>
                        </div>
                    </form>
                    {this.state.failedMessage 
                        ? <div className="alert alert-danger" role="alert">
                                {this.state.failedMessage}
                            </div> 
                        : null}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default withRouter(D12IncidentCreate);