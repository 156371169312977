import React, { Component, Fragment  } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import { getBase64 } from '../utils/file';
import * as R from 'ramda'
import config from '../config';
import ImageUploader from 'react-images-upload';
import ReactDropzone from  'react-dropzone';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class QuestionOpenItemEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: '',
            answer: '',
            note: '',
            openItem: {},
            nonConformingResponse: '',
            failedMessage: '',
            contributingFactor: 0,
            escalationProcessId: 0,
            escalationProcessDescription: '',
            immediatelyFixed: null,
            contributingFactors: [],
            escalationProcesses: [],
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image1Preview: null,
            image2Preview: null,
            image3Preview: null,
            image4Preview: null,
            image1Url: null,
            image2Url: null,
            image3Url: null,
            image4Url: null,
            isLoading: false
        };

        this.handleAnswer = this.handleAnswer.bind(this);
        this.handleNote = this.handleNote.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleContributingFactor = this.handleContributingFactor.bind(this);
        this.handleEscalationProcess = this.handleEscalationProcess.bind(this);
        this.handleImmediatelyFixed = this.handleImmediatelyFixed.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
        this.handleChangeImage = this.handleChangeImage.bind(this);
        this.image1 = null;
        this.onDrop1 = this.onDrop1.bind(this);
        this.onDrop2 = this.onDrop2.bind(this);
        this.onDrop3 = this.onDrop3.bind(this);
        this.onDrop4 = this.onDrop4.bind(this);
        this.onCloseImage1 = this.onCloseImage1.bind(this);
        this.onCloseImage2 = this.onCloseImage2.bind(this);
        this.onCloseImage3 = this.onCloseImage3.bind(this);
        this.onCloseImage4 = this.onCloseImage4.bind(this);
        this.readImage = this.readImage.bind(this);
    }


    readImage(pictureFiles, onLoadEndSetState) {
        const reader = new FileReader();
        reader.readAsDataURL(pictureFiles[0]);

        reader.onloadend = () => {
            this.setState(() => onLoadEndSetState(reader.result, pictureFiles.length === 0 ? null : pictureFiles[0]));
        }
    }

    handleContributingFactor(event) {
        const contributingFactor = event.target.value;
        this.setState({
            contributingFactor
        });
    }

    handleImmediatelyFixed(immediatelyFixed) {
        this.setState({
            immediatelyFixed
        });
    }

    async handleEscalationProcess(event) {
        try {
            const escalationProcessId = event.target.value;
            
            let escalationProcess = {
                description: null
            };

            if(escalationProcessId != 0) {
                const escalationProcessData = await axios({
                    url: `${config.api.escalationprocess}/${escalationProcessId}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
                escalationProcess = escalationProcessData.data.data;
            }
    
            this.setState({
                escalationProcessId,
                escalationProcessDescription: escalationProcess.description
            });

        } catch(e) {

        }
    }

    onCloseImage1(e) {
        e.preventDefault();
        this.setState({
            image1Url: null,
            isImage1Present: false
        });
    }

    onCloseImage2(e) {
        e.preventDefault();
        this.setState({
            image2Url: null,
            isImage2Present: false
        });
    }

    onCloseImage3(e) {
        e.preventDefault();
        this.setState({
            image3Url: null,
            isImage3Present: false
        });
    }

    onCloseImage4(e) {
        e.preventDefault();
        this.setState({
            image4Url: null,
            isImage4Present: false
        });
    }

    onDrop1(pictureFiles) {
        this.readImage(
            pictureFiles, 
            (image1Url, image1) => ({
                image1Url,
                image1
            }));
    }
    
    onDrop2(pictureFiles) {
        this.readImage(
            pictureFiles, 
            (image2Url, image2) => ({
                image2Url,
                image2
            }));
    }

    onDrop3(pictureFiles) {
        this.readImage(
            pictureFiles, 
            (image3Url, image3) => ({
                image3Url,
                image3
            }));
    }

    onDrop4(pictureFiles, pictureDataURLs) {
        this.readImage(
            pictureFiles, 
            (image4Url, image4) => ({
                image4Url,
                image4
            }));
	}

    handleChangeImage(event) {
        var self = this;
        var reader = new FileReader();
        var file = event.target.files[0];
    
        reader.onload = function(upload) {
            this.image1 = upload.target.result;
        };
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const imagesPreview = {
                image1Preview: '',
                image2Preview: '',
                image3Preview: '',
                image4Preview: ''
            };
    
            const setImageUrl = (image, idx) => {
                if(image) {
                    imagesPreview['image'+idx+'Url'] = image;
                }
            };

            const openItemResult =    
                await axios({
                    url: `${config.api.openitems}/${this.props.id}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });

            this.setState(state => {

                const openItemData = openItemResult.data.data;
                setImageUrl(openItemData.image1, 1);
                setImageUrl(openItemData.image2, 2);
                setImageUrl(openItemData.image3, 3);
                setImageUrl(openItemData.image4, 4);

                return ({
                    openItem: openItemData,
                    ...imagesPreview
                });
            });
        } catch(e) { 
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleAnswer(event) {
        const answer = event.target.value;
        this.setState({
            answer
        });
    }

    handleNote(event) {
        const note = event.target.value;
        this.setState(state => {
            const openItem = state.openItem;
            openItem.note = note;
            return ({
                openItem
            });
        });
    }

    async handleSave(event) {
        try {
            event.preventDefault();
            this.setState({
                isActiveLoadingOverlay: true
            });
            this.setState({
                isLoading: true
            });
      
            var formData = new FormData();
            console.log(this.state);
            const setImage = (idx) => {
                const imageIdx = 'image' + idx;
                const imageState = this.state[imageIdx];
                if(imageState) {
                    formData.append('data[0]['+imageIdx+']', imageState);
                }
            }
    
            formData.append('data[0][id]', this.props.id);
    
            if(this.state.openItem.note) {
                formData.append('data[0][note]', this.state.openItem.note);
            }
    
            for (let i=1; i<=4; i++) {
                setImage(i);
            }
    
            if(this.state.isImage1Present == false) {
                formData.append('data[0][isImage1Present]', this.state.isImage1Present);
            }
    
            if(this.state.isImage2Present == false) {
                formData.append('data[0][isImage2Present]', this.state.isImage2Present);
            }
    
            if(this.state.isImage3Present == false) {
                formData.append('data[0][isImage3Present]', this.state.isImage3Present);
            }
    
            if(this.state.isImage4Present == false) {
                formData.append('data[0][isImage4Present]', this.state.isImage4Present);
            }
    
    
            const headerConfig = { headers: { 'x-access-token': this.user.token, 'Content-Type': `multipart/form-databoundary=${formData._boundary}` } };
            const response = await axios.put(
                `${config.api.openitems}/raw`,
                formData,
                headerConfig
            )

            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: [],
                isLoading: false
            });
            this.setSuccessMessage('answer has been updated successfully.');
            this.props.loadSection();
            this.closeModal();
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage,
                isLoading: false
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
 
    }

    render() {
        const previewStyle = {
            display: 'inline',
            width: 280,
            height: 280,
        };

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                {!this.state.isLoading 
                ?
                <div className="pull-right" style={{marginRight: '10px'}}>
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                :
                null}
                <br/>
                <br/>
                <div className="card">
                    <div className="card-body">
                        <h1 className="card-title">Inspection</h1>
                        <hr/>
                        <div className="align-middle">
                            <div className="form-group">
                                <label><b>Question</b></label>
                                <p>{this.state.openItem.question}</p>
                            </div>
                            <div>
                                {this.state.openItem.inspectionAnswerNote
                                ?
                                <div className="form-group">
                                    <label><b>Note</b></label>
                                    <p>{this.state.openItem.inspectionAnswerNote}</p>
                                </div>
                                :
                                null}
                                {this.state.openItem.contributingFactorDescription
                                ?
                                <div className="form-group">
                                    <label><b>Contributing factor</b></label>
                                    <p>{this.state.openItem.contributingFactorDescription}</p>
                                </div>
                                :
                                null}
                                {this.state.openItem.escalationProcessTitle
                                ?
                                <div className="form-group">
                                    <label><b>Escalation process title</b></label>
                                    <p>{this.state.openItem.escalationProcessTitle}</p>
                                </div>
                                :
                                null}
                                {this.state.openItem.escalationProcessDescription ?
                                <div className="form-group">
                                    <label><b>Escalation process description</b></label>
                                    <p>{this.state.openItem.escalationProcessDescription}</p>
                                </div>
                                :
                                null}
                                <div className="row">
                                    {this.state.openItem.inspectionAnswerImage1
                                    ?
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label><b>Image 1</b></label>
                                                </div>
                                            </div>
                                            <img className="profile-panel DragnDropDP"style={previewStyle}
                                                    key={this.state.openItem.inspectionAnswerImage1}
                                                    src={this.state.openItem.inspectionAnswerImage1}/>
                                        </div>
                                    </div>
                                    :
                                    null}
                                    {this.state.openItem.inspectionAnswerImage2
                                    ?
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                    <div className="col-md-6">
                                                        <label><b>Image 2</b></label>
                                                    </div>
                                            </div>
                                            <img className="profile-panel DragnDropDP"
                                                style={previewStyle}
                                                key={this.state.openItem.inspectionAnswerImage2}
                                                src={this.state.openItem.inspectionAnswerImage2}/>
                                        </div>
                                    </div>
                                    :
                                    null}
                                    {this.state.openItem.inspectionAnswerImage3
                                    ?
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label><b>Image 3</b></label>
                                                </div>
                                            </div>
                                            <img className="profile-panel DragnDropDP"
                                                style={previewStyle}
                                                key={this.state.openItem.inspectionAnswerImage3}
                                                src={this.state.openItem.inspectionAnswerImage3}/>
                                        </div>
                                    </div>
                                    :
                                    null}
                                    {this.state.openItem.inspectionAnswerImage4
                                    ?
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                    <div className="col-md-6">
                                                        <label><b>Image 4</b></label>
                                                    </div>
                                            </div>
                                            <img className="profile-panel DragnDropDP"
                                                    style={previewStyle}
                                                    key={this.state.openItem.inspectionAnswerImage4}
                                                    src={this.state.openItem.inspectionAnswerImage4}/>
                                        </div>
                                    </div>
                                    :
                                    null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h1 className="card-title">Fix the open item</h1>
                        <hr/>
                        <form onSubmit={this.handleSave}>
                            <div className="form-group">
                                    <label><b>Note</b></label>
                                    <textarea 
                                        className="form-control"
                                        onChange={this.handleNote}
                                        value={this.state.openItem.note}
                                        rows={5}>
                                    </textarea>
                            </div>
                            <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label><b>Image 1</b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="button"  
                                                            className="pull-right clickable close-icon close" 
                                                            onClick={this.onCloseImage1}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <ReactDropzone 
                                                className="dragAndDropArea" 
                                                onDrop={this.onDrop1}
                                                accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png" 
                                                multiple={false}
                                                accept="image/*">
                                                Drag a photo here or click to upload.
                                            
                                                <img className="profile-panel DragnDropDP"
                                                    style={previewStyle}
                                                    key={this.state.image1Url}
                                                    src={this.state.image1Url}/>
                                            </ReactDropzone>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                    <div className="col-md-6">
                                                        <label><b>Image 2</b></label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <button type="button"  
                                                                className="pull-right clickable close-icon close" 
                                                                onClick={this.onCloseImage2}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                            </div>
                                            <ReactDropzone 
                                                className="dragAndDropArea" 
                                                onDrop={this.onDrop2}
                                                accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png" 
                                                multiple={false}>
                                                Drag a photo here or click to upload.
                                                <img className="profile-panel DragnDropDP"
                                                    style={previewStyle}
                                                    key={this.state.image2Url}
                                                    src={this.state.image2Url}/>
                                            </ReactDropzone>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label><b>Image 3</b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="button"  
                                                            className="pull-right clickable close-icon close" 
                                                            onClick={this.onCloseImage3}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <ReactDropzone 
                                                className="dragAndDropArea" 
                                                onDrop={this.onDrop3}
                                                accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png" 
                                                multiple={false}>
                                                Drag a photo here or click to upload.
                                            <img className="profile-panel DragnDropDP"
                                                style={previewStyle}
                                                key={this.state.image3Url}
                                                src={this.state.image3Url}/>
                                            </ReactDropzone>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="row">
                                                <div className="col-md-6">
                                                    <label><b>Image 4</b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="button"  
                                                            className="pull-right clickable close-icon close" 
                                                            onClick={this.onCloseImage4}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                        </div>
                                        <ReactDropzone 
                                            className="dragAndDropArea" 
                                            onDrop={this.onDrop4}
                                            accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png" 
                                            multiple={false}>
                                            Drag a photo here or click to upload.
                                        <img className="profile-panel DragnDropDP"
                                                style={previewStyle}
                                                key={this.state.image4Url}
                                                src={this.state.image4Url}/>
                                        </ReactDropzone>
                                    </div>
                                    </div>
                                </div>
                            {this.state.failedMessage 
                            ? <div className="alert alert-danger" role="alert">
                                {this.state.failedMessage}
                            </div> 
                            : null}
                            <div className="row">
                            <div className="col-md-12">
                                <button className="btn"
                                    style={{ width: '100%' }}
                                    type="submit"
                                    disabled={this.state.isLoading}>{this.state.isLoading ? 'Please wait...' : 'Save'}</button>
                            </div>
                            </div>
                            </form>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default QuestionOpenItemEdit;
