import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';


class QuestionPointEdit extends Component {

    constructor(props) {
        super(props);

        this.handleSavePoint = this.handleSavePoint.bind(this);
        this.state = {
            questions: []
        }
    }

    handleSavePoint() {
        const mappedQuestions = 
            R.map(x => ({
                questionId: x.id,
                point: parseFloat(x.point)
            }),
            this.state.questions);
        
        this.props.setPointsToChange(mappedQuestions);
    }

    onChangePoint(idx, point) {
        this.setState((state, props) => {
            const oldQuestion = state.questions;
            const oldState = oldQuestion[idx];
            
            oldQuestion[idx] = {
                ...oldState,
                point
            }
            return {questions: oldQuestion};
        });
    }

    componentDidMount() {
        const user = getObject('user');
  
        axios({
            url: `${config.api.sectiontemplate}/${this.props.sectionId}/questions`,
            method: 'get',
            headers: { 'x-access-token': user.token }
        }).then(response => {
            const data = response.data.data;
            const questions = data.results;
            this.setState({
                questions
            });
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        var mapIndexed = R.addIndex(R.map);
        return (
            <div>
                <div className="pull-right" style={{marginRight: '10px'}}>
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.props.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Question</th>
                            <th scope="col">Point</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapIndexed((x, idx) => {
                                return (<tr key={idx}>
                                            <td>{x.question}</td>
                                            <td><input defaultValue={x.point} 
                                                       type="number"
                                                       className="form-control" 
                                                       onChange={e => this.onChangePoint(idx, e.target.value)}/></td>
                                        </tr>);
                              }, 
                              this.state.questions)}
                    </tbody>
                </table>
                {this.props.pointFailedMessage
                    ? <div className="alert alert-danger" role="alert">
                        {this.props.pointFailedMessage}
                    </div>
                    : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn" style={{width: '100%'}} onClick={this.handleSavePoint}>Save</button>
                    </div>
                </div>
            </div>);
    }
}

export default QuestionPointEdit;