import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class CompanyEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: '',
            failedMessage: ''
        };

        this.handleCompany = this.handleCompany.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handlePostcode = this.handlePostcode.bind(this);
        this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleIsActive = this.handleIsActive.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const response = await axios({
                url: `${config.api.company}/${this.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
            const data = response.data.data;
            this.setState({
                company: data.company,
                address: data.address,
                state: data.state,
                postcode: data.postcode,
                phoneNumber: data.phoneNumber,
                country: data.country,
                isActive: data.isActive
            });
        } catch(e) {
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleDeleteCard(idx) {
        this.closeModal();
    }

    handleCompany(event) {
        const company = event.target.value;
        this.setState({
            company
        });
    }

    handleAddress(event) {
        const address = event.target.value;
        this.setState({
            address
        });
    }

    handleState(event) {
        const state = event.target.value;
        this.setState({
            state
        });
    }

    handlePostcode(event) {
        const postcode = event.target.value;
        this.setState({
            postcode
        });
    }

    handlePhoneNumber(event) {
        const phoneNumber = event.target.value;
        this.setState({
            phoneNumber
        });
    }

    handleCountry(event) {
        const country = event.target.value;
        this.setState({
            country
        });
    }

    handleIsActive(event) {
        this.setState({
            isActive: event.target.checked
        });
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });

            const response = await axios({
                url: `${config.api.company}/${this.id}`,
                method: 'put',
                headers: { 'x-access-token': this.user.token },
                data: {
                    company: this.state.company,
                    address: this.state.address,
                    state: this.state.state,
                    postcode: this.state.postcode,
                    isActive: this.state.isActive,
                    phoneNumber: this.state.phoneNumber,
                    country: this.state.country
                } 
            });
    
            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.setSuccessMessage('company has been updated successfully.');
            this.closeModal();
        } catch(e) {
            const data = e.response.data;
            const failedMessage = data.message;
            this.setState({
                failedMessage
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="clickable close-icon close" 
                            onClick={() =>this.handleDeleteCard(1)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label>Company</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.company}
                            onChange={this.handleCompany}/>
                    </div>
                    <div className="form-group">
                        <label>Address</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.address}
                            onChange={this.handleAddress}/>
                    </div>
                    <div className="form-group">
                        <label>State</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.state}
                            onChange={this.handleState}/>
                    </div>
                    <div className="form-group">
                        <label>Postcode</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.postcode}
                            onChange={this.handlePostcode}/>
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.phoneNumber}
                            onChange={this.handlePhoneNumber}/>
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <input type="text"
                            className="form-control"
                            value={this.state.country}
                            onChange={this.handleCountry}/>
                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" 
                                type="checkbox" 
                                checked={this.state.isActive}
                                onChange={this.handleIsActive}/>
                            <label className="form-check-label">
                                Active
                            </label>
                        </div>
                    </div>
                </form>

                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                  </div> 
                : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default CompanyEdit;