import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import axios from 'axios';
import {setObject, getObject} from '../utils/localstorage';
import config from '../config';

class Logout extends Component {
    constructor(props) {
        super(props);
        setObject('user', null);
        this.props.setActiveMenu('dashboard');
        
    }
    
    render() {
      return (
        <Redirect to="/login" />
      );
    }
  }

export default Logout;

