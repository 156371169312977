import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class D13ViolationEdit  extends Component {
    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
        this.totalCard = 1;
        this.selectedProjectId = 0;
        this.selectedTemplateId = 0;
        this.user = getObject('user');

        this.state = {
            companyValue: '',
            projectName: '',
            inspectionName: '',
            location: '',
            projectSuggestions: [],
            templateSuggestions: [],
            projects: [],
            templateValue: '',
            templateDescription: '',
            failedMessage: ''
        };

        this.getProjectSuggestions = async value => {
            const result = await axios({
                url: `${config.api.project}?projectName=${value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const data = result.data.data;

            console.log(data.results);
            this.selectedProjectId = 0;
            this.setState({
                location: '',
                companyName: ''
            });
            return data.results;
        };
 
        this.getProjectSuggestionValue = suggestion => {
            this.selectedProjectId = suggestion.id;
            this.setState({
                location: suggestion.location,
                companyName: suggestion.company
            });
            return suggestion.projectName;
        };

        this.getTemplateSuggestionValue = suggestion => {
            this.selectedTemplateId = suggestion.id;
            this.setState({
                templateDescription: suggestion.description
            });
            return suggestion.title;
        };

        this.renderProjectSuggestion = suggestion => (
            <span>{suggestion.projectName}</span>);

        this.renderTemplateSuggestion = suggestion => (
                <span>{suggestion.title}</span>);

        this.handleInspectionName = this.handleInspectionName.bind(this);
        this.user = getObject('user');
    }
    
    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const d13ViolationPromise = axios({
                url: `${config.api.d13violation}/${this.props.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const companiesPromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const typesPromise = axios({
                url: `${config.api.d13violationtype}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const consequencePromise = axios({
                url: `${config.api.d13violationconsequence}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const [d13ViolationResult, 
                   companiesResult, 
                   typesResult,
                   consequencesResult] = 
                await Promise.all([d13ViolationPromise, 
                                   companiesPromise,
                                   typesPromise,
                                   consequencePromise]);

            const d13Violation = d13ViolationResult.data.data;
            const companies = companiesResult.data.data.results;
            const types = typesResult.data.data.results;
            const consequences = consequencesResult.data.data.results;

            this.setState({
                companies: R.map(x => ({value: x.id, label: x.company}), companies),
                companyValue : { value: d13Violation.companyId, label: d13Violation.company},
                types: R.map(x => ({value: x.id, label: x.type}), types),
                typeValue: {value: d13Violation.d13ViolationTypeId, label: d13Violation.type},
                consequences: R.map(x => ({value: x.id, label: x.consequence}), consequences),
                consequenceValue: { value: d13Violation.d13ViolationConsequenceId, label: d13Violation.consequence },
                dateTime: d13Violation.dateTime,
                name: d13Violation.name,
                email: d13Violation.email
            });
        } catch(e) {
            console.log(e);
            // const data = e.response.data;
            // this.setFailedMessage(data.message);
            // this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleInspectionName(inspectionName) {
        this.setState({
            inspectionName
        });
    }

    onProjectSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getProjectSuggestions(value);
        this.setState({
            projectSuggestions: values
        });
    };

    onTemplateSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getTemplateSuggestions(value);
        this.setState({
            templateSuggestions: values
        });
    };

    onProjectSuggestionsClearRequested = () => {
        this.setState({
          projectSuggestions: []
        });
    };

    onTemplateSuggestionsClearRequested = () => {
        this.setState({
          templateSuggestions: []
        });
    };

    onProjectChange = (event, { newValue }) => {
        this.setState({
          companyValue: newValue
        });
    };

    onTemplateChange = (event, { newValue }) => {
        this.setState({
          templateValue: newValue
        });
    };

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }

    handleChangeCompany = async (companyValue) => {
        try {
            this.setState({
                companyValue
            });
        } catch(e) {

        }
    }

    handleChangeType = async (typeValue) => {
        try {
            this.setState({
                typeValue
            });
        } catch(e) {

        }
    }

    handleChangeConsequence = async (consequenceValue) => {
        try {
            this.setState({
                consequenceValue
            });
        } catch(e) {

        }
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const d13ViolationApi = config.api.d13violation;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const res = await axios.put(`${d13ViolationApi}/${this.props.id}`,
                       {
                           d13ViolationTypeId: this.state.typeValue.value,
                           d13ViolationConsequenceId: this.state.consequenceValue.value,
                           dateTime: this.state.dateTime
                       },
                       header);

            this.props.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('d13Violation has been updated successfully');
            this.props.closeModal();

            if(this.props.onSuccess) {
                this.props.onSuccess();
            }
        } catch(e) { 
            const data = e.response.data;
            if (data.code === 400) {
                this.setState({
                    failedMessage: data.message
                });
            }                 
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleDateTime = (value) => {
        this.setState({
            dateTime: value.format("YYYY-MM-DD HH:mm:ss")
        });
    }

    render() {
        const { companyValue, typeValue, consequenceValue } = this.state;

        if(!companyValue) {
            return null;
        }
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div>
                    <form>
                        <div className="form-group">
                            <label><b>Contractor name</b></label>
                            <p>{this.state.name}</p>
                        </div>
                        <div className="form-group">
                            <label><b>Contractor email</b></label>
                            <p>{this.state.email}</p>
                        </div>
                        <div className="form-group">
                            <label><b>Date time</b></label>
                            <DateTime onChange={this.handleDateTime} defaultValue={moment(this.state.dateTime)}/>
                        </div>
                        <div className="form-group">
                            <label><b>Type</b></label>
                            <Select
                                value={typeValue}
                                onChange={this.handleChangeType}
                                options={this.state.types}/>
                        </div>
                        <div className="form-group">
                            <label><b>Consequence</b></label>
                            <Select
                                value={consequenceValue}
                                onChange={this.handleChangeConsequence}
                                options={this.state.consequences}/>
                        </div>
                    </form>
                    {this.state.failedMessage 
                        ? <div className="alert alert-danger" role="alert">
                                {this.state.failedMessage}
                            </div> 
                        : null}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default D13ViolationEdit;