import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class ProjectCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyId: '',
            companyOptions: [],
            projectName: '',
            location: '',
            contractorNameId: ''
        };

        this.user = getObject('user');

        this.handleSave = this.handleSave.bind(this);
        this.handleProjectName = this.handleProjectName.bind(this);
        this.handleLocation = this.handleLocation.bind(this);
        this.handleCompanyId = this.handleCompanyId.bind(this);
        this.handleContractorNameId = this.handleContractorNameId.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;

        this.userType = this.user ? this.user.user.type : null;
    }

    async componentDidMount() {
        
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const companiePromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
            
            const departmentsPromise = axios({
                url: `${config.api.department}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const locationsPromise = axios({
                url: `${config.api.location}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const contractorNamesPromise = axios({
                url: `${config.api.contractorname}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });
    
            const [companiesResult,
                   departmentsResult,
                   locationsResult,
                   contractorNameResult] =
                   await Promise.all([
                        companiePromise,
                        departmentsPromise,
                        locationsPromise,
                        contractorNamesPromise
                   ]);
    
    
            const companies = companiesResult.data.data.results;
            const departments = departmentsResult.data.data.results;
            const locations = locationsResult.data.data.results;
            const contractorNames = contractorNameResult.data.data.results;

            this.setState({
                companyOptions: companies,
                companyId: companies.length > 0 ? companies[0].id : null,
                departmentOptions: departments,
                departmentId: departments.length > 0 ? departments[0].id : null,
                locationOptions: locations,
                locationId: locations.length > 0 ? locations[0].id : null,
                contractorNameOptions: contractorNames,
                contractorNameId: contractorNames.length > 0 ? contractorNames[0].id : null
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }


    handleCompanyId(companyId) {
        this.setState({
            companyId
        });
    }

    handleContractorNameId(contractorNameId) {
        this.setState({
            contractorNameId
        });
    }

    handleProjectName(projectName) {
        this.setState({
            projectName
        });
    }

    handleLocation(location) {
        this.setState({
            location
        });
    }

    handleDepartmentId = (departmentId) => {
        this.setState({
            departmentId
        });
    }

    handleLocationId = (locationId) => {
        this.setState({
            locationId
        });
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const projectAPI = config.api.project;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const data = { 
                projectName:  this.state.projectName
            };

            if(this.state.departmentId) {
                data.departmentId = this.state.departmentId;
            }

            if(this.state.locationId) {
                data.locationId = this.state.locationId; 
            }

            if(this.state.contractorNameId) {
                data.contractorNameId = this.state.contractorNameId 
            }

            const res = await axios.post(
                            projectAPI,
                            data,
                            header);

            this.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('project has been created successfully');
            this.closeModal();
        } catch(e) {
            const data = e.response.data;
            this.setState({
                failedMessage: data.message
            });
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    <div className="form-group">
                        <label>Project name</label>
                        <input type="text"
                            className="form-control"
                            onChange={(event) => this.handleProjectName(event.target.value)}/>
                    </div>
                    {this.userType === 'ADMIN_LOCATION'
                    ?
                    <div className="form-group">
                        <label>Department</label>
                        <select onChange={event => this.handleDepartmentId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                defaultValue={this.state.departmentId}>
                                {R.map(
                                    x => <option value={x.id}>{x.department}</option>,
                                    this.state.departmentOptions || [])}
                        </select>
                    </div>
                    :
                    null}
                    <div className="form-group">
                        <label>Contractor name</label>
                        <select onChange={event => this.handleContractorNameId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                defaultValue={this.state.contractorNameId}>
                                {R.map(
                                    x => <option value={x.id}>{x.contractorName}</option>,
                                    this.state.contractorNameOptions || [])}
                        </select>
                    </div>
                </form>
                {this.state.failedMessage 
                    ? <div className="alert alert-danger" role="alert">
                            {this.state.failedMessage}
                        </div> 
                    : null}

                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>            
            </div>
        );
    }
}


export default ProjectCreate;