import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import ProjectCreate from '../components/ProjectCreate';
import ProjectEdit from '../components/ProjectEdit';
import DeleteModal from '../components/DeleteModal';
import config from '../config';
import {convertToStd} from '../utils/date';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import moment from 'moment';
import ReactModal from 'react-modal';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { getColumnWidth } from '../utils/table';
import Select from 'react-select';

class ViolationOccurance extends Component {

  constructor(props) {
    super(props);

    this.filters = [
      {id: 'question', value: ''},
      {id: 'total', value: ''}
    ];

    this.props.setActiveLoadingOverlay(true);
    const today = moment().format("YYYY-MM-DD");
    const lastWeek = moment().subtract(7, 'days').format("YYYY-MM-DD");
    const last2Weeks = moment().subtract(14, 'days').format("YYYY-MM-DD");
    const last30Days = moment().subtract(30, 'days').format("YYYY-MM-DD");

    let dateFilter = 'LAST_WEEK';

    let fromDate = this.props.fromDate ? this.props.fromDate : lastWeek;
    let toDate = this.props.toDate ? this.props.toDate : today;

    if(toDate === today) {
      if(fromDate === lastWeek) {
        dateFilter = 'LAST_WEEK';
      } else if(this.props.fromDate === last2Weeks) {
        dateFilter = 'LAST_2_WEEK';
      } else if(this.props.fromDate === last30Days) {
        dateFilter = 'LAST_30_DAYS';
      } else {
        dateFilter = 'NONE';
      }
    } else {
      dateFilter = 'NONE';
    }
    


    this.state = {
      data: [],
      pages: null,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showAddModal: false,
      isBackToHome: false,
      editId: 0,
      deleteId: 0,
      redirectId: 0,
      successMessage: '',
      failedMessage: '',
      redirectId: 0,
      isShowingGetProject: true,
      isShowingCreateProject: false,
      isShowingUpdateProject: false,
      isShowingDeleteProject: false,
      isShowingProjectContractor: false,
      contractorNameId: 0,
      fromDate,
      toDate,
      dateFilter,
      from: new Date(fromDate),
      to: new Date(toDate)
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.setFailedMessage = this.setFailedMessage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
    this.hideNavBar = props.hideNavBar;
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleDateFilterShortcut = this.handleDateFilterShortcut.bind(this);
    this.handleContractorName = this.handleContractorName.bind(this);
    this.loadTable = this.loadTable.bind(this);
    this.showFromMonth = this.showFromMonth.bind(this);
    this.loadDefaultPage = this.loadDefaultPage.bind(this);
    this.loadContractorNames = this.loadContractorNames.bind(this);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);

    this.page = 0;
    this.pageSize = 10;

    this.props.handleClickMenu('violationoccurrence', '');

    this.user = getObject('user');

    setObject('lastRoute', 'violationoccurrence');
  }

  async handleContractorName({value, label}) {
    this.setState({
      contractorNameId: value,
      contractorNameValue: {
        value,
        label
      }
    },this.loadDefaultPage);
  }

  async loadContractorNames(locationId) {
    const contractorNamesResult = await axios({
      url: `${config.api.contractorname}/${locationId}`,
      method: 'get',
      headers: { 'x-access-token': this.user.token }
    });

    const result = contractorNamesResult.data.data.results;

    const finalResult = R.map(x => ({value: x.id, label: x.contractorName}), result); 
    return finalResult;
  }

  pushHistory = (contractorNameId, fromDate, toDate) => {
    window.history.pushState(
      {urlPath: `/violationoccurrence/${contractorNameId}/${fromDate}/${toDate}`}, 
      "", 
      `/violationoccurrence/${contractorNameId}/${fromDate}/${toDate}`);    
  }

  loadTable() {

  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    if(this.state.to) {
      this.setState({ 
          from,
          fromDate: moment(from).format('YYYY-MM-DD') 
        }, 
        async () => {
          await this.loadDefaultPage();
        });
    } else {
      this.setState({ 
        from,
        fromDate: moment(from).format('YYYY-MM-DD') 
      });
    }
  }

  handleToChange(to) {
    if(this.state.from) {
      this.setState({ 
          to, 
          toDate: moment(to).format('YYYY-MM-DD') 
        }, 
        async () => {
          this.showFromMonth();
          await this.loadDefaultPage();
        });
    } else {
      this.setState({ 
        to,
        toDate: moment(to).format('YYYY-MM-DD') 
      },
      async () => {
        this.showFromMonth();
      });
    }
  }

  loadDefaultPage() {
    if(this.state.fromDate
       && this.state.toDate) {
        this.pushHistory(
          this.state.contractorNameId, 
          this.state.fromDate,
          this.state.toDate);
    }
      
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  async handleDateFilterShortcut(data) {
    if(data === 'LAST_WEEK') {
      this.setState({
        dateFilter: data,
        isFilterDateShowing: false,
        fromDate: moment().subtract(7, 'days').format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD")
      }, this.loadDefaultPage);
    } else if(data === "LAST_2_WEEK") {
      this.setState({
        dateFilter: data,
        isFilterDateShowing: false,
        fromDate: moment().subtract(14, 'days').format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD") 
      }, this.loadDefaultPage);
    } else if(data === "LAST_30_DAYS") {
      this.setState({
        dateFilter: data,
        isFilterDateShowing: false,
        fromDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD") 
      }, this.loadDefaultPage);
    } else if(data === 'NONE') {
      this.setState({
        dateFilter: data,
        isFilterDateShowing: true
      });
    }
  }

  handleFilterInput() {
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
  }

  handleCloseFilterInput() {
    this.filters = [
      {id: 'question', value: ''},
      {id: 'total', value: ''}
    ];
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleSearch(idx, value) {
    this.filters[idx].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleBack() {
    this.setState({
        isBackToHome : true
    });
  }

  handleSection(redirectId) {
    this.setState({
      redirectId
    });
  }

  async refreshTable(state) {
    try {
      this.props.setActiveLoadingOverlay(true);
      const user = getObject('user');
      const size = state.pageSize;
      const page = parseInt(state.page) + 1;
  
      this.page = parseInt(state.page);
      this.pageSize = state.pageSize;
  
      const filtered = this.filters;
  
      let filterString = '';
      const filterXs = 
            R.pipe(
              R.filter(x => x.value !== ''),
              R.map(x => `${x.id}=${x.value}`)
            )(filtered);
      
      filterString = filterXs.join('&');
  
      const sortedXs = R.map(
        x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
        state.sorted || []);
      let sortedString = '&orderBy=company&orderDirection=asc';
  
      if(sortedXs.length > 0) {
          sortedString = sortedXs.join('&');
      }
  
      const response = await axios({
        url: `${config.api.openitems}/violationoccurances?contractorNameId=${this.state.contractorNameId}&size=${size}&page=${page}&fromDate=${this.state.fromDate}&toDate=${this.state.toDate}&${filterString}`,
        method: 'get',
        headers: { 'x-access-token': user.token }
      });
      const data = response.data.data;
      this.setState({
        data: data.results,
        pages: data.totalPage,
        loading: false
      });
    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }

  fetchData(state, instance) {
    this.clearMessage();
    this.setState({ loading: true });
    this.refreshTable(state);
  }

  handleAdd() {
    this.hideNavBar(true);
    this.setState({
      showAddModal: true
    });
  }

  handleEdit(editId) {
    this.hideNavBar(true);
    this.setState({
      showEditModal: true,
      editId
    });
  }

  handleCloseAddModal() {
    this.hideNavBar(false);
    this.setState({
      showAddModal: false
    });
  }

  handleCloseEditModal(id) {
    this.hideNavBar(false);
    this.setState({
      showEditModal: false
    });
  }

  handleDelete(deleteId) {
    this.setState({
      showDeleteModal: true,
      deleteId
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      showDeleteModal: false
    });
  }

  setSuccessMessage(successMessage) {
    this.setState({
      successMessage,
      failedMessage: ''
    });
  }

  setFailedMessage(failedMessage) {
    this.setState({
      failedMessage,
      successMessage: ''
    });
  }

  clearMessage() {
    this.setState({
      successMessage: '',
      failedMessage: ''
    });
  }

  componentWillUnmount() {
    if(this.timer){
        clearTimeout(this.timer);
    }
  }

  async componentDidMount() {
    try {
      this.props.setActiveLoadingOverlay(true);
      if(this.user) {  
        const apiControlViolationOccurrencePromise = await axios({
          url: `${config.api.apicontrols}/checking?route=/openitems&method=GET&parameter=/violationoccurances`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });
  
        if(!apiControlViolationOccurrencePromise.data.data.isAllowed) {
          console.log('is allowed = ', apiControlViolationOccurrencePromise.data.data.isAllowed);
          this.props.history.push('/');
        } else {

          let locations = [];
          let locationId = 0;
          let locationValue;
          if(this.user && this.user.user.type === 'ADMIN' || this.user.user.type === 'SUPER_ADMIN') {
            const locationsResult = await axios({
              url: `${config.api.location}`,
              method: 'get',
              headers: { 'x-access-token': this.user.token }
            });
    
            locations = R.map(location => ({value: location.id, label: location.location}), locationsResult.data.data.results);
            locationId = locations.length > 0 ? locations[0].value : 0;
    
            locationValue = locations.length > 0 ? {value: locations[0].value, label: locations[0].label} : {};
    
            console.log('Location value = ', locationValue);
    
          } else {
            locationId = this.user.user.locationId;
          }
    
    
          let contractorNames = [];
          if(locationId) {
            contractorNames = await this.loadContractorNames(locationId);
          }
    
          let contractorNameValue = null;
          let contractorNameId = 0;
    
          if(contractorNames.length > 0) {
            const contractorName = contractorNames[0];
    
            contractorNameValue = {
              value: contractorName.value,
              label: contractorName.label
            };
            contractorNameId = contractorName.value;
          }
    
          this.contractorNameValue = contractorNameValue;
          this.contractorNameId = contractorNameId;


          if(contractorNames.length > 0) {
            contractorNameValue.value = contractorNames[0].value;
            contractorNameValue.label = contractorNames[0].label;
          }


          this.setState({
            locations,
            locationValue,
            locationId,
            contractorNames,
            contractorNameValue,
            contractorNameId: contractorNameValue ? contractorNameValue.value : 0
          });
    
          this.loadDefaultPage();
        }
  
      }
    } finally {
      this.props.setActiveLoadingOverlay(false);
    }

  }

  async handleChangeLocation({value, label}) {
    const contractorNames = await this.loadContractorNames(value);
    let contractorNameValue = null;
    let contractorNameId;
    if(contractorNames.length > 0) {
      const contractorName = contractorNames[0];

      contractorNameValue = {
        value: contractorName.value,
        label: contractorName.label
      };

      contractorNameId = contractorName.value;
    }

    this.contractorNameId = contractorNameId;
    this.contractorNameValue = contractorNameValue;

    this.setState({
        locationId: value,
        locationValue: {
          value,
          label
        },
        contractorNames,
        contractorNameValue,
        contractorNameId
      }, this.loadDefaultPage);
  }

  render() {
    if(!this.user) {
      return <Redirect to="/login"/>;
    }

    if(!this.state.isShowingGetProject) {
      return <Redirect to="/"/>;
    }

    if(this.state.isBackToHome) {
      return <Redirect to="/"/>;
    }

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  successMessage: ''
              });
          }, config.notifDuration);
    }

    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    const { data, pages, loading } = this.state;

    const columns = [
      {
        Header: "Question",
        accessor: "question",
        width: getColumnWidth(data, 'question', 'Question')
      },
      {
          Header: "Total",
          accessor: "total",
          width: getColumnWidth(data, 'total', 'Total')
      }
    ];


      var modalStyles = {overlay: {zIndex: 10}};
      const component = (
        <div>
          <br/>
          {this.state.successMessage
            ? <div className="alert alert-success" role="alert">
              {this.state.successMessage}
            </div>
            : null}
          {this.state.failedMessage
            ? <div className="alert alert-danger" role="alert">
              {this.state.failedMessage}
            </div>
            : null}
          <hr/>
          <h1>Violation Occurrence</h1>
          <hr/>
          <ReactModal isOpen={this.state.showAddModal}
            contentLabel="Minimal Modal Example" style={modalStyles}>
            <ProjectCreate
              closeModal={this.handleCloseAddModal}
              refreshTable={this.fetchData}
              tablePaging={{
                page: this.page,
                pageSize: this.pageSize
              }}
              setSuccessMessage={this.setSuccessMessage}/>
          </ReactModal>
          <ReactModal isOpen={this.state.showEditModal}
            contentLabel="Minimal Modal Example">
            <ProjectEdit
              closeModal={this.handleCloseEditModal}
              id={this.state.editId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              tablePaging={{
                page: this.page,
                pageSize: this.pageSize
              }} />
          </ReactModal>
          <ReactModal isOpen={this.state.showDeleteModal}
            contentLabel="Minimal Modal Example"
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
              }
            }}>
            <DeleteModal
              closeModal={this.handleCloseDeleteModal}
              id={this.state.deleteId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              deleteUrl={config.api.project} />
          </ReactModal>
          {this.user
           && this.user.user.type === 'ADMIN' || this.user.user.type === 'SUPER_ADMIN'
           ?
           <div className="form-group">
            <label><b>Choose location: </b></label>
            <Select
                onChange={this.handleChangeLocation}
                options={this.state.locations}
                value={this.state.locationValue}
                />
           </div>
           :
           null}
          {this.user && this.user.user.type === 'CONTRACTOR'
          ?  
          null
          :
          <div className="form-group">
            <label><b>Choose contractor name: </b></label>
            <Select
                onChange={this.handleContractorName}
                options={this.state.contractorNames}
                value={this.state.contractorNameValue}
                />
          </div>
          }
          <div className="InputFromTo">
            <label><b>Choose filter : </b></label>
            <select className="form-control" style={{marginBottom: '8px'}}  
                    onChange={event => this.handleDateFilterShortcut(event.target.value)}
                    value={this.state.dateFilter}>
              <option value="LAST_WEEK">Last week</option>
              <option value="LAST_2_WEEK">Last 2 weeks</option>
              <option value="LAST_30_DAYS">Last 30 days</option>
              <option value="NONE">Custom</option>
            </select>

            <div style={{display: this.state.dateFilter === 'NONE' ? 'inline-block' : 'none'}}>
            <DayPickerInput
              value={from}
              placeholder="From"
              format="LL"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { after: to },
                toMonth: to,
                modifiers,
                numberOfMonths: 1,
                onDayClick: () => this.to.getInput().focus(),
              }}
              onDayChange={this.handleFromChange}
            />{' '}
            —{' '}
            <span className="InputFromTo-to">
              <DayPickerInput
                ref={el => (this.to = el)}
                value={to}
                placeholder="To"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { before: from },
                  modifiers,
                  month: from,
                  fromMonth: from,
                  numberOfMonths: 1,
                }}
                onDayChange={this.handleToChange}
              />
            </span>
            </div>
            <Helmet>
              <style>{`
      .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #f0f8ff !important;
        color: #4a90e2;
      }
      .InputFromTo .DayPicker-Day {
        border-radius: 0 !important;
      }
      .InputFromTo .DayPicker-Day--start {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
      }
      .InputFromTo .DayPicker-Day--end {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
      .InputFromTo .DayPickerInput-Overlay {
        width: 550px;
      }
      .InputFromTo-to .DayPickerInput-Overlay {
        margin-left: -198px;
      }
      `}</style>
            </Helmet>
    </div>
          <br/>
          <div className="grid2-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
            <div className="grid-item">
              {/* <labe>{}</label>
              <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Inspection</span></button> */}
            </div>
            {!this.state.showFiltering
            ?
            <div className="grid-item" style={{ justifySelf: "end" }}>
              <button type="button" className="btn" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
            </div>
            :
            null}
          </div>
          {this.state.showFiltering 
          ?
          <div>
            <br/>
            <br/>
            <div className="card">
              <div className="pull-right" style={{ marginRight: '10px'}}>
                  <button type="button"  
                          className="pull-right clickable close-icon close" 
                          onClick={this.handleCloseFilterInput}>
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-auto">
                    <label><b>Question</b></label>
                    <input className="form-control" 
                          onChange={e => this.handleSearch(0, e.target.value)}/>
                  </div>
                  <div className="col-md-auto">
                    <label><b>Total</b></label>
                    <input className="form-control" 
                          onChange={e => this.handleSearch(1, e.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          null}
          <ReactTable
            columns={columns}
            manual 
            data={data}
            pages={pages} 
            loading={loading} 
            onFetchData={this.fetchData} 
            filterable={false}
            defaultPageSize={10}
            className="-striped -highlight"
            sortable={false}
          />
          <br />
        </div>
      );
  
      return component;
  }
}

export default withRouter(ViolationOccurance);