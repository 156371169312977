import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import InspectionCreate from '../components/InspectionCreate';
import InspectionEdit from '../components/InspectionEdit';
import DeleteModal from '../components/DeleteModal';
import RestoreModal from '../components/RestoreModal';
import config from '../config';
import ReactTable from "react-table";
import * as R from 'ramda';
import "react-table/react-table.css";
import ReactModal from 'react-modal';
import { convertToStd } from '../utils/date';
import Select from 'react-select';
import moment from 'moment';

class Inspection extends Component {

  constructor(props) {
    super(props);

    this.filters = [
      {id: 'name', value: ''},
      {id: 'projectName', value: ''},
      {id: 'preparedBy', value: ''},
      {id: 'location', value: ''},
      {id: 'contractorName', value: ''},
      {id: 'isSubmitted', value: false},
      {id: 'isRemoved', value: false}
    ];

    this.successMessage = '';
    // if(this.props.location.state) {
    //   this.successMessage = this.props.location.state.successMessage; 
    // }

    // const history = createBrowserHistory();
    // history.push({ state: {successMessage: ""} });
    setObject('lastRoute', 'inspection');

    this.state = {
      data: [],
      pages: null,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showConfirmationModal: false,
      showAddModal: false,
      isBackToHome: false,
      editId: 0,
      currentInspectionId: 0,
      inspectionId: 0,
      deleteId: 0,
      redirectId: 0,
      successMessage: this.successMessage,
      failedMessage: '',
      showFiltering: false,
      isShowingCreateInspection: false,
      isShowingGetInspection: true,
      isSubmittedOptions: [{value: true, label: 'Yes'}, {value: false, label: 'No'}]
    };

    this.page = 0;
    this.pageSize = 10;
    this.fetchData = this.fetchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.setSuccessMessage = this.setSuccessMessage.bind(this);
    this.setFailedMessage = this.setFailedMessage.bind(this);
    this.refreshTable = this.refreshTable.bind(this);   
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
    this.handleIsSubmitted = this.handleIsSubmitted.bind(this);
    this.handleRestoreInspection = this.handleRestoreInspection.bind(this);
    this.handleCloseRestoreModal = this.handleCloseRestoreModal.bind(this);
    this.hideNavBar = props.hideNavBar;

    this.props.handleClickMenu('inspection', 'inspection');

    this.user = getObject('user');
  }

  async handleRestoreInspection(restoreId) {
    this.setState({
      showRestoreModal: true,
      restoreId
    });
  }

  handleCloseRestoreModal() {
    this.setState({
      showRestoreModal: false
    });
  }

  handleFilterInput() {
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
  }

  handleIsSubmitted({value, label}) {
    this.filters[6].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleCloseFilterInput() {
    this.filters = [
      {id: 'name', value: ''},
      {id: 'projectName', value: ''},
      {id: 'preparedBy', value: ''},
      {id: 'location', value: ''},
      {id: 'contractorName', value: ''},
      {id: 'isSubmitted', value: false},
      {id: 'isRemoved', value: false}
    ];
    this.setState((state) => ({
      showFiltering: !state.showFiltering
    }));
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleSearch(idx, value) {
    this.filters[idx].value = value;
    this.refreshTable({
      page: this.page,
      pageSize: this.pageSize
    });
  }

  handleBack() {
    this.setState({
        isBackToHome : true
    });
  }

  handleSection(currentInspectionId, redirectId) {
    this.setState({
      currentInspectionId,
      redirectId
    });
  }

  refreshTable(state) {
    const user = getObject('user');
    const size = state.pageSize;
    const page = parseInt(state.page) + 1;

    this.page = parseInt(state.page);
    this.pageSize = state.pageSize;

    const filtered = this.filters;

    console.log(filtered);

    let filterString = '';
    const filterXs = 
          R.pipe(
            R.filter(x => x.value !== ''),
            R.map(x => `${x.id}=${x.value}`)
          )(filtered);
    
    filterString = filterXs.join('&');

    const sortedXs = R.map(
                      x => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`,
                      state.sorted || []);
    let sortedString = '&orderBy=createdDate&orderDirection=desc';
    
    if(sortedXs.length > 0) {
      sortedString = sortedXs.join('&');
    }

    axios({
      url: `${config.api.inspection}?size=${size}&page=${page}&${filterString}&${sortedString}`,
      method: 'get',
      headers: { 'x-access-token': user.token }
    })
      .then(response => {
        const data = response.data.data;
        console.log(data);
        this.setState({
          data: data.results,
          pages: data.totalPage,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  fetchData(state, instance) {
    this.clearMessage();
    this.setState({ loading: true });
    this.refreshTable(state);
  }

  handleAdd() {
    this.hideNavBar(true);
    this.setState({
      showAddModal: true
    });
  }

  handleEdit(editId) {
    this.hideNavBar(true);
    this.setState({
      showEditModal: true,
      editId
    });
  }

  handleCloseAddModal() {
    this.hideNavBar(false);
    this.setState({
      showAddModal: false
    });
  }

  handleCloseEditModal(id) {
    this.hideNavBar(false);
    this.setState({
      showEditModal: false
    });
  }

  handleDelete(deleteId) {
    this.setState({
      showDeleteModal: true,
      deleteId
    });
  }

  handleCloseDeleteModal() {
    this.setState({
      showDeleteModal: false
    });
  }

  setSuccessMessage(successMessage) {
    this.hideNavBar(false);
    this.setState({
      successMessage,
      failedMessage: ''
    });
  }

  setFailedMessage(failedMessage) {
    this.setState({
      failedMessage,
      successMessage: ''
    });
  }

  clearMessage() {
    this.setState({
      successMessage: '',
      failedMessage: ''
    });
  }

  async componentDidMount() {
    try {
      if(this.user) {
        const apiControlGetInspectionPromise = axios({
          url: `${config.api.apicontrols}/checking?route=/inspections&method=GET&parameter=/`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });
    
        const apiControlCreateInspectionPromise = axios({
          url: `${config.api.apicontrols}/checking?route=/inspections&method=POST&parameter=/`,
          method: 'get',
          headers: { 'x-access-token': this.user.token }
        });
    
        const [apiControlGetInspectionResult, apiControlCreateInspectionResult] = await
          Promise.all([apiControlGetInspectionPromise, apiControlCreateInspectionPromise]);
        
        this.setState({
          isShowingCreateInspection: apiControlCreateInspectionResult.data.data.isAllowed,
          isShowingGetInspection: apiControlGetInspectionResult.data.data.isAllowed
        });
      }
    } catch(e) {

    }
  }

  render() {
    if(!this.user) {
      return <Redirect to="/login" />;
    }

    if(!this.state.isShowingGetInspection) {
      return <Redirect to="/" />;
    }

    const columns = [
      {
        Header: "Action",
        accessor: "id",
        // id: "inspection",
        width: 72,
        sortable: false,
        Cell: cellInfo => {
          const inspection = cellInfo.original;
          console.log('Masuk inspection = ', inspection);
          return (
            inspection.isExisted
            ? 
            <div className="row">
              <div className="col-md-auto">
                <Link to={`/inspection/${inspection.id}/template/${inspection.templateId}/section`} 
                      class="btn btn-sm">Inspect</Link>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-auto">
                <button type="button"
                        className="btn btn-sm"
                        onClick={() => this.handleRestoreInspection(inspection.id)}>Restore</button>
              </div>
            </div>);
        }
      },
      {
        Header: "Created date",
        accessor: "createdDate",
        Cell: cellInfo => {
          const date = convertToStd(cellInfo.value);
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      },
      {
        Header: "Inspection name",
        accessor: "name"
      },
      {
        Header: "Project",
        accessor: "projectName"
      },
      {
        Header: "Inspector",
        accessor: "preparedBy"
      },
      {
        Header: "Location",
        accessor: "location"
      },
      {
        Header: "Contractor name",
        accessor: "contractorName"
      },
      {
        Header: "Score",
        accessor: "score",
        sortable: false
      }
    ];

    if(this.filters[5].value == 'true') {
      columns.push({
        Header: "Submission date",
        accessor: "submissionDate",
        Cell: cellInfo => {
          const date = 
            moment(cellInfo.value, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
          if(date == 'Invalid date') {
            return '';
          }
          return date;
        }
      });
    }

    // if(this.state.isShowingDeletedInspection) {
    //     columns.unshift({
    //        Header: "Action",
    //        accessor: 'id',
    //        width: 100,
    //        filterable: false,
    //        Cell: cellInfo => {
    //          const inspection = cellInfo.original;
    //          if(!inspection.isExisted) {
    //            return (
    //            <div className="row">
    //              <div className="col-md-auto">
    //                <button type="button"
    //                        className="btn btn-sm"
    //                        style={{width: 200}}
    //                        onClick={() => this.handleRestoreInspection(inspection.id)}>Restore</button>
    //              </div>
    //            </div>
    //            );
    //          }
    //        }
    //      });
    //   }

    if(this.state.successMessage) {
      clearTimeout(this.timer);
      this.timer =
          setTimeout(() => {
              this.setState({
                  notifActive: true,
                  successMessage: ''
              });
          }, config.notifDuration);
    }

    const { data, pages, loading } = this.state;
    var modalStyles = {overlay: {zIndex: 10}};
    const component = (
      <div>
        <br/>
        {this.state.successMessage
          ? <div className="alert alert-success" role="alert">
            {this.state.successMessage}
          </div>
          : null}
        {this.state.failedMessage
          ? <div className="alert alert-danger" role="alert">
            {this.state.failedMessage}
          </div>
          : null}
        <hr/>
        <h1>Inspection</h1>
        <hr/>
        <div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
          <div className="col-md-6">
            {this.state.isShowingCreateInspection
             ?
             <button type="button" className="btn" onClick={this.handleAdd}><span class="fe fe-plus"> New Inspection</span></button>
             :
             null}
          </div>
          {!this.state.showFiltering
          ?
          <div className="col-md-6">
            <button type="button" className="btn float-right" onClick={this.handleFilterInput}><span class="fe fe-search"></span></button>
          </div>
          :
          null}
        </div>

        {this.state.showFiltering 
        ?
        <div>
          <div className="card">
            <div className="pull-right" style={{ marginRight: '10px'}}>
                <button type="button"  
                        className="pull-right clickable close-icon close" 
                        onClick={this.handleCloseFilterInput}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-auto">
                  <label><b>Name</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(0, e.target.value)}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Project name</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(1, e.target.value)}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Inspector</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(2, e.target.value)}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Project location</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(3, e.target.value)}/>
                </div>
                <div className="col-md-auto">
                  <label><b>Contractor name</b></label>
                  <input className="form-control" 
                        onChange={e => this.handleSearch(4, e.target.value)}/>
                </div>
                <div className="col-md-auto">
                    <label><b>Submitted</b></label>
                    <select className="form-control" 
                            onChange={e => this.handleSearch(5, e.target.value)}>
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                </div>
                {this.user
                  && this.user.user.type === 'SUPER_ADMIN'
                  ?
                  <div className="col-md-auto">
                    <label><b>Removed</b></label>
                    <select className="form-control" 
                            onChange={e => this.handleSearch(6, e.target.value)}>
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                </div>
                :
                null}
              </div>
            </div>
          </div>
        </div>
        :
        null}
        <ReactModal isOpen={this.state.showAddModal}
          contentLabel="Minimal Modal Example" style={modalStyles}>
          <InspectionCreate
            closeModal={this.handleCloseAddModal}
            refreshTable={this.fetchData}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }}
            hideNavBar={this.hideNavBar}
            setSuccessMessage={this.setSuccessMessage} />
        </ReactModal>
        <ReactModal isOpen={this.state.showEditModal}
          contentLabel="Minimal Modal Example">
          <InspectionEdit
            closeModal={this.handleCloseEditModal}
            id={this.state.editId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage} 
            clearMessage={this.clearMessage}
            tablePaging={{
              page: this.page,
              pageSize: this.pageSize
            }}/>
        </ReactModal>
        <ReactModal isOpen={this.state.showRestoreModal}
            contentLabel="Minimal Modal Example"
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
              }
            }}>
            <RestoreModal
              closeModal={this.handleCloseRestoreModal}
              id={this.state.restoreId}
              refreshTable={this.fetchData}
              setSuccessMessage={this.setSuccessMessage}
              setFailedMessage={this.setFailedMessage}
              deleteUrl={config.api.inspection} />
          </ReactModal>
        <ReactModal isOpen={this.state.showDeleteModal}
          contentLabel="Minimal Modal Example"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }
          }}>
          <DeleteModal
            closeModal={this.handleCloseDeleteModal}
            id={this.state.deleteId}
            refreshTable={this.fetchData}
            setSuccessMessage={this.setSuccessMessage}
            setFailedMessage={this.setFailedMessage}
            deleteUrl={config.api.inspection}
            clearMessage={this.clearMessage} />
        </ReactModal>
        <ReactTable
          columns={columns}
          manual 
          data={data}
          pages={pages} 
          loading={loading} 
          onFetchData={this.fetchData} 
          filterable={false}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        <br/>
      </div>
    );
    return component;
  }
}

export default Inspection;