import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class InspectionEdit extends Component {
    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.closeModal = props.closeModal;
        this.refreshTable = props.refreshTable;
        this.totalCard = 1;
        this.selectedProjectId = 0;
        this.selectedTemplateId = 0;
        this.user = getObject('user');

        this.state = {
            projectValue: '',
            projectName: '',
            inspectionName: '',
            location: '',
            projectSuggestions: [],
            templateSuggestions: [],
            projects: [],
            templateValue: '',
            templateDescription: '',
            failedMessage: ''
        };

        this.getProjectSuggestions = async value => {
            try {
                this.setState({
                    isActiveLoadingOverlay: true
                });
                const result = await axios({
                    url: `${config.api.project}?projectName=${value}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });
    
                const data = result.data.data;
    
                console.log(data.results);
                this.selectedProjectId = 0;
                this.setState({
                    location: '',
                    companyName: ''
                });
                return data.results;
            } finally {
                this.setState({
                    isActiveLoadingOverlay: false
                });
            }
        };
 
        this.getProjectSuggestionValue = suggestion => {
            this.selectedProjectId = suggestion.id;
            this.setState({
                location: suggestion.location,
                companyName: suggestion.company
            });
            return suggestion.projectName;
        };

        this.getTemplateSuggestionValue = suggestion => {
            this.selectedTemplateId = suggestion.id;
            this.setState({
                templateDescription: suggestion.description
            });
            return suggestion.title;
        };

        this.renderProjectSuggestion = suggestion => (
            <span>{suggestion.projectName}</span>);

        this.renderTemplateSuggestion = suggestion => (
                <span>{suggestion.title}</span>);

        this.handleInspectionName = this.handleInspectionName.bind(this);
        this.user = getObject('user');
    }
    
    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const projectsPromise = axios({
                url: `${config.api.project}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const inspectionPromise = axios({
                url: `${config.api.inspection}/${this.props.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const [inspectionResult, projectsResult] = await Promise.all([inspectionPromise, projectsPromise]);

            const data = inspectionResult.data.data;
            const projects = projectsResult.data.data.results;
            
            this.setState({
                inspectionName: data.name,
                projectId : data.projectId,
                projectValue : { value: data.projectId, label: data.projectName},
                location: data.location,
                company: data.company,
                templateTitle: data.templateTitle,
                templateDescription: data.templateDescription,
                preparedBy: data.preparedBy,
                projects: R.map(x => ({value: x.id, label: x.projectName}), projects)
            });
        } catch(e) {
            console.log(e);
            // const data = e.response.data;
            // this.setFailedMessage(data.message);
            // this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    handleInspectionName(inspectionName) {
        this.setState({
            inspectionName
        });
    }

    onProjectSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getProjectSuggestions(value);
        this.setState({
            projectSuggestions: values
        });
    };

    onTemplateSuggestionsFetchRequested = async ({ value }) => {
        const values = await this.getTemplateSuggestions(value);
        this.setState({
            templateSuggestions: values
        });
    };

    onProjectSuggestionsClearRequested = () => {
        this.setState({
          projectSuggestions: []
        });
    };

    onTemplateSuggestionsClearRequested = () => {
        this.setState({
          templateSuggestions: []
        });
    };

    onProjectChange = (event, { newValue }) => {
        this.setState({
          projectValue: newValue
        });
    };

    onTemplateChange = (event, { newValue }) => {
        this.setState({
          templateValue: newValue
        });
    };

    handleCloseModal() {
        this.refreshTable({
            page: this.props.tablePaging.page,
            pageSize: this.props.tablePaging.pageSize,
            filtered: []
        });
        this.closeModal();
    }

    handleChangeProject = async (projectValue) => {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            
            const projectResult = await axios({
                url: `${config.api.project}/${projectValue.value}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const project = projectResult.data.data;
            this.setState({
                projectValue,
                location: project.location,
                company: project.company
            });
        } catch(e) {

        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const user = getObject('user');
            const inspectionAPI = config.api.inspection;
    
            const header = {
                headers: { 'x-access-token': user.token }
            };
    
            const res = await axios.put(`${inspectionAPI}/${this.props.id}`,
                       {
                           projectId: this.state.projectValue.value,
                           name: this.state.inspectionName
                       },
                       header);
                       

            this.props.refreshTable({
                page: this.props.tablePaging.page,
                pageSize: this.props.tablePaging.pageSize,
                filtered: []
            });
            this.props.setSuccessMessage('inspection has been added successfully');
            this.props.closeModal();

            if(this.props.onSuccess) {
                this.props.onSuccess();
            }
        } catch(e) {
            const data = e.response.data;
            if (data.code === 400) {
                this.setState({
                    failedMessage: data.message
                });
            } 
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }

    }

    render() {
        const { projectValue, projectSuggestions, templateValue, templateSuggestions } = this.state;

        const projectInputProps = {
            placeholder: 'Type project name',
            value: projectValue,
            onChange: this.onProjectChange
        };

        const templateInputProps = {
            placeholder: 'Type template title',
            value: templateValue,
            onChange: this.onTemplateChange
        };

        console.log(projectInputProps);
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="pull-right clickable close-icon close" 
                            onClick={this.handleCloseModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <div>
                    <form>
                        <div className="form-group">
                            <label><b>Inspection name</b></label>
                            <input 
                                className="form-control"
                                onChange={(event) => this.handleInspectionName(event.target.value)}
                                value={this.state.inspectionName} />
                        </div>
                        <div className="form-group">
                            <label><b>Project</b></label>
                            <Select
                                value={projectValue}
                                onChange={this.handleChangeProject}
                                options={this.state.projects}/>
                        </div>
                        {this.state.location
                        ?
                        <div className="form-group">
                            <label><b>Location</b></label>
                            <p>{this.state.location}</p>
                        </div>
                        :
                        null}
                        {this.state.company
                        ?
                        <div className="form-group">
                            <label><b>Company</b></label>
                            <p>{this.state.company}</p>
                        </div>
                        :
                        null}
                        <div className="form-group">
                            <label><b>Template title</b></label>
                            <p>{this.state.templateTitle}</p>
                        </div>
                        {this.state.templateDescription
                        ?
                        <div className="form-group">
                            <label><b>Template description</b></label>
                            <p>{this.state.templateDescription}</p>
                        </div>
                        :
                        null}
                    </form>
                    {this.state.failedMessage 
                        ? <div className="alert alert-danger" role="alert">
                                {this.state.failedMessage}
                            </div> 
                        : null}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default InspectionEdit;